import { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext"; 
import { db } from "../firebase/firebase"; 
import { getAuth, reauthenticateWithCredential, updatePassword,EmailAuthProvider } from "firebase/auth";
import { ref, get, update } from "firebase/database";
import { doSignOut } from "../firebase/auth";
import "./profile.css";
import UserNotifications from "./UserNotif";
import AdminNotification from './adminNotification';

const Profile = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [idImage, setIdImage] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [popupMessage, setPopupMessage] = useState(""); 
  const [showPopup, setShowPopup] = useState(false); 
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationpw, setShowConfirmationpw] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const [profileImageTemp, setProfileImageTemp] = useState(null);

  const [showConfirmationProfile, setShowConfirmationProfile] = useState(false);
  const [showConfirmationId, setShowConfirmationId] = useState(false);

  const sessionTimeout = 300000;
  let timeout;

  const resetTimeout = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(handleSignOut1, sessionTimeout);
  };

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const getUserData = async () => {
        try {
          const userRef = ref(db, `/users/${userId}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setFirstName(userData.firstName);
            setMiddleName(userData.middleName);
            setLastName(userData.lastName);
            setEmail(userData.email);
            setProfileImage(userData.profileImage);
            setIdImage(userData.idImage);
            setAdmin(userData.admin);
          } else {
            console.log("No data available");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      getUserData();
    }
  }, [currentUser]);
  const PopupContainer = ({ message, onClose }) => (
    <div className="popup-container">
      <div className="popup-content">
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
  const ConfirmationPopup = ({ message, onConfirm, onCancel }) => (
    <div className="confirmation-popup-container">
      <div className="confirmation-popup-content">
        <p>{message}</p>
        <button onClick={onConfirm}>Yes</button>
        <button onClick={onCancel}>No</button>
      </div>
    </div>
  );
  const closePopup = () => {
    setShowPopup(false);
  };

  const handleSignOut = () => {
    doSignOut();
    window.location.href = "/home";
  };

  
  const handleSignOut1 = () => {
    doSignOut();
    alert("Session expired due to inactivity.");
    window.location.href = "/home";
  };

  useEffect(() => {

    resetTimeout();


    const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
    events.forEach(event => window.addEventListener(event, resetTimeout));


    return () => {
      clearTimeout(timeout);
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, []);


  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setPopupMessage("Are you sure you want to change your password?");
    setShowConfirmationpw(true);
  };

  const confirmPasswordUpdate = async () => {
    setIsUpdating(true);
    try {
      if (newPassword !== confirmNewPassword) {
        throw new Error("Passwords do not match");
      }


      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);


      await reauthenticateWithCredential(user, credential);


      await updatePassword(user, newPassword);

      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      setErrorMessage("");
      setPopupMessage("Password updated successfully!");
      setShowPopup(true);
      setShowConfirmationpw(false); 
    } catch (error) {
      setErrorMessage(error.message);
      setPopupMessage("Error updating password: " + error.message); 
      setShowPopup(true); 
      setShowConfirmationpw(false); 
    } finally {
    
    }
  };


  
  
    const handleProfileImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {

        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!validTypes.includes(file.type)) {
          alert("Only JPG and PNG files are allowed");
          e.target.value = ''; 
          return;
        }

   
        const maxSize = 5 * 1024 * 1024; 
        if (file.size > maxSize) {
          alert("File size must be less than 5MB");
          e.target.value = ''; 
          return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImageTemp(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  
    const handleIdImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
     
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!validTypes.includes(file.type)) {
          alert("Only JPG and PNG files are allowed");
          e.target.value = ''; 
          return;
        }


        const maxSize = 5 * 1024 * 1024; 
        if (file.size > maxSize) {
          alert("File size must be less than 5MB");
          e.target.value = '';
          return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          setIdImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleUploadProfile = async () => {
      setPopupMessage("Are you sure you want to update your profile image?");
      setShowConfirmationProfile(true);
    };

    const handleUploadId = async () => {
      setPopupMessage("Are you sure you want to update your ID image?");
      setShowConfirmationId(true);
    };

    const confirmProfileUpload = async () => {
      try {
        await update(ref(db, `/users/${currentUser.uid}`), {
          profileImage: profileImageTemp,
        });

        setProfileImage(profileImageTemp);
        setPopupMessage("Profile image uploaded successfully!");
        setShowPopup(true);
        setShowConfirmationProfile(false);
      } catch (error) {
        console.error("Error uploading profile image:", error);
        setPopupMessage("Error uploading profile image: " + error.message);
        setShowPopup(true);
        setShowConfirmationProfile(false);
      }
    };

    const confirmIdUpload = async () => {
      try {
        await update(ref(db, `/users/${currentUser.uid}`), {
          idImage: idImage,
        });

        setPopupMessage("ID image uploaded successfully!");
        setShowPopup(true);
        setShowConfirmationId(false);
      } catch (error) {
        console.error("Error uploading ID image:", error);
        setPopupMessage("Error uploading ID image: " + error.message);
        setShowPopup(true);
        setShowConfirmationId(false);
      }
    };

    const cancelUpload = () => {
      setShowConfirmationProfile(false);
      setShowConfirmationId(false);
    };
    const cancelPasswordUpdate = () => {
      setShowConfirmationProfile(false);
      setShowConfirmationId(false);
    };
  



    


  return (

    <div>
    <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
   <div className="container d-flex fixed-top">
     <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
     <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
       <ul>


         {admin === "yes" ? (
           <>
             <li><a className="nav-link scrollto active" href="adminbookinglist">Bookings</a></li>
             <li><a className="nav-link scrollto" href="manageaccounts">Manage Accounts</a></li>
             <li><a className="nav-link scrollto" href="analytics">Dashboard</a></li>
             <li><a className="nav-link scrollto" href="walkInBooking">Walk In</a></li>
             <li className="nav-link scrollto"><AdminNotification /></li>
           </>
         ) : (
           <>
             <li><a className="nav-link scrollto active" href="home">Home</a></li>
             <li><a className="nav-link scrollto" href="home#about">About</a></li>
             <li><a className="nav-link scrollto" href="home#team">Team</a></li>
             <li><a className="nav-link scrollto" href="home#faq">FAQ</a></li>
             <li><a className="nav-link scrollto" href="booking">Book</a></li>
             <li><a className="nav-link scrollto" href="packages">Packages</a></li>
             {userLoggedIn && (
               <li><a className="nav-link scrollto" href="UserBookings">My Bookings</a></li>
             )}

<div className="ml-2" style={{ marginLeft: '35px' }}>
                  <UserNotifications />
                </div>
           </>
         )}


         
         {userLoggedIn ? (
           <li className=" ml-12 dropdown">
             <a href="#" className="user-avatar-link">
               {profileImage ? (
                 <img
                   src={profileImage}
                   alt="User Image"
                   className="user-avatar"
                   style={{ width: "40px", height: "40px" }}
                 />
               ) : (
                 <img src="assets/img/profile.png" alt="Default User Image" className="user-avatar" style={{ width: "40px", height: "40px" }} />
               )}
               <i className="bi bi-chevron-down" />
             </a>
             <ul className="dropdown-menu" style={{ position: 'absolute', left: '-150px', backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', zIndex: '1000' }}>
               <li>
                 <a>
                   <span>{currentUser.email}</span>
                 </a>
               </li>
               <li><a href="profile">Profile</a></li>
               <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
             </ul>
           </li>
         ) : (
           <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
         )}
       </ul>
       <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
     </nav>
 </div>
 {showConfirmationProfile && <ConfirmationPopup message={popupMessage} onConfirm={confirmProfileUpload} onCancel={cancelUpload} />}
 {showConfirmationId && <ConfirmationPopup message={popupMessage} onConfirm={confirmIdUpload} onCancel={cancelUpload} />}
 {showConfirmationpw && <ConfirmationPopup message={popupMessage} onConfirm={confirmPasswordUpdate} onCancel={cancelPasswordUpdate} />}
 {showPopup && <PopupContainer message={popupMessage} onClose={closePopup} />} {/* Show the popup container */}
 </header>
{/* End Header */}


    <div className="container-xl px-4 mt-4">
      <hr className="mt-0 mb-4" />
      <div className="row">
        <div className="col-xl-4" style={{ marginTop: '20px' }}>
          <div className="card mb-4 mb-xl-0">
            <div className="custom-header" style={{ marginTop: '20px', marginLeft: '20px' }}>Upload images</div>
            <div className="card-body text-center" style={{ marginTop: '20px' }}>
            <div className="custom-header">Profile Picture</div> 

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  className="img-account-profile rectangle mb-"
                  src={profileImageTemp || profileImage || "http://bootdey.com/img/Content/avatar/avatar1.png"}
                  alt=""
                />
              </div>
              <div className="small font-italic text-muted mb-1">Only JPG and PNG file formats are valid.</div>
              <div className="small font-italic text-muted mb-4">No more than 5 MB</div>
              <div>
                <div style={{ marginBottom: '10px' }}>
                <input
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                onChange={handleProfileImageChange}
              />

<button className="mt-5 btn btn-primary" type="submit" onClick={handleUploadProfile}>
                    Save Profile Image
                </button>
              
             

                </div>
                
                <div className="custom-header" style={{ marginTop: '100px', marginLeft: '20px' }} >ID Picture</div>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                  <img className="img-account-profile rectangle mb-" src={idImage || "./assets/img/Validid.png"} alt="" />
                </div>

                
                <div className="small font-italic text-muted mb-1">Only JPG and PNG file formats are valid.</div>
              <div className="small font-italic text-muted mb-4">No more than 5 MB</div>
                <div style={{ marginBottom: '40px' }}>
                <input
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                onChange={handleIdImageChange}
              />

              
                <button className="mt-5 btn btn-primary" type="submit" onClick={handleUploadId}>
                    Save ID Image
                </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="card mb-4" style={{ marginTop: '20px', maxWidth: '550px' }}>
            <div className="row">
            <div className="col-auto text-center"> 
            <div className="custom-header" style={{ marginTop: '20px', marginLeft: '20px' }}>Name: </div>
              </div>
              <div className="col">
                <div className="custom-header" style={{ marginTop: '20px', marginLeft: '20px' }}>{firstName} {middleName} {lastName}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
              <div className="custom-header" style={{ marginTop: '20px', marginLeft: '20px' }}>Email: </div>
              </div>
              <div className="col">
                <div className="custom-header" style={{ marginTop: '20px', marginLeft: '20px' }}>{email}</div>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleUpdatePassword}>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="currentPassword">Current Password</label>
                  <input className="form-control" id="currentPassword" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
                </div>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="newPassword">New Password</label>
                  <input className="form-control" id="newPassword" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                </div>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="confirmNewPassword">Confirm Password</label>
                  <input className="form-control" id="confirmNewPassword" type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
                </div>
                {errorMessage && <span className="error">{errorMessage}</span>}
                <button className="btn btn-primary" type="submit">{isUpdatingProfile ? "Updating..." : "Update Password"}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>Etourmo</h3>
                  <p>
                  214 Abacan St. Brgy Calvario <br />
                  Meycauayan Bulacan<br />
                    Philippines <br /><br />
                    <strong>Phone:</strong> 0908 300 0009<br />
                    <strong>Email:</strong>ETOURMO@GMAIL.COM<br />
                  </p>
                </div>
                
                
               
              </div>
            </div>
          </div>
          <div className="container footer-bottom clearfix">
            <div className="copyright">
              © Copyright <strong><span>Arsha</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
          </div>
        </footer>{/* End Footer */} 
    </div>
  );
};
export default Profile;