import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, set, get, remove, onValue,update, push } from "firebase/database";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "../pages/adminDashboard.css";
import AdminNotification from './adminNotification';
import { Modal, Button } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';


const AdminDashboard = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || "bookings");
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [approvedBookings, setApprovedBookings] = useState([]);

  const [completedBookings, setCompletedBookings] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  

  const [isTypeAdmin, setIsTypeAdmin] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [cancelledBookings, setCancelledBookings] = useState([]); 
  const [searchQuery, setSearchQuery] = useState("");
  const [refundedBookings, setRefundedBookings] = useState([]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [requestedDates, setRequestedDates] = useState({}); 

  const [showRefundModal, setShowRefundModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [refundAmount, setRefundAmount] = useState("");
  const [refundErrorMessage, setRefundErrorMessage] = useState("");

  useEffect(() => {
    if (currentUser) {
      
      const requestedDatesRef = ref(db, `requestedDates`);
      onValue(requestedDatesRef, (snapshot) => {
        if (snapshot.exists()) {
          const requestedDatesData = snapshot.val();
          setRequestedDates(requestedDatesData); 
        } else {
          console.log("No requested dates available");
        }
      });
    }
  }, [currentUser]);


  

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setIsTypeAdmin(userData.adminType);
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin);
          } else {
            console.log("No user data available");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });

      const bookingsRef = ref(db, "bookings");
      onValue(bookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const bookingsData = snapshot.val();
          const bookingsArray = Object.keys(bookingsData).map((key) => ({
            id: key,
            ...bookingsData[key],
          }));
          setBookings(bookingsArray);

         
        } else {
          console.log("No bookings available");
        }
      });

      const approvedBookingsRef = ref(db, "approvedBookings");
      onValue(approvedBookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const approvedBookingsData = snapshot.val();
          const approvedBookingsArray = Object.keys(approvedBookingsData).map((key) => ({
            id: key,
            ...approvedBookingsData[key],
          }));
          setApprovedBookings(approvedBookingsArray);

          // New code to check for upcoming departures
          const currentDate = new Date();
          const oneWeekFromNow = new Date(currentDate);
          oneWeekFromNow.setDate(currentDate.getDate() + 7);

          approvedBookingsArray.forEach(async (booking) => {
            const departureDate = new Date(booking.departureDate);
            const notificationSent = booking.notificationSent || false; // Check if notification has been sent
            
            if (departureDate <= oneWeekFromNow && departureDate >= currentDate && !notificationSent) {
              const userEmail = booking.userEmail;
              const subject = "Upcoming Booking Reminder";
              const message = `Your booking (ID: ${booking.id}) is coming up on ${booking.departureDate}. Please prepare accordingly.\n\nBooking ID: ${booking.id}\nDeparture Date: ${booking.departureDate}\nSelected Package: ${booking.package}`;
              
            
              await createUserNotification(booking.userId, message, booking.id, 'approved');
              sendEmail(userEmail, subject, message);
              
              // Update the booking to mark notification as sent
              await update(ref(db, `approvedBookings/${booking.id}`), { notificationSent: true });
            }
          });
        } else {
          console.log("No approved bookings available");
        }
      });

      const completedBookingsRef = ref(db, "completedBookings");
      onValue(completedBookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const completedBookingsData = snapshot.val();
          const completedBookingsArray = Object.keys(completedBookingsData).map((key) => ({
            id: key,
            ...completedBookingsData[key],
          }));
          setCompletedBookings(completedBookingsArray);
        } else {
          console.log("No completed bookings available");
        }
      });

      const cancelledBookingsRef = ref(db, `cancelledBookings`);
      onValue(cancelledBookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const cancelledBookingsData = snapshot.val();
          const cancelledBookingsArray = Object.keys(cancelledBookingsData).map((key) => ({
            id: key,
            ...cancelledBookingsData[key],
          }));
          setCancelledBookings(cancelledBookingsArray);
        } else {
          console.log("No cancelled bookings available");
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const refundedBookingsRef = ref(db, "refundedBookings");
    onValue(refundedBookingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const refundedBookingsData = snapshot.val();
        const refundedBookingsArray = Object.keys(refundedBookingsData).map((key) => ({
          id: key,
          ...refundedBookingsData[key],
        }));
        setRefundedBookings(refundedBookingsArray);
      } else {
        console.log("No refunded bookings available");
      }
    });
  }, []);

  const handleSignOut = () => {
    doSignOut();
    window.location.href = "/home";
  };

  useEffect(() => {
    const bookingsRef = ref(db, "bookings");
    onValue(bookingsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const bookingsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setBookings(bookingsArray);
      }
    });
  }, []);

  

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };


  const handleDeleteBooking = async (id) => {
    if (isTypeAdmin === "superAdmin") {
      if (window.confirm("Are you sure you want to delete this booking?")) {
        const bookingRef = ref(db, `cancelledBookings/${id}`);
        const bookingRef1 = ref(db, `analytics/${id}`);
    
        try {
          await remove(bookingRef);
          await remove(bookingRef1);
          console.log("Booking deleted successfully");
    
          const findAndRemoveBookingInUsers = async (bookingId) => {
            const usersRef = ref(db, 'users');
            const snapshot = await get(usersRef);
    
            if (snapshot.exists()) {
              const users = snapshot.val();
    
              for (const userId in users) {
                const userBookingRef = ref(db, `UserCancelledBookings/${userId}/${bookingId}`);
                const userBookingSnapshot = await get(userBookingRef);
    
                if (userBookingSnapshot.exists()) {
                  await remove(userBookingRef);
                  console.log(`Booking removed for user: ${userId}`);
                  return;
                }
              }
            }
          };
    
          await findAndRemoveBookingInUsers(id);
    
          setBookings((prevBookings) => prevBookings.filter((booking) => booking.id !== id));
        } catch (error) {
          console.error("Error deleting booking:", error.message);
        }
      }
    } else {
      window.alert("You must be a super admin to delete this booking.");
    }
   
  };



  const handleDeleteCompletedBooking = (id) => {
    if (isTypeAdmin === "superAdmin") {
    if (window.confirm("Are you sure you want to delete this completed booking?")) {
      const bookingRef = ref(db, `completedBookings/${id}`);
      
      
      remove(bookingRef)
        .then(() => {
          console.log("Completed booking deleted successfully");
          setCompletedBookings((prevBookings) => prevBookings.filter((booking) => booking.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting completed booking:", error.message);
        });
    }
  } else {
    window.alert("You must be a super admin to delete this booking.");
  }
  };



  const handleApproveBooking = async (id) => {
    if (isTypeAdmin === "superAdmin" || isTypeAdmin === "admin") {
      if (window.confirm("Are you sure you want to approve this booking?")) {
        const bookingToApprove = bookings.find((booking) => booking.id === id);
        
        if (bookingToApprove) {
          try {
            const timestamp = Date.now();
            const updatedBooking = {
              ...bookingToApprove,
              approvedTimestamp: timestamp,
              approvedDate: new Date(timestamp).toLocaleString(),
              status: "Unpaid"
            };

            
            await update(ref(db, `analytics/${id}`), { 
              level: "approved",
              timestamp: timestamp,
              time: new Date(timestamp).toLocaleString()
            });

            
            await set(ref(db, `approvedBookings/${id}`), updatedBooking);
            await remove(ref(db, `bookings/${id}`));

            
            const usersRef = ref(db, 'users');
            const snapshot = await get(usersRef);

            if (snapshot.exists()) {
              const users = snapshot.val();
              const updatePromises = Object.keys(users).map(async (userId) => {
                const userBookingRef = ref(db, `UserBookings/${userId}/${id}`);
                const userBookingSnapshot = await get(userBookingRef);

                if (userBookingSnapshot.exists()) {
                  await set(ref(db, `UserApprovedBookings/${userId}/${id}`), updatedBooking);
                  await remove(userBookingRef);

                  const notificationMessage = `Your booking #${id} has been approved.`;
                  await createUserNotification(userId, notificationMessage, id, "approved");

                  const AdminNotificationMessage = `Booking #${id} has been approved.`;
                        await createAdminNotification(AdminNotificationMessage, id,'approved');
                }
              });

              await Promise.all(updatePromises);
            }

            
            setBookings(prev => prev.filter(booking => booking.id !== id));
            setApprovedBookings(prev => [...prev, updatedBooking]);

            
            const userEmail = bookingToApprove.userEmail;
            const subject = "Your Booking has been Approved";
            const message = ` ${bookingToApprove.userName},\n\nYour booking (ID: ${bookingToApprove.id}) for the package "${bookingToApprove.package}" has been approved on ${new Date(timestamp).toLocaleString()}.\n\nBooking ID: ${bookingToApprove.id}\nDeparture Date: ${bookingToApprove.departureDate}\nSelected Package: ${bookingToApprove.package}`;
            sendEmail(userEmail, subject, message);
          } catch (error) {
            console.error("Error approving booking:", error);
          }
        }
      }
    } else {
      window.alert("You must be a super admin or admin to approve this booking.");
    }
  };
  


  const handleCompleteBooking = async (id) => {
    if (isTypeAdmin === "superAdmin" || isTypeAdmin === "admin") {
      const bookingToComplete = approvedBookings.find((booking) => booking.id === id);
      
      if (bookingToComplete && bookingToComplete.status !== "Paid") {
        window.alert("You can only complete bookings that are marked as 'Paid'.");
        return;
      }

      if (window.confirm("Are you sure you want to mark this booking as complete?")) {
        try {
          const timestamp = Date.now();
          const updatedBooking = {
            ...bookingToComplete,
            completedTimestamp: timestamp,
            completedDate: new Date(timestamp).toLocaleString()
          };

          
          await update(ref(db, `analytics/${id}`), { 
            level: "completed",
            timestamp: timestamp,
            time: new Date(timestamp).toLocaleString()
          });

          await set(ref(db, `completedBookings/${id}`), updatedBooking);
          await remove(ref(db, `approvedBookings/${id}`));

          
          const usersRef = ref(db, 'users');
          const snapshot = await get(usersRef);

          if (snapshot.exists()) {
            const users = snapshot.val();
            const updatePromises = Object.keys(users).map(async (userId) => {
              const userApprovedBookingRef = ref(db, `UserApprovedBookings/${userId}/${id}`);
              const userApprovedBookingSnapshot = await get(userApprovedBookingRef);

              if (userApprovedBookingSnapshot.exists()) {
                await set(ref(db, `UserCompletedBookings/${userId}/${id}`), updatedBooking);
                await remove(userApprovedBookingRef);

                const notificationMessage = `Your booking #${id} has been marked as completed.`;
                await createUserNotification(userId, notificationMessage, id, "completed");

                const AdminNotificationMessage = `Booking #${id} has been completed.`;
                        await createAdminNotification(AdminNotificationMessage, id,'completed');
                
              }
            });

            await Promise.all(updatePromises);
          }

          
          setApprovedBookings(prev => prev.filter(booking => booking.id !== id));
          setCompletedBookings(prev => [...prev, updatedBooking]);

          
          const userEmail = bookingToComplete.userEmail;
          const subject = "Your Booking has been Completed";
          const message = ` ${bookingToComplete.userName},\n\nYour booking (ID: ${bookingToComplete.id}) for the package "${bookingToComplete.package}" has been marked as completed on ${new Date(timestamp).toLocaleString()}.\n\nBooking ID: ${bookingToComplete.id}\nDeparture Date: ${bookingToComplete.departureDate}\nSelected Package: ${bookingToComplete.package}`;
          sendEmail(userEmail, subject, message);
        } catch (error) {
          console.error("Error completing booking:", error);
        }
      }
    } else {
      window.alert("You must be a super admin or admin to complete this booking.");
    }
  };
  
  
  

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  
  const sortBookings = (bookings) => {
    if (!sortBy) {
      
      return [...bookings].sort((a, b) => {
        const timeA = a.pendingTimestamp || a.approvedTimestamp || a.completedTimestamp || a.cancelledTimestamp || 0;
        const timeB = b.pendingTimestamp || b.approvedTimestamp || b.completedTimestamp || b.cancelledTimestamp || 0;
        return timeB - timeA;
      });
    }

    return [...bookings].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
  };



  const getSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? (
        <i className="bi bi-arrow-up"></i>
      ) : (
        <i className="bi bi-arrow-down"></i>
      );
    }
    return null;
  };

 

  const renderBookingsTable = (bookings, actionButton) => (
    <div className="Abooking-table">
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort("id")}>Booking #{getSortIcon("id")}</th>
            <th onClick={() => handleSort("userName")}>Name {getSortIcon("userName")}</th>
            <th onClick={() => handleSort("userEmail")}>Email {getSortIcon("userEmail")}</th>
            <th onClick={() => handleSort("schoolName")}>School {getSortIcon("schoolName")}</th>
            <th onClick={() => handleSort("address")}>Address {getSortIcon("address")}</th>
            <th onClick={() => handleSort("zipCode")}>Zip Code {getSortIcon("zipCode")}</th>
            <th onClick={() => handleSort("departureDate")}>Departure Date {getSortIcon("departureDate")}</th>
            <th onClick={() => handleSort("package")}>Package {getSortIcon("package")}</th>
            <th onClick={() => handleSort("pax")}>Pax {getSortIcon("pax")}</th>
            <th onClick={() => handleSort("downpayment")}>Down Payment {getSortIcon("downpayment")}</th>
            <th onClick={() => handleSort("totalPrice")}>Total Price {getSortIcon("totalPrice")}</th>
            {activeTab === "refunded" && (
              <th onClick={() => handleSort("refundAmount")}>Refund Amount {getSortIcon("refundAmount")}</th>
            )}
            <th onClick={() => handleSort("status")}>Status {getSortIcon("status")}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking.id}>
              <td>{booking.id}</td>
              <td>{booking.userName}</td>
              <td>{booking.userEmail}</td>
              <td>{booking.schoolName}</td>
              <td>{booking.address}</td>
              <td>{booking.zipCode}</td>
              <td>
                {activeTab === "cancelled" ? (
                  <span style={{ color: "red" }}>Cancelled</span>
                ) : (
                  <input
                    type="date"
                    value={booking.departureDate}
                    onChange={(e) => handleDepartureDateChange(booking.id, e.target.value)}
                    disabled={isTypeAdmin !== "superAdmin" && isTypeAdmin !== "admin"}
                  />
                )}
              </td>
              <td>{booking.package}</td>
              <td>{booking.pax}</td>
              <td>{booking.downPayment}</td>
              <td>{booking.totalPrice}</td>
              {activeTab === "refunded" && <td>{booking.refundAmount || "N/A"}</td>}
              <td>
                {activeTab === "cancelled" ? (
                  <span style={{ color: "red" }}>Cancelled</span>
                ) : activeTab === "refunded" ? (
                  <span style={{ color: "green" }}>Refunded</span>
                ) : activeTab === "completed" ? (
                  <span style={{ color: "green" }}>Paid</span>
                ) : activeTab === "bookings" ? (
                  <span style={{ color: "orange" }}>For Approval</span>
                ) : (
                  <>
                    {booking.status === "Request Change Date" ? (
                      <span>
                        <select
                        value={booking.status}
                        onChange={(e) => handleStatusChange(booking.id, e.target.value)}
                      >
                         {activeTab === "approved" && booking.status === "Paid" ? null : (
                          <option value="Unpaid">Unpaid</option>
                        )}
                        <option value="Paid">Paid</option>
                        <option value="Request Change Date">Request Change Date</option>
                        <option value="Request Refund">Request Refund</option>
                        <option value="Settled Downpayment">Settled Downpayment</option>
                      </select>
                       (Requested Date: {requestedDates[booking.id]?.requestedDate || "N/A"})
                      </span>
                    ) : (
                      <select
                        value={booking.status}
                        onChange={(e) => handleStatusChange(booking.id, e.target.value)}
                      >
                        {activeTab === "approved" && booking.status === "Paid" ? null : (
                          <option value="Unpaid">Unpaid</option> 
                        )}
                        <option value="Paid">Paid</option>
                        <option value="Request Change Date">Request Change Date</option>
                        <option value="Request Refund">Request Refund</option>
                        <option value="Settled Downpayment">Settled Downpayment</option>
                      </select>
                    )}
                  </>
                )}
              </td>
              <td>{actionButton(booking)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const handleStatusChange = async (bookingId, newStatus) => {
    if (!window.confirm("Are you sure you want to change the status?")) {
      return;
    }

    let bookingRef, userBookingRefPrefix;
    
    switch (activeTab) {
        case "bookings":
            bookingRef = ref(db, `bookings/${bookingId}`);
            userBookingRefPrefix = `UserBookings`;
            break;
        case "approved":
            bookingRef = ref(db, `approvedBookings/${bookingId}`);
            userBookingRefPrefix = `UserApprovedBookings`;
            break;
        case "completed":
            bookingRef = ref(db, `completedBookings/${bookingId}`);
            userBookingRefPrefix = `UserCompletedBookings`;
            break;
        default:
            console.error("Invalid activeTab value");
            return;
    }
    
    try {
      if (isTypeAdmin === "superAdmin" || isTypeAdmin === "admin") {
        await update(bookingRef, { status: newStatus });
        console.log(`Booking status updated successfully to ${newStatus}`);

        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);

        if (snapshot.exists()) {
            const users = snapshot.val();
            const updatePromises = Object.keys(users).map(async (userId) => {
                const userBookingRef = ref(db, `${userBookingRefPrefix}/${userId}/${bookingId}`);
                const userBookingSnapshot = await get(userBookingRef);

                if (userBookingSnapshot.exists()) {
                    await update(userBookingRef, { status: newStatus });
                    console.log(`Booking status updated for user: ${userId} to ${newStatus}`);

                    const notificationMessage = `Your booking #${bookingId} status has been updated to ${newStatus}`;
                    await createUserNotification(userId, notificationMessage, bookingId, newStatus);

                    const AdminNotificationMessage = `Booking #${bookingId} status has been updated to ${newStatus}.`;
                    await createAdminNotification(AdminNotificationMessage, bookingId, 'newStatus');

                    const userEmail = users[userId].email;
                    sendEmail(userEmail, newStatus);
                }
            });

            await Promise.all(updatePromises);
        }

      } else {
        window.alert("You must be a super admin or admin to update the status of this booking.");
      }
    
    } catch (error) {
        console.error("Error updating booking status:", error.message);
    }
};


const sendEmail = (userEmail, subject, message) => {
  const templateParams = {
    user_email: userEmail,
    subject: subject,
    message: message,
  };

  emailjs
      .send('service_5fwafhc', 'template_17y2dr9', templateParams, 'ymggzz4cqmsAkMw6i')
      .then(
        () => {
          console.log('Email sent successfully!');
        },
        (error) => {
          console.log('Failed to send email:', error.text);
        },
      );
  };

const handleCancelBooking = async (id) => {
  if (window.confirm("Are you sure you want to cancel this booking?")) {
    try {
      const timestamp = Date.now();
      const bookingToCancel = [...bookings, ...approvedBookings].find(b => b.id === id);
      
      if (bookingToCancel) {
        const updatedBooking = {
          ...bookingToCancel,
          cancelledTimestamp: timestamp,
          cancelledDate: new Date(timestamp).toLocaleString(),
          status: "cancelled" 
        };

        
        await update(ref(db, `analytics/${id}`), { 
          level: "cancelled",
          timestamp: timestamp,
          time: new Date(timestamp).toLocaleString()
        });

        await set(ref(db, `cancelledBookings/${id}`), updatedBooking);
        await remove(ref(db, `bookings/${id}`));
        await remove(ref(db, `approvedBookings/${id}`));

        
        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);

        if (snapshot.exists()) {
          const users = snapshot.val();
          const updatePromises = Object.keys(users).map(async (userId) => {
            const userBookingRef = ref(db, `UserBookings/${userId}/${id}`);
            const userApprovedBookingRef = ref(db, `UserApprovedBookings/${userId}/${id}`);
            
            const bookingExists = await get(userBookingRef);
            const approvedBookingExists = await get(userApprovedBookingRef);

            if (bookingExists.exists() || approvedBookingExists.exists()) {
              await set(ref(db, `UserCancelledBookings/${userId}/${id}`), updatedBooking);
              await remove(userBookingRef);
              await remove(userApprovedBookingRef);

              const notificationMessage = `Your booking #${id} has been cancelled.`;
              await createUserNotification(userId, notificationMessage, id, "cancelled");


              const AdminNotificationMessage = `Booking #${id} has been cancelled.`;
                        await createAdminNotification(AdminNotificationMessage, id,'cancelled');
            }
          });

          await Promise.all(updatePromises);
        }

        
        setBookings(prev => prev.filter(booking => booking.id !== id));
        setApprovedBookings(prev => prev.filter(booking => booking.id !== id));
        setCancelledBookings(prev => [...prev, updatedBooking]);

        
        const userEmail = bookingToCancel.userEmail;
        const subject = "Your Booking has been Cancelled";
        const message = ` ${bookingToCancel.userName},\n\nYour booking (ID: ${bookingToCancel.id}) for the package "${bookingToCancel.package}" has been cancelled on ${new Date(timestamp).toLocaleString()}.\n\nBooking ID: ${bookingToCancel.id}\nDeparture Date: ${bookingToCancel.departureDate}\nSelected Package: ${bookingToCancel.package}`;
        sendEmail(userEmail, subject, message);
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  }
};


const handleRefund = async (id) => {
  if (isTypeAdmin === "superAdmin" || isTypeAdmin === "admin") {
    const bookingToRefund = approvedBookings.find((booking) => booking.id === id);

    if (!bookingToRefund) {
      console.error("Booking not found");
      return;
    }

    if (refundAmount <= 0) {
      setRefundErrorMessage("Please enter a valid refund amount.");
      return;
    }

    if (window.confirm("Are you sure you want to mark this booking as refunded?")) {
      if (bookingToRefund.downPayment === 0) {
        window.alert("Refund cannot be processed as the down payment is 0.");
        return;
      }

      const bookingRef = ref(db, `approvedBookings/${id}`);
      const refundedBookingsRef = ref(db, `refundedBookings/${id}`);
      const analyticsRef = ref(db, `analytics/${id}`);

      try {
        // Update the booking status and refund amount in the approved bookings
        await update(bookingRef, { status: "Refunded", refundAmount: refundAmount });

        // Move the booking to refundedBookings with the refund amount
        await set(refundedBookingsRef, { ...bookingToRefund, status: "Refunded", refundAmount: refundAmount });
        console.log("Booking marked as refunded and moved successfully");

        // Update the analytics to reflect the refund
        await update(analyticsRef, { level: "refunded" });

        // Remove the booking from approvedBookings
        await remove(bookingRef);
        console.log("Booking removed from approvedBookings successfully");

        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);

        if (snapshot.exists()) {
          const users = snapshot.val();

          const updatePromises = Object.keys(users).map(async (userId) => {
            const userApprovedBookingRef = ref(db, `UserApprovedBookings/${userId}/${id}`);
            const userApprovedBookingSnapshot = await get(userApprovedBookingRef);

            if (userApprovedBookingSnapshot.exists()) {
              const userRefundedBookingsRef = ref(db, `UserRefundedBookings/${userId}/${id}`);
              await set(userRefundedBookingsRef, { ...bookingToRefund, status: "Refunded", refundAmount: refundAmount });
              console.log(`Booking added to UserRefundedBookings for user: ${userId}`);

              await remove(userApprovedBookingRef);
              console.log(`Booking removed from UserApprovedBookings for user: ${userId}`);

              const notificationMessage = `Your booking #${id} has been marked as refunded.`;
              await createUserNotification(userId, notificationMessage, id, "refunded");

              const adminNotificationMessage = `Booking #${id} has been marked as refunded.`;
              await createAdminNotification(adminNotificationMessage, id, 'refunded');
            }
          });

          await Promise.all(updatePromises);
        }

        setApprovedBookings((prevApproved) => prevApproved.filter((booking) => booking.id !== id));
        setRefundedBookings((prevRefunded) => [...prevRefunded, { ...bookingToRefund, status: "Refunded", refundAmount: refundAmount }]);

        const userEmail = bookingToRefund.userEmail;
        const subject = "Your Booking has been Refunded";
        const message = `${bookingToRefund.userName},\n\nYour booking (ID: ${bookingToRefund.id}) for the package "${bookingToRefund.package}" has been marked as refunded on ${new Date().toLocaleDateString()}.\n\nBooking ID: ${bookingToRefund.id}\nDeparture Date: ${bookingToRefund.departureDate}\nSelected Package: ${bookingToRefund.package}`;
        sendEmail(userEmail, subject, message);

        console.log(`Refunding ${refundAmount} for booking ID: ${id}`);
        handleCloseRefundModal();

      } catch (error) {
        console.error("Error processing refund:", error.message);
      }
    }
  } else {
    window.alert("You must be a super admin or admin to refund this booking.");
  }
};



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBookings = bookings.filter((booking) =>
    (booking.id && booking.id.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userName && booking.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userEmail && booking.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.zipCode && booking.zipCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.departureDate && booking.departureDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.schoolName && booking.schoolName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.address && booking.address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const filteredApprovedBookings = approvedBookings.filter((booking) =>
    (booking.id && booking.id.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userName && booking.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userEmail && booking.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.zipCode && booking.zipCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.departureDate && booking.departureDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.schoolName && booking.schoolName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.address && booking.address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const filteredCompletedBookings = completedBookings.filter((booking) =>
    (booking.id && booking.id.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userName && booking.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userEmail && booking.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.zipCode && booking.zipCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.departureDate && booking.departureDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.schoolName && booking.schoolName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.address && booking.address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const filteredCancelledBookings = cancelledBookings.filter((booking) =>
    (booking.id && booking.id.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userName && booking.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.userEmail && booking.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.zipCode && booking.zipCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.departureDate && booking.departureDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.schoolName && booking.schoolName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.address && booking.address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleDepartureDateChange = async (bookingId, newDate) => {
    let bookingRef, userBookingRefPrefix;
    let userId; 
    let bookingToUpdate; 

    switch (activeTab) {
        case "bookings":
            bookingRef = ref(db, `bookings/${bookingId}`);
            userBookingRefPrefix = `UserBookings`;
            break;
        case "approved":
            bookingRef = ref(db, `approvedBookings/${bookingId}`);
            userBookingRefPrefix = `UserApprovedBookings`;
            break;
        case "completed":
            bookingRef = ref(db, `completedBookings/${bookingId}`);
            userBookingRefPrefix = `UserCompletedBookings`;
            break;
        default:
            console.error("Invalid activeTab value");
            return;
    }

    if (isTypeAdmin === "superAdmin" || isTypeAdmin === "admin") {
        try {
            
            const bookingSnapshot = await get(bookingRef);
            if (bookingSnapshot.exists()) {
                bookingToUpdate = bookingSnapshot.val();
                userId = bookingToUpdate.userId; 
            } else {
                console.error("Booking not found");
                return;
            }
            const analytics = ref(db, `analytics/${bookingId}`);

            await update(bookingRef, { departureDate: newDate });

            await update(analytics, { departureDate: newDate });
            console.log(`Departure date updated successfully for booking ID: ${bookingId}`);

            const usersRef = ref(db, 'users');
            const snapshot = await get(usersRef);

            if (snapshot.exists()) {
                const users = snapshot.val();
                const updatePromises = Object.keys(users).map(async (userId) => {
                    const userBookingRef = ref(db, `${userBookingRefPrefix}/${userId}/${bookingId}`);
                    const userBookingSnapshot = await get(userBookingRef);

                    if (userBookingSnapshot.exists()) {
                        await update(userBookingRef, { departureDate: newDate });
                        console.log(`Departure date updated for user: ${userId}`);

                        const notificationMessage = `The departure date for your booking #${bookingId} has been changed to ${newDate}.`;
                        await createUserNotification(userId, notificationMessage, bookingId, 'requestDateChange'); 

                        const AdminNotificationMessage = `The departure date for booking #${bookingId} has been changed to ${newDate}.`;
                        await createAdminNotification(AdminNotificationMessage, bookingId,'requestDateChange'); 
                   
                    }
                });

                await Promise.all(updatePromises);
            }

            
            } catch (error) {
            console.error("Error updating departure date:", error.message);
        }
    } else {
        window.alert("You must be a super admin or admin to update the departure date.");
    }
};

  const sortedFilteredBookings = sortBookings(filteredBookings);
  const sortedFilteredApprovedBookings = sortBookings(filteredApprovedBookings);
  const sortedFilteredCompletedBookings = sortBookings(filteredCompletedBookings);
  const sortedFilteredCancelledBookings = sortBookings(filteredCancelledBookings);

  

  const createUserNotification = async (userId, message, bookingId,status) => {
    try {
      const notificationRef = ref(db, `notifications/${userId}`);
      const newNotificationRef = push(notificationRef);
      
      const timestamp = Date.now();
      
      await update(newNotificationRef, {
        message,
        bookingId,
        time: timestamp,
        timestamp: timestamp,
        read: false,
        statusViewed: false,
        bgColor: 'bg-blue-100',
        createdAt: new Date(timestamp).toLocaleString(),
        status: status
      });
      
      console.log("Notification created successfully");
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);



  const createAdminNotification = async (message, bookingId,status) => {
    try {
      const notificationRef = ref(db, `adminNotifications/`);
      const newNotificationRef = push(notificationRef);
      
      const timestamp = Date.now();
      
      await update(newNotificationRef, {
        message,
        bookingId,
        time: timestamp,
        timestamp: timestamp,
        read: false,
        statusViewed: false,
        bgColor: 'bg-blue-100',
        createdAt: new Date(timestamp).toLocaleString(),
        status: status
      });
      
      console.log("Notification created successfully");
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  // Function to open the refund modal
  const handleOpenRefundModal = (booking) => {
    setSelectedBooking(booking);
    setShowRefundModal(true);
  };

  // Function to close the refund modal
  const handleCloseRefundModal = () => {
    setShowRefundModal(false);
    setSelectedBooking(null);
    setRefundAmount("");
    setRefundErrorMessage("");
  };


  const renderRefundModal = () => (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleCloseRefundModal}>&times;</span>
        <h2>Refund Booking #{selectedBooking?.id}</h2>
        <p>Name: {selectedBooking?.userName}</p>
        <p>Email: {selectedBooking?.userEmail}</p>
        <p>Package: {selectedBooking?.package}</p>
        <p>Total Price: ₱{selectedBooking?.totalPrice}</p>
        <p>Downpayment: ₱{selectedBooking?.downPayment}</p>
        <p>Departure Date: {selectedBooking?.departureDate}</p>
        <p>Enter the refund amount below:</p>
        <input
          type="number"
          placeholder="Enter refund amount"
          value={refundAmount}
          onChange={(e) => setRefundAmount(e.target.value)}
          className="form-control"
        />
        {refundErrorMessage && <p className="text-red-500">{refundErrorMessage}</p>}
        <button
          className="mt-4 p-2 bg-blue-500 text-white rounded"
          onClick={() => handleRefund(selectedBooking.id)}
        >
          Yes, Refund
        </button>
        <button
          className="mt-4 p-2 bg-gray-500 text-white rounded"
          onClick={handleCloseRefundModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  

  return (
    <div>
      <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
      <div className="container d-flex fixed-top">
        <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
        <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
 
              <li><a className="nav-link scrollto active" href="adminbookinglist">Bookings</a></li>
              <li><a className="nav-link scrollto" href="manageaccounts">Manage Accounts</a></li>
              <li><a className=" nav-link scrollto" href="analytics">Dashboard</a></li>
              <li><a className=" nav-link scrollto" href="managepackages">Manage Packages</a></li>
              <li><a className=" nav-link scrollto" href="walkInBooking">Walk In</a></li>
              <li className="nav-link scrollto">
                <AdminNotification />
              </li>
          
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(prev => !prev);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                  
                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                     
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
        </div>
      </header>
{/* End Header */}

      <div className="container mt-10">
        
        <nav className="mt-30 nav nav-tabs">
          <a
            className={`mt-20 nav-item nav-link ${activeTab === "bookings" ? "active" : ""}`}
            onClick={() => setActiveTab("bookings")}
          >
            Pending
          </a>
          <a
            className={`mt-20 nav-item nav-link ${activeTab === "approved" ? "active" : ""}`}
            onClick={() => setActiveTab("approved")}
          >
            Approved Bookings
          </a>
          <a
            className={`mt-20 nav-item nav-link ${activeTab === "completed" ? "active" : ""}`}
            onClick={() => setActiveTab("completed")}
          >
            Completed Bookings
          </a>
          <a
            className={`mt-20 nav-item nav-link ${activeTab === "cancelled" ? "active" : ""}`}
            onClick={() => setActiveTab("cancelled")}
          >
            Cancelled Bookings
          </a> {/* New tab */}

          <a
            className={`mt-20 nav-item nav-link ${activeTab === "refunded" ? "active" : ""}`}
            onClick={() => setActiveTab("refunded")}
          >
            Refunded Bookings
          </a> {/* New tab */}
        </nav>
   
     

        


        
        {activeTab === "bookings" && (
          <section id="booking-list" className="">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2> Admin Booking List</h2>

              
        <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>

                <div style={{ textAlign: 'left' }}>
                  <input
                    type="text"
                    placeholder="Search bookings..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      width: '100%',
                      maxWidth: '400px',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                      outline: 'none',
                      transition: 'border-color 0.3s',
                    }}
                    onFocus={(e) => e.target.style.borderColor = '#007bff'}
                    onBlur={(e) => e.target.style.borderColor = '#ccc'}
                  />

                </div>

                {renderBookingsTable(sortedFilteredBookings, (booking) => (
                  <>
                    <button className="mb-2 btn btn-danger action-button" onClick={() => handleCancelBooking(booking.id)}>
                     Cancel
                    </button>
                    <button className="btn btn-success action-button" onClick={() => handleApproveBooking(booking.id)}>
                      Approve
                    </button>
                  </>
                ))}
              </div>
            </div>
          </section>
        )}

        



        
        



        {activeTab === "approved" && (
          <section id="approved-bookings" className="mt-10">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Admin Approved Bookings</h2>

        <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>

                <div style={{ textAlign: 'left' }}>
                  <input
                      type="text"
                      placeholder="Search bookings..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="search-input"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        width: '100%',
                        maxWidth: '400px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                        transition: 'border-color 0.3s',
                      }}
                      onFocus={(e) => e.target.style.borderColor = '#007bff'}
                      onBlur={(e) => e.target.style.borderColor = '#ccc'}
                    />
                </div>

                  

                {renderBookingsTable(sortedFilteredApprovedBookings, (booking) => (
                  <>
                    <button className="mb-2 btn btn-success action-button" onClick={() => handleCompleteBooking(booking.id)}>
                      Complete
                    </button>
                    <button className="mb-2 btn btn-danger action-button" onClick={() => handleCancelBooking(booking.id)}>
                      Cancel
                    </button>
                    <button className="btn btn-warning action-button" onClick={() => handleOpenRefundModal(booking)}>
                      Refund
                    </button>
                  </>
                ))}
              </div>
            </div>
          </section>
        )}












        {activeTab === "completed" && (
          <section id="completed-bookings" className="mt-10">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Admin Completed Bookings</h2>

                <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>

                <div style={{ textAlign: 'left' }}>
                  <input
                      type="text"
                      placeholder="Search bookings..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="search-input"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        width: '100%',
                        maxWidth: '400px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                        transition: 'border-color 0.3s',
                      }}
                      onFocus={(e) => e.target.style.borderColor = '#007bff'}
                      onBlur={(e) => e.target.style.borderColor = '#ccc'}
                    />
                </div>

                {renderBookingsTable(sortedFilteredCompletedBookings, (booking) => (
                  <button className="mb-2 btn btn-danger action-button" onClick={() => handleDeleteCompletedBooking(booking.id)}>
                    Delete
                  </button>
                ))}
              </div>
            </div>
          </section>
        )}






        {activeTab === "cancelled" && (
          <section id="cancelled-bookings" className="mt-10">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Cancelled Bookings</h2>


                <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>

                <div style={{ textAlign: 'left' }}>
                  <input
                      type="text"
                      placeholder="Search bookings..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="search-input"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        width: '100%',
                        maxWidth: '400px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                        transition: 'border-color 0.3s',
                      }}
                      onFocus={(e) => e.target.style.borderColor = '#007bff'}
                      onBlur={(e) => e.target.style.borderColor = '#ccc'}
                    />
                </div>



                {renderBookingsTable(sortedFilteredCancelledBookings, (booking) => (
                  <button className="btn btn-danger action-button" onClick={() => handleDeleteBooking(booking.id)}>
                    Delete
                  </button>
                ))}
              </div>
            </div>
          </section>
        )}

{activeTab === "refunded" && (
  <section id="refunded-bookings" className="mt-10">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Refunded Bookings</h2>

        <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>

        <div style={{ textAlign: 'left' }}>
                  <input
            type="text"
            placeholder="Search bookings..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              width: '100%',
              maxWidth: '400px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              outline: 'none',
              transition: 'border-color 0.3s',
            }}
            onFocus={(e) => e.target.style.borderColor = '#007bff'}
            onBlur={(e) => e.target.style.borderColor = '#ccc'}
          />
        </div>

        {renderBookingsTable(refundedBookings, (booking) => (
          <button className="btn btn-danger action-button" onClick={() => handleDeleteBooking(booking.id)}>
            Delete
          </button>
        ))}
    </div>
    </div>
  </section>
)}

      {/* Render Refund Modal */}
      {showRefundModal && renderRefundModal()}
      
      </div>
    </div>
  );
};




export default AdminDashboard;