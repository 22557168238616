import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, get, push, set, serverTimestamp } from "firebase/database";
import "../pages/Booking.css";
import MapComponent from './MapComponent'; 
import emailjs from '@emailjs/browser';
import Cookies from 'js-cookie'; 
import AdminNotification from './adminNotification';
import UserNotifications from './UserNotif';

// Define imageDescriptionStyle at the top of your component
const imageDescriptionStyle = {
  textAlign: 'center',
  padding: '10px',
  backgroundColor: '#f8f9fa',
  borderRadius: '0 0 8px 8px',
  fontSize: '14px',
  color: '#666',
  maxWidth: '350px',
  maxHeight: '250px'
};

const createNotification = async (userId, message, bookingId) => {
  try {
    const notificationRef = ref(db, `notifications/${userId}`);
    const newNotificationRef = push(notificationRef);
    
    const timestamp = Date.now();
    
    
    await set(newNotificationRef, {
      message,
      bookingId,
      time: timestamp,
      timestamp: timestamp,
      read: false,
      statusViewed: false,
      bgColor: 'bg-blue-100',
      createdAt: new Date(timestamp).toLocaleString(),
      status:"pending"
    });
    
    console.log("Notification created successfully");
  } catch (error) {
    console.error("Error creating notification:", error);
  }
};



const createAdminNotification = async (message, bookingId,status) => {
  try {
    const notificationRef = ref(db, `adminNotifications/`);
    const newNotificationRef = push(notificationRef);
    
    const timestamp = Date.now();
    
    await set(newNotificationRef, {
      message,
      bookingId,
      time: timestamp,
      timestamp: timestamp,
      read: false,
      statusViewed: false,
      bgColor: 'bg-blue-100',
      createdAt: new Date(timestamp).toLocaleString(),
      status: status
    });
    
    console.log("Notification created successfully");
  } catch (error) {
    console.error("Error creating notification:", error);
  }
};



const Booking = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(""); 
  const [errors, setErrors] = useState({}); 
  const [errorMessage, setErrorMessage] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(null); 
  const [formData, setFormData] = useState(() => {
     
    const savedData = Cookies.get('bookingData');
    return savedData ? JSON.parse(savedData) : {
    departureDate: '',
    schoolName: "",
    address: "",
    zipCode: "",
    departureDate: "",
    package:"",
    pax: "",
  };
});
const [showCookiePopup, setShowCookiePopup] = useState(false);
const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
const [dropdownVisible, setDropdownVisible] = useState(false);
const [bookingData, setBookingData] = useState([]);  
const [showWarning, setShowWarning] = useState(false);
const [packages, setPackages] = useState([]); // Add this state to store packages

useEffect(() => {
  const consent = Cookies.get('cookieConsent');
  if (consent) {
    setCookieConsent(consent); 
  }


}, []);
  
  const handleLocationSelect = (locationName, zipCode) => {
    setFormData((prevData) => ({
      ...prevData,
      address: locationName,
      zipCode: zipCode || '', 
  }));
};

const sessionTimeout = 300000; 
let timeout;
let warningTimeout;

const resetTimeout = () => {
  if (userLoggedIn) { 
    if (timeout) clearTimeout(timeout);
    if (warningTimeout) clearTimeout(warningTimeout);
    

    warningTimeout = setTimeout(() => {
      setShowWarning(true);
    }, sessionTimeout - 15000);
    
    timeout = setTimeout(handleSignOut1, sessionTimeout);
  }
};

useEffect(() => {
  return () => {
    if (warningTimeout) clearTimeout(warningTimeout);
  };
}, []);

  const handlePackageClick = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handlePackageSelect = (selectedPackage) => {
    setSelectedPackage(selectedPackage);
    setFormData({ ...formData, package: selectedPackage });
    setIsPopupVisible(false);
  };

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', 'accepted'); 
    setCookieConsent('accepted');
  };

  const handleRejectCookies = () => {
    Cookies.set('cookieConsent', 'rejected'); 
    setCookieConsent('rejected');
  };

  const openCookiePopup = () => {
    setShowCookiePopup(true);
  };

  const closeCookiePopup = () => {
    setShowCookiePopup(false);
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
    setFormData({
      ...formData,
      package: event.target.value
    });
  };

  const carouselImageStyle = {
    width: '100%',
    height: 'auto',
    maxWidth: '350px',
    maxHeight: '250px',
    objectFit: 'contain'
  };

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("bookingFormData"));
    if (savedData) {
      setFormData(savedData);
    }
  }, []);

  
  useEffect(() => {
    localStorage.setItem("bookingFormData", JSON.stringify(formData));
  }, [formData]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

  Cookies.set('bookingData', JSON.stringify(updatedData), { expires: 1 }); 
      return updatedData;
    });
  };



  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin);
          }
        })
        .catch((error) => console.error("Error fetching user data:", error.message));
    }
  }, [currentUser]);

  const handleSignOut = () => {
    doSignOut();
  
    window.location.href = "/home";
  };

  const handleSignOut1 = () => {
    doSignOut();
    alert("Session expired due to inactivity.");
    window.location.href = "/home";
  };

  useEffect(() => {

    resetTimeout();


    const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
    events.forEach(event => window.addEventListener(event, resetTimeout));


    return () => {
      clearTimeout(timeout);
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, []);

const today = new Date();
const oneMonthAhead = new Date(today);
const threeMonthsAhead = new Date(today);
oneMonthAhead.setMonth(today.getMonth() + 1);
threeMonthsAhead.setMonth(today.getMonth() + 3);

const minDate = oneMonthAhead.toISOString().split('T')[0];
const maxDate = threeMonthsAhead.toISOString().split('T')[0];


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!userLoggedIn) {
      setErrors({ general: "You need to be logged in to make a booking." });
      return;
    }

    const formErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = "This field is required";
      }
    });
    if (formData.pax < 50) {
      formErrors.pax = "Minimum pax should be 50";
    }
    if (formData.pax > 1000) {
      formErrors.pax = "Only 1000 pax per day";
    }

    
    const bookingDate = new Date(); 
    const departureDate = new Date(formData.departureDate);
    if (departureDate.getDay() === 1) {
      setErrors({ ...errors, departureDate: "Mondays are not selectable." });
      return; 
    }
  
    const oneMonthAhead = new Date(bookingDate);
    oneMonthAhead.setMonth(oneMonthAhead.getMonth()); 

    if (departureDate <= oneMonthAhead) {
      formErrors.departureDate = "Departure date must be at least 1 month after booking";
    }
    
    const threeMonthsFromNow = new Date(bookingDate);
    threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
    if (departureDate > threeMonthsFromNow) {
      formErrors.departureDate = "Departure date cannot be more than 3 months ahead";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const fullName = `${userData.firstName} ${userData.middleName || ""} ${userData.lastName || ""}`.trim();

        const timestamp = Date.now();

        const newBooking = {
          schoolName: formData.schoolName,
          address: formData.address,
          zipCode: formData.zipCode,
          departureDate: formData.departureDate,
          package: formData.package,
          pax: formData.pax,
          userEmail: userData.email,
          userName: fullName,
          userId: currentUser.uid,
          status: "For Approval",
          level: "pending",
          downPayment: 0,
          totalPrice: totalPrice,
          timestamp: timestamp,
          time: new Date(timestamp).toLocaleString()
        };

      
        const bookingKey = push(ref(db, "bookings")).key;
        await set(ref(db, `bookings/${bookingKey}`), newBooking);
        await set(ref(db, `UserBookings/${currentUser.uid}/${bookingKey}`), newBooking);
        await set(ref(db, `analytics/${bookingKey}`), newBooking);

     
        const notificationMessage = `Your booking request has been submitted successfully. Booking ID: ${bookingKey}`;
        await createNotification(currentUser.uid, notificationMessage, bookingKey);


        const AdminNotificationMessage = `Booking #${bookingKey} has been marked as pending.`;
        await createAdminNotification(AdminNotificationMessage, bookingKey,'pending');

        
        setFormData({
          schoolName: "",
          address: "",
          zipCode: "",
          departureDate: "",
          package: "",
          pax: "",
        });
        setTotalPrice(0);
        setSelectedPackage("");
        setErrors({});
        setBookingSuccess(true);

        const userEmail = userData.email;
        const subject = "Your Booking Confirmation";
        const message = `${fullName},\n\nThank you for your booking! Your booking is now pending.\n\nBooking ID: ${bookingKey}\nDeparture Date: ${formData.departureDate}\nSelected Package: ${formData.package}\n\nWe look forward to seeing you!`;
        sendEmail(userEmail, subject, message);
        Cookies.remove('bookingData');

      }
    } catch (error) {
      console.error("Error submitting booking:", error.message);
      setErrors({ general: "An error occurred while submitting your booking. Please try again." });
    }
  };

  const sendEmail = (userEmail, subject, message) => {
    const templateParams = {
      user_email: userEmail,
      subject: subject,
      message: message,
    };

    emailjs
      .send('service_5fwafhc', 'template_17y2dr9', templateParams, 'ymggzz4cqmsAkMw6i')
      .then(
        () => {
          console.log('Email sent successfully!');
        },
        (error) => {
          console.log('Failed to send email:', error.text);
        },
      );
  };

  useEffect(() => {
    if (formData.package && formData.pax >= 50) {
      const packagePrice = parseFloat((formData.package).split("₱")[1].split(" ")[0].replace(",", ""));
      const estimatedPrice = packagePrice * parseInt(formData.pax);
      setTotalPrice(estimatedPrice);
      
  } else {
      setTotalPrice(0);
      Cookies.remove('estimatedPrice'); 
  }
  }, [selectedPackage, formData.pax]);

 

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  useEffect(() => {
    setSelectedPackage("");
  }, []); 
  const handleBookNow = async (e) => {
    e.preventDefault();
  
 
    const missingFields = [];
  
    if (!formData.schoolName) {
      missingFields.push("School Name,");
    }
  
    if (!formData.address) {
      missingFields.push("Address,");
    }
  
    if (!formData.zipCode) {
      missingFields.push("Zip Code,");
    }
  
    if (!formData.departureDate) {
      missingFields.push("Departure Date,");
    }
  
    if (!formData.package) {
      missingFields.push("Package,");
    }
  
    if (!formData.pax) {
      missingFields.push("Number of Participants");
    }
  
    if (missingFields.length > 0) {
      setErrorMessage("Please fill in the following required fields:\n\n" + missingFields.join("\n")); 
      return;
    } else {
      setErrorMessage(""); 
    }
  
  
    await handleFormSubmit(e);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownVisible && !event.target.closest('.dropdown')) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  useEffect(() => {

    const selectedPackage = localStorage.getItem('selectedPackage');
    if (selectedPackage) {
        setFormData((prevData) => ({
            ...prevData,
            package: selectedPackage, 
        }));
    }
  }, []);

  useEffect(() => {
    const packagesRef = ref(db, 'packages');
    get(packagesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const packagesData = snapshot.val();
          const packageComponents = Object.entries(packagesData).map(([id, packageData]) => (
            <li key={id}>
              <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
                <div id={`heroCarousel${id}`} className="carousel slide" data-bs-ride="false">
                  <div style={imageDescriptionStyle} className="carousel-inner">
                    {packageData.images.map((image, index) => (
                      <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <img
                          src={image}
                          className="img-fluid"
                          style={carouselImageStyle}
                          alt={packageData.locations[index]}
                        />
                        <div style={imageDescriptionStyle}>
                          {packageData.locations[index]} 
                        </div>
                      </div>
                    ))}
                  </div>
                  <a
                    className="carousel-control-prev"
                    href={`#heroCarousel${id}`}
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href={`#heroCarousel${id}`}
                    role="button"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
              <div className="package-content">
                <h3 className="package-title">{packageData.title}</h3>
                <div className="package-price">{packageData.price}</div>
                
             
                <button 
                  className="Select-button mb-10" 
                  onClick={() => handlePackageSelect(`${packageData.title} - ${packageData.price} || ${packageData.locations.join(', ')}`)}
                >
                  Select
                </button>
              </div>


            </li>

            
          ));
          setPackages(packageComponents);
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  return (
    <div>
    {/* Cookie Consent Banner */}
    {cookieConsent === null && (
        <div className="cookie-consent-banner" style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          position: 'fixed', 
          top: '70%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          zIndex: 1000, 
          backgroundColor: '#f8f9fa', 
          padding: '20px', 
          borderRadius: '5px', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' 
        }}>
          <p>This website uses cookies to enhance the user experience. Do you accept cookies?</p>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={handleAcceptCookies}>Accept</button>
            <button onClick={handleRejectCookies}>Reject</button>
          </div>
          <span 
            onClick={openCookiePopup} 
            style={{ 
              marginTop: '10px', 
              textDecoration: 'underline', 
              color: 'blue', 
              cursor: 'pointer' 
            }}
          >
            About Cookies
          </span>
        </div>
      )}

      {/* Cookie Popup Modal */}
      {showCookiePopup && (
        <div className="cookie-popup-modal" style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '5px',
            width: '300px',
            textAlign: 'center',
          }}>
            <h4>About Cookies</h4>
            <p>
              Cookies are small text files that are used to store small pieces of information. 
              They help us make the website function properly, make it more secure, 
              provide better user experience, and understand how the website performs 
              and to analyze what works and where it needs improvement.
            </p>
            <button onClick={closeCookiePopup}>Close</button>
          </div>
        </div>
      )}

      {showWarning && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            textAlign: 'center',
            maxWidth: '400px',
            width: '90%'
          }}>
            <h4 style={{ marginBottom: '20px', color: '#333' }}>Session Timeout Warning</h4>
            <p style={{ marginBottom: '20px', color: '#666' }}>
              Your session will expire in 15 seconds. Would you like to continue?
            </p>
            <button 
              onClick={() => {
                setShowWarning(false);
                resetTimeout();
              }}
              style={{
                backgroundColor: '#4154f1',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              Continue Session
            </button>
          </div>
        </div>
      )}
    <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
      <div className="container d-flex fixed-top">
        <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
        <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
            <ul>
              <li><a className="nav-link scrollto" href="home">Home</a></li>
              <li><a className="nav-link scrollto" href="home#about">About</a></li>
              <li><a className="nav-link scrollto" href="home#team">Team</a></li>
              <li><a className="nav-link scrollto" href="home#faq">FAQ</a></li>
              <li><a className="nav-link scrollto active" href="booking">Book</a></li>
              <li><a className="nav-link scrollto" href="packages">Packages</a></li>

              
                
              {userLoggedIn && (
                <li><a className="nav-link scrollto" href="UserBookings">My Bookings</a></li>
              )}
              {userLoggedIn && (
              <div className="ml-2" style={{ marginLeft: '35px' }}>
                    <UserNotifications />
                  </div>
                )}
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(!dropdownVisible);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                

                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                      {admin === "yes" && (
                        <li><a href="adminbookinglist">Admin Dashboard</a></li>
                      )}
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
      </div>

      <div>
        {isPopupVisible && (
        <div className="popup" style={{ position: 'absolute', zIndex: 2000 }}>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg shadow-lg overflow-auto" style={{ width: '180vh', maxHeight: '54rem' }}>
            <ul className="Booking-list">
              {packages}
            </ul>
            <div className="text-center mt-4">
              <button
                onClick={handlePopupClose}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
        )}
      </div>
      </header>
      <section id="booking" className="mt-10 booking section-bg">
    <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Book Your Adventure</h2>
            <form
                onSubmit={handleBookNow}
               className="Booking-form"
            >
                <div className="mb-4"> {/* Container for school name */}
                    <label htmlFor="schoolName">Name of School:</label>
                    <input
                        type="text"
                        id="schoolName"
                        name="schoolName"
                        value={formData.schoolName}
                        onChange={handleInputChange}
                        required
                        className="w-full border-gray-300 rounded-lg p-2"
                    />
                    {errors.schoolName && <div className="text-red-500">{errors.schoolName}</div>}
                </div>

                <MapComponent onLocationSelect={handleLocationSelect} />

                <div className=" mb-4">
            
                        <label htmlFor="address" className="block">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                            className="w-full border-gray-300 rounded-lg p-2"
                        />
                    </div>
             

                <div className="mb-4"> {/* Zip Code container */}
                    <label htmlFor="zipCode">Zip Code:</label>
                    <input
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        required
                        className="w-full border-gray-300 rounded-lg p-2"
                    />
                </div>

                {errors.schoolLocation && <div className="text-red-500">{errors.schoolLocation}</div>}
                <label htmlFor="departureDate">Departure Date:</label>

              {/* Button to show the message */}
              <button 
                              onClick={() => setShowMessage(!showMessage)} 
                              className="custom-button w-48 bg-blue-500 text-white border border-black rounded-lg p-1 text-sm hover:bg-blue-600 mb-4"
                          >
                              Show Booking Info
                          </button>

              {/* Pop-up message */}
              {showMessage && (
                  <div 
                      className="popup-message" 
                      style={{ 
                          backgroundColor: 'lightyellow', 
                          padding: '10px', 
                          borderRadius: '5px', 
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
                          marginBottom: '10px',
                          width: '100%',
                          textAlign: 'center',
                      }}
                  >
                      The available booking dates for your educational field trip begin 30 days from today and must be within 3 months from today. Please note that bookings are not available on Mondays due to itinerary and bus scheduling. This 30-day minimum window allows the travel agency enough time to make all necessary preparations. Thank you for planning ahead with us!
                  </div>
              )}

              <input
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  value={formData.departureDate}
                  onChange={handleInputChange}
                  required
                  min={minDate}
                  max={maxDate}
                  className="w-full border-gray-300 rounded-lg p-2"
              />
                
            

              {errors.departureDate && <div className="text-red-500">{errors.departureDate}</div>}
              <label htmlFor="package">Choose a Package:</label>
              <input
                id="package"
                name="package"
                value={formData.package}
                onChange={handlePackageChange}
                required
                className="w-full border-gray-300 rounded-lg p-2"
                readOnly
              >
              </input>
              <button
                  id="package"
                  name="package"
                  onClick={handlePackageClick}
                  className="w-full bg-blue-500 text-white border border-black rounded-lg p-2 hover:bg-blue-600 mb-4"
                >   
                  Select Package
              </button>

              
              
              {errors.package && <div className="text-red-500">{errors.package}</div>}
                           
              <label htmlFor="pax">Number of Pax (Min 50/Max 1000):</label>
              <input
                type="number"
                id="pax"
                name="pax"
                value={formData.pax}
                onChange={handleInputChange}
                required
                className="w-full border-gray-300 rounded-lg p-2"
              />
              {errors.pax && <div className="text-red-500">{errors.pax}</div>}
              
              <label htmlFor="totalPrice">Estimated Price:</label>
              <input
                type="text"
                id="totalPrice"
                name="totalPrice"
                value={`₱${totalPrice.toFixed(2)}`}
                readOnly
                className="w-full border-gray-300 rounded-lg p-2"
              />
               {errorMessage && <div className="error-message text-red-500">{errorMessage}</div>}
    <button type="submit" className="book-now" onClick={handleBookNow}>Book Now</button>
              {errors.general && <div className="text-red-500 mt-3">{errors.general}</div>}
              {bookingSuccess && (
                <div className="text-green-500 mt-3" style={{ fontSize: '1.5rem' }}>
                Waiting for approval
              </div>
                
              )}
               

               
            </form>
          </div>
        </div>
      </section>
      
      
      <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>Etourmo</h3>
                  <p>
                  214 Abacan St. Brgy Calvario <br />
                  Meycauayan Bulacan<br />
                    Philippines <br /><br />
                    <strong>Phone:</strong> 0908 300 0009<br />
                    <strong>Email:</strong>ETOURMO@GMAIL.COM<br />
                  </p>
                </div>
                
                
               
              </div>
            </div>
          </div>
          <div className="container footer-bottom clearfix">
            <div className="copyright">
              © Copyright <strong><span>Arsha</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/ */}
              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
          </div>
        </footer>{/* End Footer */} 
    </div>

  );
};

export default Booking;