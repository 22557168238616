import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
   apiKey: "AIzaSyC83PKN61zrDqVILI7N5ML43vsiUD6zCVc",
  authDomain: "etourmo-48a28.firebaseapp.com",
  databaseURL: "https://etourmo-48a28-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "etourmo-48a28",
  storageBucket: "etourmo-48a28.appspot.com",
  messagingSenderId: "992498926017",
  appId: "1:992498926017:web:ab314480b56fb526a1af40",
  measurementId: "G-XQSG2KHK52"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getDatabase(app);
const storage = getStorage(app);
 
export { app, auth, db, storage };
