import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { db } from '../firebase/firebase';
import { useAuth } from "../contexts/authContext";
import { useNavigate } from 'react-router-dom';

const UserNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const { currentUser } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0);
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!currentUser) return;

    const notificationsRef = ref(db, `notifications/${currentUser.uid}`);
    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      if (snapshot.exists()) {
        const notificationsData = snapshot.val();
        const notificationsArray = Object.entries(notificationsData).map(([id, data]) => ({
          id,
          ...data,
          createdAt: data.createdAt
        }));

       
        notificationsArray.sort((a, b) => b.timestamp - a.timestamp);
        
        setNotifications(notificationsArray);

        const unread = notificationsArray.filter(notif => !notif.read).length;
        setUnreadCount(unread);
      } else {
        setNotifications([]);
        setUnreadCount(0);
      }
    });

    return () => unsubscribe();
  }, [currentUser]);

  const markAsRead = async (notificationId) => {
    if (!currentUser) return;
    
    try {
      const notificationRef = ref(db, `notifications/${currentUser.uid}/${notificationId}`);
      await update(notificationRef, { read: true });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllAsRead = async () => {
    if (!currentUser || notifications.length === 0) return;

    try {
      const updates = {};
      notifications.forEach(notification => {
        if (!notification.read) {
          updates[`notifications/${currentUser.uid}/${notification.id}/read`] = true;
        }
      });
      await update(ref(db), updates);
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

 
  const handleNotificationClick = async (notification) => {
    try {

      const notificationRef = ref(db, `notifications/${currentUser.uid}/${notification.id}`);
      await update(notificationRef, { 
        statusViewed: true,
        read: true 
      });

     
      switch(notification.status) {
        case 'pending':
          navigate('/UserBookings', { state: { activeTab: 'bookings' } });
          break;
        case 'approved':
          navigate('/UserBookings', { state: { activeTab: 'approved' } });
          break;
        case 'completed':
          navigate('/UserBookings', { state: { activeTab: 'completed' } });
          break;
        case 'cancelled':
          navigate('/UserBookings', { state: { activeTab: 'cancelled' } });
          break;
        case 'refunded':
          navigate('/UserBookings', { state: { activeTab: 'cancelled' } });
          break;
        case 'requestDateChange':
          navigate('/UserBookings', { state: { activeTab: 'approved' } });

          case 'settledDownpayment':
          navigate('/UserBookings', { state: { activeTab: 'approved' } });
          break;
        default:
          navigate('/UserBookings');
      }
      
      setShowNotifications(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="relative">
   
      <button 
        onClick={() => setShowNotifications(!showNotifications)}
        className="relative p-2 bg-[#302b63] hover:bg-[#24204d] rounded-md transition-colors duration-200"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-6 w-6" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="white"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" 
          />
        </svg>
        {unreadCount > 0 && (
          <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
            {unreadCount}
          </span>
        )}
      </button>

      {/* Notifications Dropdown */}
      {showNotifications && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl z-50 max-h-96 overflow-y-auto">
          <div className="p-4 border-b">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Notifications</h3>
              {unreadCount > 0 && (
                <button 
                  onClick={markAllAsRead}
                  className="text-sm text-[#302b63] bg-white hover:text-black px-3 py-1 rounded-md transition-colors duration-200"
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>
          
          <div className="divide-y divide-gray-100">
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <div 
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                  className={`p-4 hover:bg-gray-50 cursor-pointer ${
                    notification.bgColor
                  } ${!notification.statusViewed ? 'font-semibold' : ''}`}
                >
                  <p className="text-sm text-gray-800">{notification.message}</p>
                  <p className="text-xs text-gray-500 mt-1">
                    {notification.createdAt}
                  </p>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No notifications
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNotifications;
