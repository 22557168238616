import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, get, push, set } from "firebase/database";
import "../pages/Booking.css";
import MapComponent from './MapComponent'; 
import Cookies from 'js-cookie'; 
import AdminNotification from './adminNotification';

const WalkIn = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(""); 
  const [errors, setErrors] = useState({}); 
  const [errorMessage, setErrorMessage] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(null); 
  const [formData, setFormData] = useState(() => {
    const savedData = Cookies.get('bookingData');
    return savedData ? JSON.parse(savedData) : {
      departureDate: '',
      schoolName: "",
      address: "",
      zipCode: "",
      departureDate: "",
      package: "",
      pax: "",
    };
  });
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [bookingData, setBookingData] = useState([]);  
  const [showWarning, setShowWarning] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (consent) {
      setCookieConsent(consent); 
    }
  }, []);

  const handleLocationSelect = (locationName, zipCode) => {
    setFormData((prevData) => ({
      ...prevData,
      address: locationName,
      zipCode: zipCode || '', 
    }));
  };

  const handlePackageClick = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handlePackageSelect = (selectedPackage) => {
    setSelectedPackage(selectedPackage);
    setFormData({ ...formData, package: selectedPackage });
    setIsPopupVisible(false);
  };

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', 'accepted'); 
    setCookieConsent('accepted');
  };

  const handleRejectCookies = () => {
    Cookies.set('cookieConsent', 'rejected'); 
    setCookieConsent('rejected');
  };

  const openCookiePopup = () => {
    setShowCookiePopup(true);
  };

  const closeCookiePopup = () => {
    setShowCookiePopup(false);
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
    setFormData({
      ...formData,
      package: event.target.value
    });
  };

  const carouselImageStyle = {
   
  };

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("bookingFormData"));
    if (savedData) {
      setFormData(savedData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("bookingFormData", JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

  Cookies.set('bookingData', JSON.stringify(updatedData), { expires: 1 }); 
      return updatedData;
    });
  };

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin);
          }
        })
        .catch((error) => console.error("Error fetching user data:", error.message));
    }
  }, [currentUser]);

  const handleSignOut = () => {
    doSignOut();
  
    window.location.href = "/home";
  };

  const handleSignOut1 = () => {
    doSignOut();
    alert("Session expired due to inactivity.");
    window.location.href = "/home";
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!userLoggedIn) {
      setErrors({ general: "You need to be logged in to make a booking." });
      return;
    }

    const formErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = "This field is required";
      }
    });
    if (formData.pax < 50) {
      formErrors.pax = "Minimum pax should be 50";
    }
    if (formData.pax > 1000) {
      formErrors.pax = "Only 1000 pax per day";
    }

    
    const bookingDate = new Date(); 
    const departureDate = new Date(formData.departureDate);
    if (departureDate.getDay() === 1) {
      setErrors({ ...errors, departureDate: "Mondays are not selectable." });
      return; 
    }
  
    const oneMonthAhead = new Date(bookingDate);
    oneMonthAhead.setMonth(oneMonthAhead.getMonth()); 

    if (departureDate <= oneMonthAhead) {
      formErrors.departureDate = "Departure date must be at least 1 month after booking";
    }
    
    const threeMonthsFromNow = new Date(bookingDate);
    threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
    if (departureDate > threeMonthsFromNow) {
      formErrors.departureDate = "Departure date cannot be more than 3 months ahead";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const fullName = `${userData.firstName} ${userData.middleName || ""} ${userData.lastName || ""}`.trim();

        const timestamp = Date.now();

        const newBooking = {
          schoolName: formData.schoolName,
          address: formData.address,
          zipCode: formData.zipCode,
          departureDate: formData.departureDate,
          package: formData.package,
          pax: formData.pax,
          userEmail: userData.email,
          userName: fullName,
          userId: currentUser.uid,
          status: "For Approval",
          level: "pending",
          downPayment: 0,
          totalPrice: totalPrice,
          timestamp: timestamp,
          time: new Date(timestamp).toLocaleString()
        };


      
        const bookingKey = push(ref(db, "bookings")).key;
        await set(ref(db, `bookings/${bookingKey}`), newBooking);
        await set(ref(db, `UserBookings/${currentUser.uid}/${bookingKey}`), newBooking);
        await set(ref(db, `analytics/${bookingKey}`), newBooking);

     
        
        setFormData({
          schoolName: "",
          address: "",
          zipCode: "",
          departureDate: "",
          package: "",
          pax: "",
        });
        setTotalPrice(0);
        setSelectedPackage("");
        setErrors({});
        setBookingSuccess(true);

        Cookies.remove('bookingData');

      }
    } catch (error) {
      console.error("Error submitting booking:", error.message);
      setErrors({ general: "An error occurred while submitting your booking. Please try again." });
    }
  };

  useEffect(() => {
    if (formData.package && formData.pax >= 50) {
      const packagePrice = parseFloat((formData.package).split("₱")[1].split(" ")[0].replace(",", ""));
      const estimatedPrice = packagePrice * parseInt(formData.pax);
      setTotalPrice(estimatedPrice);
      
  } else {
      setTotalPrice(0);
      Cookies.remove('estimatedPrice'); 
  }
  }, [selectedPackage, formData.pax]);

  const handlePayNow = () => {
    window.location.href = "UserBookings"; 
  };

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  useEffect(() => {
    setSelectedPackage("");
  }, []); 

  const handleBookNow = async (e) => {
    e.preventDefault();
  

    const missingFields = [];
  
    if (!formData.schoolName) {
      missingFields.push("School Name");
    }
  
    if (!formData.address) {
      missingFields.push("Address");
    }
  
    if (!formData.zipCode) {
      missingFields.push("Zip Code");
    }
  
    if (!formData.departureDate) {
      missingFields.push("Departure Date");
    }
  
    if (!formData.package) {
      missingFields.push("Package");
    }
  
    if (!formData.pax) {
      missingFields.push("Number of Participants");
    }
  
    if (missingFields.length > 0) {
      setErrorMessage("Please fill in the following required fields:\n\n" + missingFields.join("\n"));  
      return;
    } else {
      setErrorMessage("");
    }
  
    await handleFormSubmit(e);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownVisible && !event.target.closest('.dropdown')) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  const today = new Date();
  const oneMonthAhead = new Date(today);
  const threeMonthsAhead = new Date(today);
  oneMonthAhead.setMonth(today.getMonth() + 1);
  threeMonthsAhead.setMonth(today.getMonth() + 3);

  const minDate = oneMonthAhead.toISOString().split('T')[0];
  const maxDate = threeMonthsAhead.toISOString().split('T')[0];

  return (
    <div>
    {/* Cookie Consent Banner */}
    {cookieConsent === null && (
        <div className="cookie-consent-banner" style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          position: 'fixed', 
          top: '70%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          zIndex: 1000, 
          backgroundColor: '#f8f9fa', 
          padding: '20px', 
          borderRadius: '5px', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' 
        }}>
          <p>This website uses cookies to enhance the user experience. Do you accept cookies?</p>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={handleAcceptCookies}>Accept</button>
            <button onClick={handleRejectCookies}>Reject</button>
          </div>
          <span 
            onClick={openCookiePopup} 
            style={{ 
              marginTop: '10px', 
              textDecoration: 'underline', 
              color: 'blue', 
              cursor: 'pointer' 
            }}
          >
            About Cookies
          </span>
        </div>
      )}

      {/* Cookie Popup Modal */}
      {showCookiePopup && (
        <div className="cookie-popup-modal" style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '5px',
            width: '300px',
            textAlign: 'center',
          }}>
            <h4>About Cookies</h4>
            <p>
              Cookies are small text files that are used to store small pieces of information. 
              They help us make the website function properly, make it more secure, 
              provide better user experience, and understand how the website performs 
              and to analyze what works and where it needs improvement.
            </p>
            <button onClick={closeCookiePopup}>Close</button>
          </div>
        </div>
      )}

      {showWarning && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            textAlign: 'center',
            maxWidth: '400px',
            width: '90%'
          }}>
            <h4 style={{ marginBottom: '20px', color: '#333' }}>Session Timeout Warning</h4>
            <p style={{ marginBottom: '20px', color: '#666' }}>
              Your session will expire in 15 seconds. Would you like to continue?
            </p>
            <button 
              onClick={() => {
                setShowWarning(false);
              }}
              style={{
                backgroundColor: '#4154f1',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              Continue Session
            </button>
          </div>
        </div>
      )}
     <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
      <div className="container d-flex fixed-top">
        <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
        <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
 
              <li><a className="nav-link scrollto" href="adminbookinglist">Bookings</a></li>
           
              <li><a className="nav-link scrollto" href="manageaccounts">Manage Accounts</a></li>
              <li><a className=" nav-link scrollto" href="analytics">Dashboard</a></li>
              <li><a className=" nav-link scrollto" href="managepackages">Manage Packages</a></li>
              <li><a className=" nav-link scrollto active" href="walkInBooking">Walk In</a></li>
              <li className="nav-link scrollto">
                <AdminNotification />
              </li>
          
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(prev => !prev);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                  
                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                     
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
        </div>


      <div>
        {isPopupVisible && (
        <div className="popup" style={{ position: 'absolute', zIndex: 2000 }}>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg shadow-lg overflow-auto" style={{ width: '180vh', maxHeight: '54rem' }}>
            <ul className="package-list">
          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel1" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 1/Luneta Park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 1/Presidential Car Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 1/QC Museum.jpeg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 1/Star City.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                  </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel1"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel1"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>            
            </div>
          </div>
          <span><strong style={{ color: '#302b63' }}>Package 1: Luneta Park, Presidential Car Museum, QC Museum, Star City</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 1 - Starts at ₱1,440.00 per head || Star City, Luneta Park, QC Museum, Presidential Car Museum')}>Select</button>
          </li>




          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel2" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 2/Luneta Park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle}
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 2/Presidential Car Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle}
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 2/QC Museum.jpeg"
                    className="img-fluid"
                    style={carouselImageStyle}
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 2/Tales of Illumina.jpg"
                    className="img-fluid"
                    style={carouselImageStyle}
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel2"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel2"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
            <span>
              <strong style={{ color: '#302b63' }}>
                Package 2: Luneta Park, Presidential Car Museum, QC Museum, Tales of Illumina
              </strong>
            </span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 2 - Starts at ₱1,530.00 per head || Tales of Illumina, Luneta Park, QC Museum, Presidential Car Museum')}>Select</button>
          </div>
          </li>




          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel3" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 3/Manila Zoo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 3/Museo Pambata.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 3/National-library.webp"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel3"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel3"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 3: Manila Zoo, Museo Pambata, National Library</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 3 - Starts at ₱1,550.00 per head || Museo Pambata, Manila Zoo, National Library')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel4" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 4/ArtInIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 4/Malacanang Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 4/Presidential Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 4/QC Museum.jpeg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel4"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel4"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 4: Art in Island, Malacanang Museum, Presidential Museum, QC Museum</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 4 - Starts at ₱1,600.00 per head || Art in Island, Malacañang Museum, Presidential Museum, QC Museum')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel5" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 5/AvilonZoo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 5/Doll Joy Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 5/Presidential Car Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 5/QC Shrine.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel5"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel5"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 5 Avilon Zoo, Doll Joy Museum, Presidential Car Museum, QC Shrine</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 5 - Starts at ₱1,600.00 per head || Avilon Zoo, Doll Joy Museum, QC Shrine, Presidential Car Museum')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel6" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 6/Dessert Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 6/Fort Santiago.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 6/MMDA road safety park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 6/National Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel6"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel6"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 6 Dessert Museum, Fort Santiago, MMDA Road Safety Park, National Museum</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 6 - Starts at ₱1,620.00 per head || National Museum, Fort Santiago, Dessert Museum, MMDA Road Safety Park')}>Select</button>
          </li>
          
          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel7" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 7/MMDA road safety park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 7/Museo Pambata.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 7/national-library.webp"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 7/Star City.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel7"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel7"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 7: MMDA Road Safety Park, Museo Pambata, National Library, Star City</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 7 - Starts at ₱1,700.00 per head || Star City, National Library, Museo Pambata, MMDA Road Safety Park')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel8" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 8/Luneta Park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 8/National Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 8/Wisdome of Da Vinci.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel8"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel8"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 8: Luneta Park, National Museum, Wisdome of Da Vinci</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 8 - Starts at ₱1,700.00 per head || Luneta Park, Wisdome of Da Vinci, National Museum')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel9" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 9/Doll Joy Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 9/Fort Santiago.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 9/Mind Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel9"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel9"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 9: Doll Joy Museum, Fort Santiago, Mind Museum</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 9 - Starts at ₱1,755.00 per head || Doll Joy Museum, Mind Museum, Fort Santiago')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel10" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 10/Doll Joy Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 10/Manila Zoo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 10/MMDA road safety park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 10/PlayLab.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel10"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel10"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 10: Doll Joy Museum, Manila Zoo, MMDA Road Safety Park, PlayLab</strong> </span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 10 - Starts at ₱1,800.00 per head || Doll Joy Museum, Playlab, Manila Zoo, MMDA Road Safety Park')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel11" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 11/Fort Santiago.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 11/Luneta Park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 11/Manila Ocean Park.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 11/National Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel11"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel11"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 11: Fort Santiago, Luneta Park, Manila Ocean Park, National Museum</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 11 - Starts at ₱1,780.00 per head || Manila Ocean Park, Luneta Park, National Museum, Fort Santiago')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel12" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 12/Lakbay Museo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 12/Mall of Asia.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 12/Manila Zoo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 12/National Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel12"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel12"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 12: Lakbay Museo, Mall of Asia, Manila Zoo, National Museum</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 12 - Starts at ₱1,750.00 per head || Manila Zoo, Lakbay Museo, National Museum, Mall of Asia')}>Select</button>
          </li>
          
          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel13" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 13/AvilonZoo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 13/Presidential Car Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 13/QC Museum.jpeg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 13/Tales of Illumina.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel13"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel13"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 13: Avilon Zoo, Presidential Car Museum, QC Museum, Tales of Illumina</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 13 - Starts at ₱1,750.00 per head || Tales of Illumina, Avilon Zoo, QC Museum, Presidential Car Museum')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel14" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 14/Libingan ng mga bayani.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 14/Omniverse.jpeg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 14/Venice Grand Canal.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel14"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel14"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 14: Libingan ng mga Bayani, Omniverse, Venice Grand Canal</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 14 - Starts at ₱1,800.00 per head || Omniverse, Venice Grand Canal, Libingan ng mga Bayani')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel15" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 15/Enchanted Kingdom.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 15/Gardenia Plant.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 15/Ketchup Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 15/Rizal Shrine.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel15"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel15"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 15: Enhcanted Kingdom, Gardenia Plant, Ketchup Museum, Rizal Shrine</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 15 - Starts at ₱1,700.00 per head || Enchanted Kingdom, Gardenia Plant Tour, Ketchup Museum, Rizal Shrine')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel16" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 16/Gardenia Plant.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 16/Rizal Shrine.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 16/St John the Baptist Church.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 16/sta elena fun farm.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel16"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel16"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 16: Gardenia Plant, Rizal Shrine, St. John the Baptist Church, Sta. Elena Fun Farm</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 16 - Starts at ₱1,645.00 per head || Sta. Elena Fun Farm, Rizal Shrine, St. John the Baptist, Gardenia Plant Tour')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel17" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 17/Ginger Bread House.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 17/Residence Inn.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 17/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel17"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel17"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 17: Ginger Bread House, Residence Inn, Sky Ranch</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 17 - Starts at ₱1,760.00 per head || Gingerbread House, Residence Inn Zoo, Sky Ranch')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel18" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 18/Ginger Bread House.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 18/Residence Inn.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 18/Yokiz Farm.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel18"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel18"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 18: Ginger Bread House, Residence Inn, Yokiz Farm</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 18 - Starts at ₱1,700.00 per head || Residence Inn Zoo, Yokis Farm, Gingerbread House')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel19" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 19/Enchanted Kingdom.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 19/Nuvali.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 19/Picnic Groove.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 19/Residence Inn.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel19"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel19"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 19: Enchanted Kingdom, Nuvali, Picnic Groove, Residence Inn</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 19 - Starts at ₱1,850.00 per head || Enchanted Kingdom, Residence Inn Zoo, Picnic Groove, Nuvali')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel20" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 20/Enchanted Kingdom.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 20/Residence Inn.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 20/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel20"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel20"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 20: Enchanted Kingdom, Residence Inn, Sky Ranch</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 20 - Starts at ₱1,900.00 per head || Enchanted Kingdom, Residence Inn Zoo, Sky Ranch')}>Select</button>
          </li>
          
          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel21" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 21/Pasalubong Area.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 21/Shercon resort.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel21"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel21"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 21: Pasalubong Area, Shercon Resort</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 21 - Starts at ₱1,700.00 per head || Shercon Resort, Pasalubong Area')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel22" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 22/Amana Waterpark.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 22/Barasaoin Churce.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 22/Ciudad de Victoria.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel22"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel22"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 22: Amana Waterpark, Barasaoin Churj, Ciudad de Victoria</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 22 - Starts at ₱1,745.00 per head || Amana Waterpark, Ciudad de Victoria, Barasoain Church')}>Select</button>
          </li>
          
          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel23" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 23/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 23/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 23/Hanza Toys.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel23"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel23"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 23: Dinosaur Island, Duty Free, Hanza Toys</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 23 - Starts at ₱1,470.00 per head || Dinosaurs Island, Hanza Toys, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel24" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 24/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 24/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 24/Zoocobia.jfif"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel24"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel24"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 24: Dinosaur Island, Duty Free, Zoocobia</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 24 - Starts at ₱1,490.00 per head || Dinosaurs Island, Zoocobia, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel25" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 25/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 25/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 25/Hanza Toys.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 25/Zoocobia.jfif"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel25"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel25"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 25: Dinosaur Island, Duty Free, Hanza Toys, Zoocobia</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 25 - Starts at ₱1,620.00 per head || Dinosaurs Island, Zoocobia, Hanza Toys, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel26" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 26/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 26/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 26/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel26"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel26"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 26: Dinosaur Island, Duty Free, Sky Ranch</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 26 - Starts at ₱1,650.00 per head || Dinosaurs Island, Sky Ranch, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel27" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 27/Duty free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 27/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 27/Zoocobia.jfif"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel27"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel27"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 27: Duty Free, Sky Ranch, Zoocobia</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 27 - Starts at ₱1,650.00 per head || Zoocobia, Sky Ranch, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel28" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 28/Clark Safari.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 28/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 28/Hanza Toys.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel28"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel28"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 28: Clark Safari, Duty Free, Hanza Toys</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 28 - Starts at ₱1,775.00 per head || Clark Safari, Hanza Toys, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel29" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 29/Clark Airbase.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 29/Clark Safari.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 29/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 29/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel29"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel29"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 29: Clark Airbase, Clark Safari, Dinosaur Island, Duty Free</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 29 - Starts at ₱1,850.00 per head || Dinosaurs Island, Clark Safari, Clark Airbase, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel30" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 30/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 30/Duty Free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 30/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 30/Zoocobia.jfif"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel30"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel30"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package  30: Dinosaur Island, Duty Free, Sky Ranch, Zoocobia</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 30 - Starts at ₱1,780.00 per head || Dinosaurs Island, Zoocobia, Sky Ranch, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel31" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 31/Clark Safari.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 31/DinosaursIsland.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 31/Duty free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 31/Zoocobia.jfif"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel31"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel31"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package  31: Clark Safari, Dinosaur Island, Duty Free, Zoocobia</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 31 - Starts at ₱1,940.00 per head || Dinosaurs Island, Zoocobia, Clark Safari, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel32" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 32/Barasaoin Church.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 32/Clark Safari.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 32/Duty free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 32/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 4"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel32"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel32"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 32: Barasaoin Church, Clark Safari, Duty Free, Sky Ranch</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 32 - Starts at ₱1,955.00 per head || Barasoain Church, Clark Safari, Sky Ranch, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel33" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 33/Barasaoin Church.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 33/Barasaoin museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 33/Zoocobia.jfif"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel33"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel33"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 33: Barasaoin Church, Barasaoin Museum, Zoocobia</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 33 - Starts at ₱1,455.00 per head || Zoocobia, Barasoain Church, Barasoain Museum')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel34" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 34/Barasaoin Church.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 34/Barasaoin Museum.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 34/Ocean Adventure.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel34"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel34"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 34: Barasaoin Church, Barasaoin Museum, Ocean Adventure</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 34 - Starts at ₱1,700.00 per head || Ocean Adventure, Barasoain Church, Barasoain Museum')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel35" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 35/Duty free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 35/Ocean Adventure.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 35/Zoobic safari.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel35"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel35"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 35: Duty Free, Ocean Adventure, Zoobic Safari</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 35 - Starts at ₱2,100.00 per head || Ocean Adventure, Zoobic Safari, Duty Free')}>Select</button>
          </li>

          <li>
          <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
            <div id="heroCarousel36" className="carousel slide" data-bs-ride="false">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/Packages/Package 36/Duty free.jpg"
                    className="img-fluid"
                    style={carouselImageStyle}
                    alt="Place 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 36/Sky Ranch.png"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/Packages/Package 36/Zoobic safari.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="Place 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel36"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel36"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>   
            </div>
          </div>
            <span><strong style={{ color: '#302b63' }}>Package 36: Duty Free, Sky Ranch, Zoobic Safari</strong></span>
            <button className="Select-button" onClick={() => handlePackageSelect('Package 36 - Starts at ₱1,765.00 per head || Zoobic Safari, Sky Ranch, Duty Free')}>Select</button>
          </li>
            </ul>
          <div className="flex justify-center items-center">
              <button onClick={handlePopupClose} className="mt-4 border-gray-300 rounded-lg p-2">
              Close
              </button>
          </div>
          </div>
        </div>
        </div>
      )}
    </div>
      </header>
      <section id="booking" className="mt-10 booking section-bg">
    <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Walk In Clients</h2>
            <form
                onSubmit={handleBookNow}
                className="Booking-form"
            >
                {/* New input fields for name and email */}
                <div className="input-container">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="w-full border-gray-300 rounded-lg p-2"
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full border-gray-300 rounded-lg p-2"
                  />
                </div>

                <div className="mb-4"> {/* Container for school name */}
                    <label htmlFor="schoolName">Name of School:</label>
                    <input
                        type="text"
                        id="schoolName"
                        name="schoolName"
                        value={formData.schoolName}
                        onChange={handleInputChange}
                        required
                        className="w-full border-gray-300 rounded-lg p-2"
                    />
                    {errors.schoolName && <div className="text-red-500">{errors.schoolName}</div>}
                </div>

                <MapComponent onLocationSelect={handleLocationSelect} />

                <div className=" mb-4">
            
                        <label htmlFor="address" className="block">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                            className="w-full border-gray-300 rounded-lg p-2"
                        />
                    </div>
             

                <div className="mb-4"> {/* Zip Code container */}
                    <label htmlFor="zipCode">Zip Code:</label>
                    <input
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        required
                        className="w-full border-gray-300 rounded-lg p-2"
                    />
                </div>

                {errors.schoolLocation && <div className="text-red-500">{errors.schoolLocation}</div>}
                <label htmlFor="departureDate">Departure Date:</label>

              {/* Button to show the message */}
              <button 
                              onClick={() => setShowMessage(!showMessage)} 
                              className="custom-button w-48 bg-blue-500 text-white border border-black rounded-lg p-1 text-sm hover:bg-blue-600 mb-4"
                          >
                              Show Booking Info
                          </button>

              {/* Pop-up message */}
              {showMessage && (
                  <div 
                      className="popup-message" 
                      style={{ 
                          backgroundColor: 'lightyellow', 
                          padding: '10px', 
                          borderRadius: '5px', 
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
                          marginBottom: '10px',
                          width: '100%',
                          textAlign: 'center',
                      }}
                  >
                      The available booking dates for your educational field trip begin 30 days from today and must be within 3 months from today. Please note that bookings are not available on Mondays due to itinerary and bus scheduling. This 30-day minimum window allows the travel agency enough time to make all necessary preparations. Thank you for planning ahead with us!
                  </div>
              )}

              <input
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  value={formData.departureDate}
                  onChange={handleInputChange}
                  required
                  min={minDate}
                  max={maxDate}
                  className="w-full border-gray-300 rounded-lg p-2"
              />
                
            

              {errors.departureDate && <div className="text-red-500">{errors.departureDate}</div>}
              <label htmlFor="package">Choose a Package:</label>
              <input
                id="package"
                name="package"
                value={formData.package}
                onChange={handlePackageChange}
                required
                className="w-full border-gray-300 rounded-lg p-2"
                readOnly
              >
              </input>
              <button
                  id="package"
                  name="package"
                  onClick={handlePackageClick}
                  className="w-full bg-blue-500 text-white border border-black rounded-lg p-2 hover:bg-blue-600 mb-4"
                >   
                  Select Package
              </button>

              
              
              {errors.package && <div className="text-red-500">{errors.package}</div>}
                           
              <label htmlFor="pax">Number of Pax (Min 50/Max 1000):</label>
              <input
                type="number"
                id="pax"
                name="pax"
                value={formData.pax}
                onChange={handleInputChange}
                required
                className="w-full border-gray-300 rounded-lg p-2"
              />
              {errors.pax && <div className="text-red-500">{errors.pax}</div>}
              
              <label htmlFor="totalPrice">Estimated Price:</label>
              <input
                type="text"
                id="totalPrice"
                name="totalPrice"
                value={`₱${totalPrice.toFixed(2)}`}
                readOnly
                className="w-full border-gray-300 rounded-lg p-2"
              />
             {errorMessage && <div className="error-message text-red-500">{errorMessage}</div>}
             <button type="submit" className="book-now" onClick={handleBookNow}>Book Now</button>
              {errors.general && <div className="text-red-500 mt-3">{errors.general}</div>}
              {bookingSuccess && (
                <div className="text-green-500 mt-3" style={{ fontSize: '1.5rem' }}>
                Waiting for approval
              </div>
                
              )}
               

             
            </form>
          </div>
        </div>
      </section>
      
      
      <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>Etourmo</h3>
                  <p>
                  214 Abacan St. Brgy Calvario <br />
                  Meycauayan Bulacan<br />
                    Philippines <br /><br />
                    <strong>Phone:</strong> 0908 300 0009<br />
                    <strong>Email:</strong>ETOURMO@GMAIL.COM<br />
                  </p>
                </div>
                
                
               
              </div>
            </div>
          </div>
          <div className="container footer-bottom clearfix">
            <div className="copyright">
              © Copyright <strong><span>Arsha</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
               Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
          </div>
        </footer>{/* End Footer */} 
    </div>

  );
};

export default WalkIn;