import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";

import Profile from "./pages/profile";
import Etourmo from "./pages/home";
import Booking from "./pages/Booking";
import UserBookings from "./pages/UserBookings";
import RegisterComplete from "./pages/registerComplete";
import Packages from "./pages/Packages"


import DashboardCheck from "./checkAdmin/dashboardCheck";
import ManageCheck from "./checkAdmin/manageCheck";
import ManagePackageCheck from "./checkAdmin/managePackageCheck";

import AnalyticsCheck from "./checkAdmin/anayticsCheck";
import WalkinCheck from "./checkAdmin/walkinCheck";




import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";


function App() {
  const routesArray = [
    {
      path: "*",
      element: <Etourmo />,
    },

    {
      path: "/login",
      element: <Login />,
    },

    {
      path: "/register",
      element: <Register />,
    },

    
    

    {
      path: "/booking",
      element: <Booking />,
    },

    { path: "/profile",
      element: <Profile />,
    },


     { path: "/adminbookinglist",
      element: <DashboardCheck />,
    },


     { path: "/manageaccounts",
      element: <ManageCheck />,
    },

    { path: "/UserBookings",
      element: <UserBookings />,
    },

    {
      path: "/analytics",
      element: <AnalyticsCheck />,
    },

    {
      path: "/registrationComplete",
      element: <RegisterComplete />,
    },


 {
      path: "/walkInBooking",
      element: <WalkinCheck />,
    },

    
 {
  path: "/packages",
  element: <Packages />,
},


{
  path: "/managepackages",
  element: <ManagePackageCheck />,
},
   
   
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      <Header />
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
