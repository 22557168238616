import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import {
  doSignInWithEmailAndPassword,
  doPasswordReset,
} from "../../../firebase/auth";
import { useAuth } from "../../../contexts/authContext";
import { deleteUser,getAuth, } from "firebase/auth";
import { ref, set, get } from "firebase/database"; 
import { db } from "../../../firebase/firebase";


const Login = () => {
  const { userLoggedIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);
  const [isLockedOut, setIsLockedOut] = useState(false);
  const [lockoutStart, setLockoutStart] = useState(0);
  const [lockoutDuration, setLockoutDuration] = useState(10);
  const [resetMessage, setResetMessage] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const storedLockoutStart = parseInt(localStorage.getItem("lockoutStart"));
    if (!isNaN(storedLockoutStart) && storedLockoutStart > 0) {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - storedLockoutStart;
      if (elapsedTime < lockoutDuration) {
        setIsLockedOut(true);
        setLockoutStart(storedLockoutStart);
        setLockoutDuration(lockoutDuration - elapsedTime);
        startCountdown();
      } else {
        setIsLockedOut(false);
        localStorage.removeItem("lockoutStart");
      }
    }

    if (userLoggedIn){
      setEmailVerified(true);
    }
  }, []);

  const startCountdown = () => {
    const timer = setInterval(() => {
      setLockoutDuration((prevDuration) => {
        const newDuration = prevDuration - 1;
        if (newDuration <= 0) {
          clearInterval(timer);
          setIsLockedOut(false);
          localStorage.removeItem("lockoutStart");
        }
        return newDuration;
      });
    }, 1000);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      if (!email || !password) {
        setErrorMessage("Please provide both email and password");
        return;
      }
  
      if (isLockedOut) {
        setErrorMessage(
          `Too many consecutive failed attempts. Please try again in ${lockoutDuration} seconds.`
        );
        return;
      }
  
      setIsSigningIn(true);
      try {
        
        const userCredential = await doSignInWithEmailAndPassword(email, password);
        const user = userCredential.user;
  
        await user.reload();
        
        if (!user.emailVerified) {
          setErrorMessage("Please verify your email address before logging in.");
          setIsSigningIn(false);
          setEmailVerified(false);
          

          const verificationTimeout = setTimeout(async () => {
            const user = getAuth().currentUser;
            if (user && !user.emailVerified) {
              await deleteUser(user); 
              await set(ref(db, `/users/${user.uid}`), null); 
            }
          }, 120000); 
    
          return;
        }
        
        const userRef = ref(db, `/users/${user.uid}`);
        const snapshot = await get(userRef);
        const userData = snapshot.val();
        setIsAdmin(userData?.admin === "yes");
        setEmailVerified(true);
    
      } catch (error) {
        setIsSigningIn(false);
        setErrorMessage("Invalid email or password");
        const newConsecutiveFailures = consecutiveFailures + 1;
        setConsecutiveFailures(newConsecutiveFailures);
        if (newConsecutiveFailures >= 5) {
          setIsLockedOut(true);
          const currentTime = Math.floor(Date.now() / 1000);
          localStorage.setItem("lockoutStart", currentTime);
          setLockoutStart(currentTime);
          setLockoutDuration(lockoutDuration * 2);
          startCountdown();
        }
      }
    }
  };


 

  const handlePasswordReset = async () => {
    try {
      await doPasswordReset(email);
      setResetMessage("Password reset email sent. Please check your inbox.");
      setErrorMessage("");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setErrorMessage("No user found with this email address.");
      } else {
        setErrorMessage(
          "Error sending password reset email. Please try again later."
        );
      }
      setResetMessage("");
    }
  };

  return (
    <main
      className="w-full h-screen flex items-center justify-center"
      style={{
        backgroundImage: "url('assets/img/officeETM.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >

<header id="header" className="fixed-top navy-blue"style={{ height: '50px' }}>
  <div className="container d-flex fixed-top ">
    <img src="assets\img\ETOURMO LOGO.png"  className=" image" style={{ width: '100px', height: '50px' }} />
    <nav id="navbar" className="ml-15 navbar">
      <ul>
       
        <li><a className="nav-link scrollto active" href="home">Home</a></li>
        <li><a className="nav-link scrollto" href="home#about">About</a></li>
        <li><a className="nav-link scrollto" href="home#team">Team</a></li>
        <li><a className="nav-link scrollto" href="home#faq">FAQ</a></li>
        <li><a className="nav-link scrollto" href="booking">Booking</a></li>
        <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
      </ul>
      <i className="bi bi-list mobile-nav-toggle" />
    </nav>
  </div>
</header>
      <div>

      {emailVerified && (
        <Navigate to={isAdmin ? "/adminbookinglist" : "/home"} replace={true} />
      )}

        <main className="w-full h-auto flex self-center place-content-center place-items-center">
          <div
            className="mt-20 mb-10 w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
          >
            <div className="text-center">
              <img
                src="assets/img/ETOURMO LOGO.png"
                alt="Etourmo_logo"
                className="w-22 h-20 mr-2 mx-auto"
              />
              <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
                Welcome Back!
              </h3>
            </div>
            {!showForgotPassword && (
              <form onSubmit={handleSignIn} className="space-y-5">
                <div>
                  <label className="text-sm text-gray-600 font-bold">
                    Email
                  </label>
                  <input
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full mt-2 px-3 py-2 text-gray-500 outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                  />
                </div>
                <div>
                  <label className="text-sm text-gray-600 font-bold">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"} 
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full mt-2 px-3 py-2 text-gray-500 outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                  />
                  <button type="button" onClick={() => setShowPassword(!showPassword)} className="text-sm text-blue-600">
                    {showPassword ? "Hide" : "Show"} Password
                  </button> {/* Button to toggle password visibility */}
                </div>
                {isLockedOut && (
                  <p>
                    Too many consecutive failed attempts. Please try again in{" "}
                    {lockoutDuration} seconds.
                  </p>
                )}
                {errorMessage && (
                  <span className="text-red-600 font-bold">{errorMessage}</span>
                )}
                <button
                  type="submit"
                  disabled={isSigningIn}
                  className={`w-full px-4 py-2 text-white font-medium rounded-lg ${
                    isSigningIn
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
                  }`}
                >
                  {isSigningIn ? "Logging In..." : "Log In"}
                </button>
                <p
                  className="text-center text-sm cursor-pointer hover:underline font-bold"
                  onClick={() => setShowForgotPassword(true)}
                >
                  Forgot your password? Click here.
                </p>
                <p className="text-center text-sm">
                  Don't have an account?{" "}
                  <Link to={"/register"} className="hover:underline font-bold">
                    Sign up
                  </Link>
                </p>
              </form>
            )}
            {showForgotPassword && (
              <div>
                <label className="text-sm text-gray-600 font-bold">Email</label>
                <input
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full mt-2 px-3 py-2 text-gray-500 outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                />
                <button
                  onClick={handlePasswordReset}
                  className="mt-2 bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
                >
                  Reset Password
                </button>
                {resetMessage && (
                  <p className="text-green-600">{resetMessage}</p>
                )}
                {errorMessage && <p className="text-red-600">{errorMessage}</p>}
                <p
                  className="text-center text-sm cursor-pointer hover:underline font-bold color-blue"
                  onClick={() => setShowForgotPassword(false)}
                >
                  Go back
                </p>
              </div>
            )}
          </div>
        </main>
      </div>
    </main>
  );
};

export default Login;