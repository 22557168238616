import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import { Bar, Pie } from "react-chartjs-2";
import "../pages/Analytics.css";
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement, 
} from 'chart.js';
import Notification from '../pages/adminNotification';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement 
);


const Analytics = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [totalClients, setTotalClients] = useState(0);
  const [approvedBookings, setApprovedBookings] = useState(0);
  const [pendingBookings, setPendingBookings] = useState(0);
  const [completedBookings, setCompletedBookings] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [bookingsPerMonth, setBookingsPerMonth] = useState({});
  const [mostChosenPackagesData, setMostChosenPackagesData] = useState({
    
    labels: [],
    datasets: [{ data: [], backgroundColor: [], hoverBackgroundColor: [] }],
  });
  const [averagePax, setAveragePax] = useState(0);
  const [completedBookingsDetails, setCompletedBookingsDetails] = useState([]);
  const [dailyData, setDailyData] = useState({});
  const [weeklyData, setWeeklyData] = useState({});
  const [monthlyData, setMonthlyData] = useState({});
  const [yearlyData, setYearlyData] = useState({});
  const [currentView, setCurrentView] = useState("monthly");
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [cancelledBookings, setCancelledBookings] = useState(0);
  const [refundedBookings, setRefundedBookings] = useState(0);
  const [bookingsData, setBookingsData] = useState({});
  const [showDatesPopup, setShowDatesPopup] = useState(false);
  const [popupDates, setPopupDates] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedWeek, setSelectedWeek] = useState(1);

  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i
  );
  
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const weeks = Array.from({ length: 5 }, (_, i) => i + 1);

  const handleFilterChange = (type, value) => {
    switch(type) {
      case 'year':
        setSelectedYear(value);
        break;
      case 'month':
        setSelectedMonth(value);
        break;
      case 'week':
        setSelectedWeek(value);
        break;
      default:
        break;
    }
   
  };

  const toggleView = (view) => {
    setCurrentView(view);
  };

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin);
          } else {
            console.log("No user data available");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
      fetchData();
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      const bookingsRef = ref(db, "analytics");
      const bookingsSnapshot = await get(bookingsRef);
  
      if (bookingsSnapshot.exists()) {
        const bookingsData = bookingsSnapshot.val();
        setBookingsData(bookingsData);
        let approved = 0;
        let pending = 0;
        let completed = 0;
        let totalIncome = 0;
        let totalPax = 0;
        let totalClients = Object.keys(bookingsData).length;
        let bookingCount = 0;
  
        const monthlyBookings = {
          January: 0, February: 0, March: 0, April: 0, May: 0,
          June: 0, July: 0, August: 0, September: 0, October: 0,
          November: 0, December: 0,
        };
  
        const dailyBookings = {};
        const weeklyBookings = {}; 
        const yearlyBookings = {}; 
        const packageCount = {};
        const completedBookings = [];
        let cancelled = 0;
        let refunded = 0;
  

        const totalBookings = Object.keys(bookingsData).length;
  
        for (let bookingId in bookingsData) {
          const booking = bookingsData[bookingId];
          const bookingDate = new Date(booking.departureDate);

      
          const month = bookingDate.toLocaleString("default", { month: "long" });
          monthlyBookings[month]++;
  
  
          const dateKey = bookingDate.toLocaleDateString();
          dailyBookings[dateKey] = (dailyBookings[dateKey] || 0) + 1;
  
    
          const weekKey = `${month} - Week ${getWeekOfMonth(bookingDate)}`;
          weeklyBookings[weekKey] = (weeklyBookings[weekKey] || 0) + 1;
  
      
          const year = bookingDate.getFullYear();
          yearlyBookings[year] = (yearlyBookings[year] || 0) + 1;
  
      
          const packageName = booking.package;
          packageCount[packageName] = (packageCount[packageName] || 0) + 1;
  
          if (booking.pax) {
            totalPax += parseInt(booking.pax);
            bookingCount++;
          }
          
          if (booking.level === "pending") pending++;
          if (booking.level === "approved") approved++;
          if (booking.level === "completed") {
            totalIncome += parseInt(booking.totalPrice);
            completed++;
            completedBookings.push({
              id: bookingId,
              package: booking.package,
              rating: booking.rating,
            });
          }
          if (booking.level === "cancelled") cancelled++;
          if (booking.level === "refunded") refunded++;
        }
  
        const averagePax = bookingCount ? Math.round(totalPax / bookingCount) : 0;

        setTotalClients(totalClients);
        setApprovedBookings(approved);
        setPendingBookings(pending);
        setCompletedBookings(completed);
        setTotalIncome(totalIncome);
        setBookingsPerMonth(monthlyBookings);
        setAveragePax(averagePax);
        setCompletedBookingsDetails(completedBookings);
        setDailyData(dailyBookings);
        setWeeklyData(weeklyBookings); 
        setMonthlyData(monthlyBookings);
        setYearlyData(yearlyBookings); 
        setCancelledBookings(cancelled);
        setRefundedBookings(refunded);
  
      
        
        const sortedPackages = Object.entries(packageCount)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 5);
  
        setMostChosenPackagesData({
          labels: sortedPackages.map((item) => item[0]),
          datasets: [
            {
              label: "Most Chosen Packages",
              data: sortedPackages.map((item) => (item[1] / totalBookings) * 100), 
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#8CFE4C", "#9966FF"],
              hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#8CFE4C", "#9966FF"],
            },
          ],
        });
      } else {
        console.log("No bookings data available");
      }
    } catch (error) {
      console.error("Error fetching bookings data:", error.message);
    }
  };
  

  const getWeekOfMonth = (date) => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfMonth = date.getDate();
    const adjustedDate = dayOfMonth + startOfMonth.getDay(); 
    return Math.ceil(adjustedDate / 7); 
  };
  


  
const getWeekOfYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 1); 
  const days = Math.floor((date - start) / (24 * 60 * 60 * 1000)); 
  const weekNumber = Math.ceil((days + start.getDay() + 1) / 7); 
  
  
  switch (weekNumber) {
    case 1:
      return "First Week";
    case 2:
      return "Second Week";
    case 3:
      return "Third Week";
    case 4:
      return "Fourth Week";
    case 5:
      return "Fifth Week";
    default:
      return `${weekNumber}th Week`; 
  }
};

  const getDataForCurrentView = () => {
    switch (currentView) {
      case "daily":
        return dailyData;
      case "weekly":
        return weeklyData;
      case "monthly":
        return bookingsPerMonth; 
      case "yearly":
        return yearlyData; 
      default:
        return {};
    }
  };

  const dataForChart = getDataForCurrentView();

  const calculateAverageRating = () => {
    if (completedBookingsDetails.length === 0) return 0;
  
    
    const validRatings = completedBookingsDetails
      .map((booking) => {
        console.log(`Booking ID: ${booking.id}, Rating: ${booking.rating}`); 
        return parseFloat(booking.rating);
      })
      .filter((rating) => !isNaN(rating)); 
  
    if (validRatings.length === 0) return 0; 
  
    const totalRating = validRatings.reduce((acc, rating) => acc + rating, 0);
    return (totalRating / validRatings.length).toFixed(1);
  };

  const handleSignOut = () => {
    doSignOut();
 
    window.location.href = "/home";
  };

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };



  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleExport = async (type) => {
    try {
      let dataToExport = {};
      let title;
      let filteredBookings = [];

      
      filteredBookings = Object.entries(bookingsData || {}).filter(([_, booking]) => {
        const bookingDate = new Date(booking.time);
        

        console.log(`Booking ID: ${booking.id}, Parsed Date: ${bookingDate}`);


        if (isNaN(bookingDate.getTime())) {
          console.error(`Invalid date format for booking ID: ${booking.id}`);
          return false; 
        }

        switch(type) {
          case 'year':
            console.log(`Checking year: ${bookingDate.getFullYear()} against selected year: ${selectedYear}`);
            return bookingDate.getFullYear() === selectedYear;
          
          case 'month':
            console.log(`Checking month: ${bookingDate.getMonth()} against selected month: ${selectedMonth}`);
            return bookingDate.getFullYear() === selectedYear && 
                   bookingDate.getMonth() === selectedMonth;
          
          case 'week':
            console.log(`Checking week: ${getWeekOfMonth(bookingDate)} against selected week: ${selectedWeek}`);
            return bookingDate.getFullYear() === selectedYear && 
                   bookingDate.getMonth() === selectedMonth && 
                   getWeekOfMonth(bookingDate) === selectedWeek;
          
          default:
            return false;
        }
      });

      console.log(`Filtered Bookings Count: ${filteredBookings.length}`);

      if (filteredBookings.length === 0) {
        alert(`No bookings found for the selected ${type}`);
        return;
      }


      const stats = filteredBookings.reduce((acc, [_, booking]) => {
        if (booking.level === "completed") {
            acc.totalIncome += parseFloat(booking.totalPrice) || 0;
        }
        acc.totalPax += parseInt(booking.pax) || 0;
        acc.bookingsByStatus[booking.level] = (acc.bookingsByStatus[booking.level] || 0) + 1;
        return acc;
      }, {
        totalIncome: 0,
        totalPax: 0,
        bookingsByStatus: {}
      });

  
      console.log("Calculated Stats:", stats);


      switch(type) {
        case 'year':
          title = `Yearly Bookings Report ${selectedYear}`;
         
          dataToExport = {
            [`Year ${selectedYear}`]: filteredBookings
              .filter(([_, booking]) => {
                const bookingDate = new Date(booking.time);
                return bookingDate.getFullYear() === selectedYear;
              }).length
          };

          if (dataToExport[`Year ${selectedYear}`] === 0) {
            alert(`No bookings found for year ${selectedYear}`);
            return;
          }
          break;

        case 'month':
          title = `Monthly Bookings Report - ${months[selectedMonth]} ${selectedYear}`;

          dataToExport = {
            [`${months[selectedMonth]} ${selectedYear}`]: filteredBookings
              .filter(([_, booking]) => {
                const bookingDate = new Date(booking.time);
                return bookingDate.getFullYear() === selectedYear && 
                       bookingDate.getMonth() === selectedMonth;
              }).length
          };

          if (dataToExport[`${months[selectedMonth]} ${selectedYear}`] === 0) {
            alert(`No bookings found for ${months[selectedMonth]} ${selectedYear}`);
            return;
          }
          break;

        case 'week':
          title = `Weekly Bookings Report - Week ${selectedWeek}, ${months[selectedMonth]} ${selectedYear}`;
    
          dataToExport = {
            [`Week ${selectedWeek} of ${months[selectedMonth]} ${selectedYear}`]: filteredBookings
              .filter(([_, booking]) => {
                const bookingDate = new Date(booking.time);
                return bookingDate.getFullYear() === selectedYear && 
                       bookingDate.getMonth() === selectedMonth && 
                       getWeekOfMonth(bookingDate) === selectedWeek;
              }).length
          };

          if (dataToExport[`Week ${selectedWeek} of ${months[selectedMonth]} ${selectedYear}`] === 0) {
            alert(`No bookings found for Week ${selectedWeek} of ${months[selectedMonth]} ${selectedYear}`);
            return;
          }
          break;
      }

      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.text(title, 14, 15);
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleString()}`, 14, 25);
      const periodStats = {
        totalIncome: 0,
        totalPax: 0,
        bookingsByStatus: {}
      };
      const periodBookings = filteredBookings.map(([_, booking]) => booking);
      periodBookings.forEach(booking => {
        periodStats.totalIncome += parseFloat(booking.totalPrice) || 0;
        periodStats.totalPax += parseInt(booking.pax) || 0;
        periodStats.bookingsByStatus[booking.level] = (periodStats.bookingsByStatus[booking.level] || 0) + 1;
      });
      console.log(`Period Stats:`, periodStats);
      const summaryData = [
        ['Total Bookings', filteredBookings.length],
        ['Total Income', `${stats.totalIncome.toLocaleString()} PHP`],
        ['Average Pax', stats.totalPax > 0 ? Math.ceil(stats.totalPax / filteredBookings.length) + ' Pax' : 0],
        ['Average Rating', calculateAverageRating() > 0 ? calculateAverageRating() : 'N/A']
      ];
      doc.autoTable({
        startY: 35,
        head: [['Period', 'Number of Bookings']],
        body: [
          [
            type === 'year' ? `Year ${selectedYear}` :
            type === 'month' ? `${months[selectedMonth]} ${selectedYear}` :
            `Week ${selectedWeek} of ${months[selectedMonth]} ${selectedYear}`,
            periodBookings.length.toString()
          ]
        ],
        theme: 'grid',
        headStyles: {
          fillColor: [30, 144, 255],
          textColor: 255,
          fontStyle: 'bold'
        },
        styles: {
          fontSize: 10,
          cellPadding: 5,
          valign: 'middle'
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245]
        }
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        head: [['Metric', 'Value']],
        body: summaryData,
        theme: 'grid',
        headStyles: {
          fillColor: [30, 144, 255],
          textColor: 255,
          fontStyle: 'bold'
        },
        styles: {
          fontSize: 10,
          cellPadding: 5,
          valign: 'middle'
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245]
        }
      });
      const statusData = Object.entries(periodStats.bookingsByStatus).map(([status, count]) => [
        status.charAt(0).toUpperCase() + status.slice(1),
        count
      ]);
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        head: [['Booking Status', 'Count']],
        body: statusData,
        theme: 'grid',
        headStyles: {
          fillColor: [30, 144, 255],
          textColor: 255,
          fontStyle: 'bold'
        },
        styles: {
          fontSize: 10,
          cellPadding: 5,
          valign: 'middle'
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245]
        }
      });
      doc.setFontSize(8);
      doc.text('Generated by EtourMo Analytics', 14, doc.internal.pageSize.height - 10);
      const fileName = `EtourMo_${type}_report_${selectedYear}${type === 'month' ? '_' + months[selectedMonth] : ''}${type === 'week' ? '_week' + selectedWeek : ''}.pdf`;
      doc.save(fileName);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating report. Please try again.');
    }
  };

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const percentage = context.raw.toFixed(1);
            return `${label}: ${percentage}%`;
          }
        }
      },
      legend: {
        position: 'bottom'
      },
      datalabels: {
        display: true,
        color: '#fff',
        font: (context) => {
          const percentage = context.dataset.data[context.dataIndex];
          if (percentage >= 30) return { weight: 'bold', size: 20 };
          if (percentage >= 15) return { weight: 'bold', size: 16 };
          if (percentage >= 5) return { weight: 'bold', size: 12 };
          return { weight: 'bold', size: 10 };
        },
        formatter: (value, ctx) => {
          return `${value.toFixed(1)}%`;
        },
        anchor: 'center',
        align: 'center',
        offset: 0
      }
    }
  };

 

  return (
    <div>
    <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
      <div className="container d-flex fixed-top">
        <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
        <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
          <li><a className="nav-link scrollto" href="adminbookinglist">Bookings</a></li>

              <li><a className="nav-link scrollto" href="manageaccounts">Manage Accounts</a></li>
              <li><a className=" nav-link scrollto active" href="analytics">Dashboard</a></li>
              <li><a className=" nav-link scrollto" href="managepackages">Manage Packages</a></li>
              <li><a className=" nav-link scrollto" href="walkInBooking">Walk In</a></li>
              <li className="nav-link scrollto">
                <Notification />
              </li>
          
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(prev => !prev);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                  
                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                     
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
        </div>
      </header>
{/* End Header */}


  <main className="analytics">
    <h1>Analytics Dashboard</h1>
    <div className="analytics-filters-container">
      <div className="date-filters">
        <select 
          value={selectedYear}
          onChange={(e) => handleFilterChange('year', parseInt(e.target.value))}
        >
          {years.map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>

        <select 
          value={selectedMonth}
          onChange={(e) => handleFilterChange('month', parseInt(e.target.value))}
        >
          {months.map((month, index) => (
            <option key={month} value={index}>{month}</option>
          ))}
        </select>

        <select 
          value={selectedWeek}
          onChange={(e) => handleFilterChange('week', parseInt(e.target.value))}
        >
          {weeks.map(week => (
            <option key={week} value={week}>Week {week}</option>
          ))}
        </select>
      </div>

      <div className="export-buttons">
        <button 
          className="export-button"
          onClick={() => handleExport('year')}
        >
          Export Year Data
        </button>
        <button 
          className="export-button"
          onClick={() => handleExport('month')}
        >
          Export Month Data
        </button>
        <button 
          className="export-button"
          onClick={() => handleExport('week')}
        >
          Export Week Data
        </button>
      </div>
    </div>
    <div className="view-toggle">
      <button onClick={() => toggleView("daily")}>Daily</button>
      <button onClick={() => toggleView("weekly")}>Weekly</button>
      <button onClick={() => toggleView("monthly")}>Monthly</button>
      <button onClick={() => toggleView("yearly")}>Yearly</button>
    </div>

    <div className="charts">
      <div className="chart">
        <h2>Number of Bookings</h2>
        <Bar
          data={{
            labels: Object.keys(dataForChart),
            datasets: [
              {
                label: "Bookings",
                data: Object.values(dataForChart),
                backgroundColor:[
                  "rgba(0, 51, 102, 0.6)",
                  "rgba(0, 102, 204, 0.6)", 
                  "rgba(0, 153, 255, 0.6)", 
                  "rgba(51, 153, 255, 0.6)", 
                  "rgba(0, 204, 255, 0.6)", 
                ],
              },
            ],
          }}
          options={{ responsive: true }}
        />
      </div>
      <div className="booking-status">
            <h3>Booking Status</h3>
            <div className="status-container">


            <button 
                className="status-box pending" 
                style={{color: 'black', ':hover': {backgroundColor: 'blue'}}}
                onClick={() => {
                  const datesAndPackages = Object.values(bookingsData || {})
                    .filter(booking => booking.level === "pending")
                    .map(booking => ({
                      date: new Date(booking.departureDate).toLocaleDateString(),
                      package: booking.package
                    }));
                  setPopupDates(datesAndPackages);
                  setPopupTitle("Pending Booking Dates");
                  setShowDatesPopup(true);
                }}
              >
                <p>Pending: {pendingBookings}</p>
              </button>
              
              <button 
                className="status-box approved" 
                style={{color: 'black', ':hover': {backgroundColor: 'blue'}}}
                onClick={() => {
                  const datesAndPackages = Object.values(bookingsData || {})
                    .filter(booking => booking.level === "approved")
                    .map(booking => ({
                      date: new Date(booking.departureDate).toLocaleDateString(),
                      package: booking.package
                    }));
                  setPopupDates(datesAndPackages);
                  setPopupTitle("Approved Booking Dates");
                  setShowDatesPopup(true);
                }}
              >
                <p>Approved: {approvedBookings}</p>
              </button>
             
              <button 
                className="status-box completed" 
                style={{color: 'black', ':hover': {backgroundColor: 'blue'}}}
                onClick={() => {
                  const datesAndPackages = Object.values(bookingsData || {})
                    .filter(booking => booking.level === "completed")
                    .map(booking => ({
                      date: new Date(booking.departureDate).toLocaleDateString(),
                      package: booking.package
                    }));
                  setPopupDates(datesAndPackages);
                  setPopupTitle("Completed Booking Dates");
                  setShowDatesPopup(true);
                }}
              >
                <p>Completed: {completedBookings}</p>
              </button>
              <button 
                className="status-box cancelled" 
                style={{color: 'black', ':hover': {backgroundColor: 'blue'}}}
                onClick={() => {
                  const datesAndPackages = Object.values(bookingsData || {})
                    .filter(booking => booking.level === "cancelled")
                    .map(booking => ({
                      date: new Date(booking.departureDate).toLocaleDateString(),
                      package: booking.package
                    }));
                  setPopupDates(datesAndPackages);
                  setPopupTitle("Cancelled Booking Dates");
                  setShowDatesPopup(true);
                }}
              >
                <p>Cancelled: {cancelledBookings}</p>
              </button>
              <button 
                className="status-box refunded" 
                style={{color: 'black', ':hover': {backgroundColor: 'blue'}}}
                onClick={() => {
                  const datesAndPackages = Object.values(bookingsData || {})
                    .filter(booking => booking.level === "refunded")
                    .map(booking => ({
                      date: new Date(booking.departureDate).toLocaleDateString(),
                      package: booking.package
                    }));
                  setPopupDates(datesAndPackages);
                  setPopupTitle("Refunded Booking Dates");
                  setShowDatesPopup(true);
                }}
              >
                <p>Refunded: {refundedBookings}</p>
              </button>
            </div>
            {showDatesPopup && (
              <div className="dates-popup">
                <h4>{popupTitle}</h4>
                <ul>
                  {popupDates.map((item, index) => (
                    <li key={index}>
                      Date: {item.date}<br/>
                      Package: {item.package}
                    </li>
                  ))}
                </ul>
                <button onClick={() => setShowDatesPopup(false)}>Close</button>
              </div>
            )}
          </div>
      {/* Flex Container for Pie Chart and Summary */}
      <div className="summary-container">
        <div className="chart pie-chart">
          <h2>Most Chosen Packages</h2>
          <Pie 
            data={mostChosenPackagesData} 
            options={pieOptions}
            plugins={[ChartDataLabels]}
          />
          
        </div>

        <div className="summary">
          <h3>Summary</h3>
          <p>Total Clients: {totalClients}</p>
          <p>Total Income: ₱{totalIncome}</p>
          <p>Average Pax: {averagePax}</p>
          <p>Average Rating: {calculateAverageRating()}</p>
        </div>
      </div>
    </div>
  </main>
</div>

  );
};

export default Analytics;
