import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import AdminDashboard from "../pages/adminDashboard"; // Import AdminDashboard component
import { useLocation } from 'react-router-dom'; // Import useLocation

const DashboardCheck = () => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation(); // Get the location object
  const activeTab = location.state?.activeTab || "bookings"; // Get activeTab from state or default to "bookings"

  useEffect(() => {
    if (currentUser) {
      // Fetch user data from the database
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setIsAdmin(userData.admin === "yes"); // Check if user is admin
          } else {
            console.log("Not a superadmin");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    }
  }, [currentUser]);

 

  return (
    <div>
      {/* Header */}
      {/* Header content */}
      {/* End Header */}

      {/* Render AdminDashboard only if user is admin */}
      {isAdmin && <AdminDashboard activeTab={activeTab} />} {/* Pass activeTab as a prop */}
    </div>
  );
};

export default DashboardCheck;
