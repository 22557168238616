import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, set, get, remove, onValue, update, push } from "firebase/database";
import "../pages/adminDashboard.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaStar } from 'react-icons/fa'; 
import emailjs from '@emailjs/browser';
import { useLocation } from 'react-router-dom';
import UserNotifications from "./UserNotif";



const UserBookings = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [approvedBookings, setApprovedBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("bookings");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [paymentType, setPaymentType] = useState(""); 
  const [pack, setPack] = useState(""); 
  const [showMinText, setShowMinText] = useState(false); 
  const [fiftyPercentAmount, setFiftyPercentAmount] = useState(0); 
  const [checkOutAmount, setCheckoutAmount] = useState(""); 
  const [RefID, setRefID] = useState('');
  const [errorMessage, setErrorMessage] = useState("");


  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [isApplyCodeModalOpen, setIsApplyCodeModalOpen] = useState(false);
  const [invoiceCode, setInvoiceCode] = useState(''); 

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
const [agreedToTerms, setAgreedToTerms] = useState(false);

const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
const [dropdownVisible, setDropdownVisible] = useState(false);

const [alertMessage, setAlertMessage] = useState(""); 

const [cancelledBookings, setCancelledBookings] = useState([]); 

const location = useLocation();

const [showWarning, setShowWarning] = useState(false);
const [requestedDates, setRequestedDates] = useState([]);




const sessionTimeout = 300000; 
let timeout;
let warningTimeout;

const resetTimeout = () => {
  if (currentUser) {
    if (timeout) clearTimeout(timeout);
    if (warningTimeout) clearTimeout(warningTimeout);
    
    warningTimeout = setTimeout(() => {
      setShowWarning(true);
    }, sessionTimeout - 15000);
    
    timeout = setTimeout(handleSignOut1, sessionTimeout);
  }
};

const handleSignOut1 = () => {
  doSignOut();
  window.location.href = "/home";
  alert("Session expired due to inactivity.");
};

const handleContinueSession = () => {
  setShowWarning(false);
  resetTimeout();
};


useEffect(() => {
  resetTimeout();

  const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
  events.forEach(event => window.addEventListener(event, resetTimeout));

  return () => {
    clearTimeout(timeout);
    clearTimeout(warningTimeout);
    events.forEach(event => window.removeEventListener(event, resetTimeout));
  };
}, []);






  useEffect(() => {

    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location]);

  useEffect(() => {

    
    if (currentUser) {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin);

        
            if (!userData.idImage) {
              const today = new Date();
              const sevenDaysFromNow = new Date(today);
              sevenDaysFromNow.setDate(today.getDate() + 7);

              const bookingsRef = ref(db, `UserBookings/${currentUser.uid}`);
              onValue(bookingsRef, (snapshot) => {
                if (snapshot.exists()) {
                  const bookingsData = snapshot.val();
                  Object.entries(bookingsData).forEach(([key, booking]) => {
                    const bookingDate = new Date(booking.departureDate);
                    if (booking.status === "Pending" && bookingDate <= sevenDaysFromNow) {

                     
                      const bookingRef = ref(db, `UserBookings/${currentUser.uid}/${key}`);
                      const bookingRef1 = ref(db, `bookings/${key}`);
                      remove(bookingRef)
                      remove(bookingRef1)
                        .then(() => {
                          console.log(`Booking ${key} deleted due to missing idImage.`);
                        })
                        .catch((error) => {
                          console.error("Error deleting booking:", error.message);
                        });
                    }
                  });
                }
              });
            }
          } else {
            console.log("No user data available");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });

      const bookingsRef = ref(db, `UserBookings/${currentUser.uid}`);
      onValue(bookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const bookingsData = snapshot.val();
          const bookingsArray = Object.keys(bookingsData).map((key) => ({
            id: key,
            ...bookingsData[key],
          }));
          setBookings(bookingsArray);
        } else {
          console.log("No bookings available");
        }
      });

      const approvedBookingsRef = ref(db, `UserApprovedBookings/${currentUser.uid}`);
      onValue(approvedBookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const approvedBookingsData = snapshot.val();
          const approvedBookingsArray = Object.keys(approvedBookingsData).map((key) => ({
            id: key,
            ...approvedBookingsData[key],
          }));
          setApprovedBookings(approvedBookingsArray);
        } else {
          console.log("No approved bookings available");
        }
      });

      

      const completedBookingsRef = ref(db, `UserCompletedBookings/${currentUser.uid}`);
      onValue(completedBookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const completedBookingsData = snapshot.val();
          const completedBookingsArray = Object.keys(completedBookingsData).map((key) => ({
            id: key,
            ...completedBookingsData[key],
          }));
          setCompletedBookings(completedBookingsArray);
        } else {
          console.log("No completed bookings available");
        }
      });

      const cancelledBookingsRef = ref(db, `UserCancelledBookings/${currentUser.uid}`); 
      onValue(cancelledBookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const cancelledBookingsData = snapshot.val();
          const cancelledBookingsArray = Object.keys(cancelledBookingsData).map((key) => ({
            id: key,
            ...cancelledBookingsData[key],
          }));
          setCancelledBookings(cancelledBookingsArray); 
        } else {
          console.log("No cancelled bookings available");
        }
      });

 
      const analyticsRef = ref(db, `analytics`);
      onValue(analyticsRef, (snapshot) => {
        if (snapshot.exists()) {
          const analyticsData = snapshot.val();
          Object.entries(analyticsData).forEach(([id, booking]) => {

            if (booking.userId === currentUser.uid) {
         
              if (!booking.statusViewed) {
                let message = '';
                switch(booking.level) {
                  case "pending":
                    message = `New booking for ${booking.package}`;
                    break;
                  case "approved":
                    message = `Your booking for ${booking.package} has been approved`;
                    break;
                  case "completed":
                    message = `Your booking for ${booking.package} has been completed`;
                    break;
                  case "cancelled":
                    message = `Your booking for ${booking.package} has been cancelled`;
                    break;
                  case "refunded":
                    message = `Your booking for ${booking.package} has been refunded`;
                    break;
                  default:
                    return;
                }


                if ("Notification" in window) {
                  if (Notification.permission === "granted") {
                    new Notification("Booking Update", {
                      body: message,
                      icon: "/favicon.ico"
                    });
                  } else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function (permission) {
                      if (permission === "granted") {
                        new Notification("Booking Update", {
                          body: message,
                          icon: "/favicon.ico"
                        });
                      }
                    });
                  }
                }
              }
            }
          });
        }
      });

    



    }
  }, [currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownVisible && !event.target.closest('.dropdown')) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleSignOut = () => {
    doSignOut();
    window.location.href = "/home";
  };


  useEffect(() => {

    resetTimeout();

 
    const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
    events.forEach(event => window.addEventListener(event, resetTimeout));

    return () => {
      clearTimeout(timeout);
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, []);

 const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };


  const handleTermsOpen = () => {
    setTermsModalOpen(true);
  };


  const handleTermsClose = () => {
    setTermsModalOpen(false);
  };

  const termsContent = (
    <div className="modal">
      <div
        className="modal-content1"
        style={{ backgroundColor: 'lightgray', width: '800px', height: 'auto', padding: '20px', maxHeight: '80vh', overflowY: 'auto' }}
      >
        <span className="close" onClick={handleTermsClose}>&times;</span>
        <h2>Payment Terms</h2>
        <p>Each payment method has a maximum amount that could be processed per transaction.</p>
        <p>The upper limits are as follows:</p>
        <ul>
          <li>Cards: less than ₱ 10,000,000.00</li>
          <li>BillEase: ₱ 150,000.00</li>
          <li>E-wallets(Gcash and Maya): up to ₱ 100,000.00</li>
          <li>Online Banking via UBP: up to ₱ 100,000.00</li>
          <li>Online Banking via BPI: up to ₱ 50,000.00</li>
          <li>Online Banking via BDO through Brankas: up to ₱ 50,000.00</li>
          <li>Online Banking via Metrobank through Brankas: up to ₱ 50,000.00</li>
          <li>Online Banking via LandBank through Brankas: up to ₱ 50,000.00</li>
          <li>QR Ph: dependent on the outward limits set by the customer's bank for QR Ph payments</li>
        </ul>
        
        <h3>REMINDER!</h3>
        <h4>Down Payment Policy</h4>
        <ul>
          <li>50% downpayment 1 month before the trip.</li>
          <li>50% payment of the remaining balance on the day of the tour.</li>
     
        </ul>
        
        <h4>Cancellation Policy</h4>
        <p>
          - 10% surcharge if cancelled one (1) week before the trip.<br />
          - 25% surcharge if cancelled five (5) days before the trip.<br />
          - No refund after the fifth (5th) day.<br />
          - Rescheduling may be valid under force majeure circumstances.<br />
          - All cancellation requests must be submitted in writing via email or live chat.<br />
          - Refunds are given in cash at the Etourmo Travel and Tours office, 214 Abacan Brgy Calvario, Meycauayan, Philippines, 3020.
        </p>
        
        <label>
          <input
            type="checkbox"
            checked={agreedToTerms}
            onChange={(e) => setAgreedToTerms(e.target.checked)}
          />
          I agree to the payment terms
        </label>
        <br />
        <button onClick={handleTermsClose}>Close</button>
      </div>
    </div>
  );
  

  const sendEmail = (userEmail, subject, message) => {
    const templateParams = {
      user_email: userEmail,
      subject: subject,
      message: message,
    };
  
    emailjs
    .send('service_5fwafhc', 'template_17y2dr9', templateParams, 'ymggzz4cqmsAkMw6i')
    .then(
      () => {
        console.log('Email sent successfully!');
      },
      (error) => {
        console.log('Failed to send email:', error.text);
      },
    );
};


    
  
  


  

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortBookings = (bookingsArray) => {
    return [...bookingsArray].sort((a, b) => {
      if (sortBy) {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (sortBy === "pax" || sortBy === "downpayment" || sortBy === "totalPrice") {
       
          return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        } else if (sortBy === "departureDate") {
        
          return sortOrder === "asc"
            ? new Date(aValue) - new Date(bValue)
            : new Date(bValue) - new Date(aValue);
        } else {
  
          const compareResult = aValue.toString().localeCompare(bValue.toString());
          return sortOrder === "asc" ? compareResult : -compareResult; 
        }
      }
      return 0; 
    });
  };

  const sortedBookings = sortBookings(bookings);
  const sortedApprovedBookings = sortBookings(approvedBookings);
  const sortedCompletedBookings = sortBookings(completedBookings);


  const getSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? (
        <i className="bi bi-arrow-up"></i>
      ) : (
        <i className="bi bi-arrow-down"></i>
      );
    }
    return null;
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Unpaid":
        return "status-red";
      case "Paid":
        return "status-green";
      case "Cancelled":
        return "status-gray";
      default:
        return "";
    }
  };

  const renderBookingsTable = (bookings, actionButton, showRating = false) => (
    <div className="booking-table">
      <table>
        <thead>
          
          <tr>
            <th onClick={() => handleSort("id")}>
              Booking #{getSortIcon("id")}
            </th>
            <th onClick={() => handleSort("userName")}>
              Name {getSortIcon("userName")}
            </th>
            <th onClick={() => handleSort("userEmail")}>
              Email {getSortIcon("userEmail")}
            </th>
            <th onClick={() => handleSort("schoolName")}>
              School {getSortIcon("schoolName")}
            </th>
            <th onClick={() => handleSort("address")}>
              Address {getSortIcon("address")}
            </th>
            <th onClick={() => handleSort("zipCode")}>
              Zip Code {getSortIcon("zipCode")}
            </th>
            <th onClick={() => handleSort("departureDate")}>
              Departure Date {getSortIcon("departureDate")}
            </th>
            <th onClick={() => handleSort("package")}>
              Package {getSortIcon("package")}
            </th>
            <th onClick={() => handleSort("pax")}>
              Pax {getSortIcon("pax")}
            </th>
            
            <th onClick={() => handleSort("downpayment")}>
              Down Payment {getSortIcon("downpayment")}
            </th>
            <th onClick={() => handleSort("totalPrice")}>
              Total Price {getSortIcon("totalPrice")}
            </th>
            <th onClick={() => handleSort("status")}>
              Status {getSortIcon("status")}
            </th>
            
        
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => {
            const requestedDate = requestedDates.find(req => req.id === booking.id)?.requestedDate || "N/A";
            const statusDisplay = booking.status === "Request Change Date" 
              ? `${booking.status} ( ${requestedDate})` 
              : booking.status;

            return (
              <tr key={booking.id}>
                <td>{booking.id}</td>
                <td>{booking.userName}</td>
                <td>{booking.userEmail}</td>
                <td>{booking.schoolName}</td>
                <td>{booking.address}</td>
                <td>{booking.zipCode}</td>
                <td>{booking.departureDate}</td>
                <td>{booking.package}</td>
                <td>{booking.pax}</td>
                
                <td>{booking.downPayment}</td>
                <td>{booking.totalPrice}</td>
                <td className={getStatusStyle(booking.status)}>{statusDisplay}</td>
                <td>
                {actionButton(booking)}
                 
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );


  
  const handleApplyCodeClick = (booking) => {
    setSelectedBooking(booking);
    setIsApplyCodeModalOpen(true);
    

    console.log(booking.downPayment);
          console.log(booking.totalPrice);
          
    console.log(isApplyCodeModalOpen)
  };

  
  





 
  




  const handleReference = async (wat) => {
  
  

    if (activeTab === "approved") {
      await update(ref(db, `UserApprovedBookings/${currentUser.uid}/${selectedBooking.id}`), {
        refID: wat
      });

      await update(ref(db, `approvedBookings/${selectedBooking.id}`), {
        refID: wat
      });
    }
  };


  const handleChangeDPPrice = (booking) => {
    setSelectedBooking(booking);
    
    setCheckoutAmount(fiftyPercentAmount)
  }

  const handleChangeFPrice = (booking) => {
    setSelectedBooking(booking);

    const totalPrice = parseInt(booking.totalPrice);
    const finalPrice = parseInt(totalPrice);
    setCheckoutAmount(finalPrice)
  }


  const handlePaymentTypeChange = (e) => {
    const selectedValue = e.target.value;
    setPaymentType(selectedValue);
    if (selectedValue === "Down Payment") {
      setShowMinText(true);
      handleChangeDPPrice(selectedBooking);
    } else {
      setShowMinText(false);
      handleChangeFPrice(selectedBooking);
    }
  };


  const handlePayClick = (booking) => {
    if (!booking) {
        setAlertMessage("No booking selected."); 
        return;
    }
    setSelectedBooking(booking);
    setIsModalOpen(true);
    setPack(booking.id); 
    const totalPrice = parseInt(booking.totalPrice);
    const fiftyPercentAmmount = totalPrice * 0.5;
    setFiftyPercentAmount(fiftyPercentAmmount);
  };

  const handleModalClose = () => {
    setPaymentType(null);
    setShowMinText(false);
    setIsModalOpen(false);
    setSelectedBooking(null);
    setCheckoutAmount(0);
    setAlertMessage(""); 
    setErrorMessage("");
  };



  const handlePayment = (booking) => {
    if (!booking) {
        setAlertMessage("No booking selected."); 
        return;
    }
    setSelectedBooking(booking);
    console.log("Payment processed for booking:", booking);

    if (!window.confirm("Are you sure you want to proceed with the payment? ")) {
        handleModalClose();
        return;
    }

  


    handleCheckOut(checkOutAmount)
        
    handleModalClose();
  };



  const createAdminNotification = async (message, bookingId,status) => {
    try {
      const notificationRef = ref(db, `adminNotifications/`);
      const newNotificationRef = push(notificationRef);
      
      const timestamp = Date.now();
      
      await update(newNotificationRef, {
        message,
        bookingId,
        time: timestamp,
        timestamp: timestamp,
        read: false,
        statusViewed: false,
        bgColor: 'bg-blue-100',
        createdAt: new Date(timestamp).toLocaleString(),
        status: status
      });
      
      console.log("Notification created successfully");
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  };

  


  const markDownPayment = async (bookingId) => {
    try {

    

        await update(ref(db, `UserApprovedBookings/${currentUser.uid}/${bookingId}`), {
            status: "Settled Downpayment", 
        });

        await update(ref(db, `approvedBookings/${bookingId}`), {
            status: "Settled Downpayment", 
        });



        const AdminNotificationMessage = `Booking #${bookingId} has Settled Downpayment.`;
        await createAdminNotification(AdminNotificationMessage, bookingId,'settledDownpayment');


    
        const notificationMessage = `Your have settled your downpayment for booking #${bookingId}.`;
       await createNotification(currentUser.uid, notificationMessage, bookingId, "settledDownpayment");

        

       
    } catch (error) {
        console.error("Error marking down payment or creating notification:", error);
    }
};

  

  const handleCheckOut  = async (checkOutAmount) => {
    
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Basic c2tfdGVzdF9INDd4VDlRUHNxN1A0WEthY1lGOHNiaVM6'
      },
      body: JSON.stringify({
        data: { attributes: { amount: checkOutAmount*100, description: paymentType, remarks: pack } }
      })
    };
  
    return fetch('https://api.paymongo.com/v1/links', options)
      .then(response => {
        console.log('Raw response:', response);
        if (!response.ok) {
          throw new Error(`Network response was not ok, status: ${response.status}`);
        }
        return response.json();
      })
      .then(response => {
        console.log('API Response:', response);
  
  
        if (!response.data || !response.data.attributes || !response.data.attributes.checkout_url) {
          throw new Error('Invalid response structure');
        }
  
  
        
  
        const checkoutUrl = response.data.attributes.checkout_url;
        console.log('Checkout URL:', checkoutUrl);
  
  
        const tempLink = document.createElement('a');
        tempLink.href = checkoutUrl;
        tempLink.target = '_blank';
        tempLink.rel = 'noopener noreferrer';
        document.body.appendChild(tempLink);
        tempLink.click();
  
      const refNum = response.data.attributes.reference_number;
  
        
        handleReference(response.data.attributes.reference_number);
        
  
        document.body.removeChild(tempLink);
      })
      .catch(err => console.error('Fetch error:', err));
  
      
    }
  
  




  const handleInputChange = (event) => {
    setRefID(event.target.value);
  };




  const handleButtonClick = async () => {
    try {
        console.log("Reference Code: ", RefID);

        await handleCheckRefID(RefID);
        console.log("Invoice Code Submitted:", invoiceCode);
        
      

   
       

        setRefID("");
    } catch (error) {
        console.error("Error handling payment:", error);
        alert("There was an error processing your payment. Please try again.");
        setRefID("");
    }
};


const handleCheckRefID = async (refid) => {
  if (!refid || refid.trim() === "") {
    setAlertMessage("Invoice Code cannot be empty. Please enter a valid Invoice Code."); 
    console.log("Invoice Code is empty or invalid.");
    return;
  }

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      authorization: 'Basic c2tfdGVzdF9INDd4VDlRUHNxN1A0WEthY1lGOHNiaVM6'
    }
  };

  try {
    const response = await fetch(`https://api.paymongo.com/v1/links/${refid}`, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const amount = data.data.attributes.amount;
    const status = data.data.attributes.status;
    const remarks = data.data.attributes.remarks;
    const NewAmount = amount / 100;

    

    const foundABooking = approvedBookings.find(booking => booking.id === remarks);

    if (foundABooking) {
      const AddAmount = NewAmount + foundABooking.downPayment;

      

      if (status === 'paid') {
        console.log('Payment status is paid for booking:', foundABooking);

        if (refid === foundABooking.refID) {
          console.log('Invoice Code matches, processing payment.');
          handleRefDown1(AddAmount, foundABooking);
          setAlertMessage("Payment was successful! Thank you for your transaction.");
          setIsApplyCodeModalOpen(false);

          markDownPayment(foundABooking.id);



          if (foundABooking.downPayment === foundABooking.totalPrice) {
            console.log('Down payment equals total price, marking booking as paid.');
          
          } else {
            console.log('Down payment does not equal total price, cannot mark as paid.');
          }

        } else {
          console.log('Invoice Code mismatch');
          setAlertMessage("Reference code used or does not exist."); 
        }
      } else if (status === 'unpaid') {
        console.log('Payment is unpaid.');
        setAlertMessage("Payment status is unpaid. Please complete the payment.");
      }

  
    }

  } catch (error) {
    console.error('Error fetching or processing data:', error.message);
    setAlertMessage("Invoice Code Incorect, Please check your Invoice Code.");
  }
};



const handleRefDown1 = async (amo,booking) => {

  await update(ref(db, `approvedBookings/${booking.id}`), {
    refID: "",
    downPayment: amo ,

  });

  await update(ref(db, `UserApprovedBookings/${currentUser.uid}/${booking.id}`), {
    refID: "",
    downPayment: amo,
    
  });

  await update(ref(db, `analytics/${booking.id}`), {
    downPayment: amo,
});


 

}


const handlePaidBooking = async (booking) => {
  console.log('Attempting to mark booking as paid:', booking);


  if (!booking) {
    console.error('No booking provided to mark as paid.');
    alert("No booking selected. Please select a booking to mark as paid.");
    return;
  }

  if (booking.downPayment === booking.totalPrice) {
    console.log('Down payment equals total price. Proceeding to mark as paid.');

    await update(ref(db, `approvedBookings/${booking.id}`), {
      status: "Paid",
    });
    console.log(`Booking ${booking.id} marked as paid in approvedBookings.`);

    await update(ref(db, `UserApprovedBookings/${currentUser.uid}/${booking.id}`), {
      status: "Paid",
    });
    console.log(`Booking ${booking.id} marked as paid in UserApprovedBookings.`);
  } else {
    console.log('Down payment does not equal total price. Cannot mark as paid.');
    alert("Down payment must equal the total price to mark as paid.");
  }
};




const handleRateClick = (booking) => {
  setSelectedBooking(booking);
  setIsRateModalOpen(true);
};

const handleRateModalClose = () => {
  setRating(0);
  setIsRateModalOpen(false);
  setSelectedBooking(null);
};


const handleRatingSubmit = async () => {
  if (!selectedBooking) return;

  if (rating < 1 || rating > 5) {
    setErrorMessage("Please provide a rating between 1 and 5 stars.");
    return; 
  }

  await update(ref(db, `analytics/${selectedBooking.id}`), {
    rating: rating,
  });

  await update(ref(db, `UserCompletedBookings/${currentUser.uid}/${selectedBooking.id}`), {
    rating: rating,
  });

  await update(ref(db, `completedBookings/${selectedBooking.id}`), {
    rating: rating,
  });

  handleRateModalClose();
};


const renderStarRating = () => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => (
        <FaStar
          key={index}
          className={`star ${index < rating ? 'filled' : ''}`}
          onClick={() => setRating(index + 1)}
          style={{ cursor: 'pointer', color: index < rating ? '#FFD700' : '#ccc' }} 
        />
      ))}
    </div>
  );
};




const renderCancelledBookingsTable = (bookings) => (
  <div className="booking-table">
    <table>
      <thead>
        <tr>
          <th>Booking #</th>
          <th>Name</th>
          <th>Email</th>
          <th>School</th>
          <th>Address</th>
          <th>Departure Date</th>
          <th>Package</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map((booking) => (
          <tr key={booking.id}>
            <td>{booking.id}</td>
            <td>{booking.userName}</td>
            <td>{booking.userEmail}</td>
            <td>{booking.schoolName}</td>
            <td>{booking.address}</td>
            <td>
            {activeTab === "cancelled" ? (
                  <span style={{ color: "red" }}>Cancelled</span>
                ) : (
                  <span> {booking.departureDate}</span>
                )}
                </td>

            <td>{booking.package}</td>
            <td>{booking.status}
                
                </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);









const handleCancelBooking = async (bookingId) => {
  if (!window.confirm("Are you sure you want to cancel this booking?")) {
    return;
  }

  const timestamp = Date.now();
  const bookingToCancel = bookings.find((booking) => booking.id === bookingId);
  if (bookingToCancel) {
    const bookingRef = ref(db, `UserBookings/${currentUser.uid}/${bookingId}`);
    const cancelledBookingsRef = ref(db, `UserCancelledBookings/${currentUser.uid}/${bookingId}`);
    const analyticsRef = ref(db, `analytics/${bookingId}`); 
    const globalBookingRef = ref(db, `bookings/${bookingId}`);
    const globalCancelledBookingsRef = ref(db, `cancelledBookings/${bookingId}`); 


    
    try {
      await set(cancelledBookingsRef, bookingToCancel);
      await remove(bookingRef);
      await update(analyticsRef, {
        level: "cancelled",
        userId: currentUser.uid,
        package: bookingToCancel.package,
        statusViewed: false,
        status: "cancelled",
        timestamp: timestamp,
          time: new Date(timestamp).toLocaleString()
        
      });
      

      await remove(globalBookingRef); 
      await set(globalCancelledBookingsRef, bookingToCancel);
      
      console.log("Booking cancelled, moved, and analytics updated successfully");

      const userEmail = bookingToCancel.userEmail; 
      const subject = "Your Booking has been cancelled";
      const message = `${bookingToCancel.userName},\n\nYour booking (ID: ${bookingToCancel.id}) for the package "${bookingToCancel.package}" has been cancelled on ${new Date().toLocaleDateString()}. Thank you!`;
      sendEmail(userEmail, subject, message);

      const notificationMessage = `Your booking #${bookingToCancel.id} has been cancelled.`;
              await createNotification(currentUser.uid, notificationMessage, bookingToCancel.id, "cancelled");

  
      bookings.pop(bookingToCancel);
    } catch (error) {
      console.error("Error canceling booking:", error.message);
      window.alert("Error canceling booking. Please try again.");
    }
  } else {
    console.log("Booking not found.");
    window.alert("Booking not found. Please try again.");
  }
};



const createNotification = async (userId, message, bookingId,status) => {
  try {
    const notificationRef = ref(db, `notifications/${userId}`);
    const newNotificationRef = push(notificationRef);
    
    const timestamp = Date.now();
    
    await update(newNotificationRef, {
      message,
      bookingId,
      time: timestamp,
      timestamp: timestamp,
      read: false,
      statusViewed: false,
      bgColor: 'bg-blue-100',
      createdAt: new Date(timestamp).toLocaleString(),
      status: status
    });
    
    console.log("Notification created successfully");
  } catch (error) {
    console.error("Error creating notification:", error);
  }
};





useEffect(() => {
  if (!currentUser) return;

  const checkUpcomingBookings = () => {
    const today = new Date();
    approvedBookings.forEach(booking => {
      const bookingDate = new Date(booking.departureDate);
      const diffTime = bookingDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 7 && !booking.weekReminderSent) {
        const notificationRef = ref(db, `notifications/${currentUser.uid}/${booking.id}_week_reminder`);
        set(notificationRef, {
          message: `Your booking for ${booking.package} is in one week! Please prepare for your trip.`,
          time: Date.now(),
          read: false,
          status: 'approved',
          statusViewed: false,
          bgColor: 'bg-blue-100'
        });

        const bookingRef = ref(db, `UserApprovedBookings/${currentUser.uid}/${booking.id}`);
        update(bookingRef, {
          weekReminderSent: true
        });

        if ("Notification" in window && Notification.permission === "granted") {
          new Notification("Upcoming Booking Reminder", {
            body: `Your booking for ${booking.package} is in one week!`,
            icon: "/favicon.ico"
          });
        }

        const subject = "Upcoming Booking Reminder";
        const message = `${booking.userName},\n\nThis is a reminder that your booking (ID: ${booking.id}) for "${booking.package}" is scheduled for ${booking.departureDate}, which is one week from now. Please ensure you have made all necessary preparations for your trip.\n\nBest regards,\nEtourmo Travel and Tours`;
        sendEmail(booking.userEmail, subject, message);
      }
    });
  };

  checkUpcomingBookings();
  const intervalId = setInterval(checkUpcomingBookings, 24 * 60 * 60 * 1000);

  return () => clearInterval(intervalId);
}, [currentUser, approvedBookings]);

const [isChangeDateModalOpen, setIsChangeDateModalOpen] = useState(false);
const [newDate, setNewDate] = useState("");
const [dateChangeError, setDateChangeError] = useState("");

const today = new Date();
const thirtyDaysFromNow = new Date(today);
thirtyDaysFromNow.setDate(today.getDate() + 30);
const minDateForChange = thirtyDaysFromNow.toISOString().split('T')[0];

const handleChangeDateClick = (booking) => {
  const today = new Date();
  const departureDate = new Date(booking.departureDate);
  const timeDiff = departureDate - today;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff <= 14) {
    alert("You cannot request a change of date within 14 days of the departure date.");
    return; 
  }

  setSelectedBooking(booking);
  setIsChangeDateModalOpen(true);
  setNewDate("");
  setDateChangeError("");
};

const handleChangeDateSubmit = async () => {
  if (!newDate) {
    setDateChangeError("Please select a new date");
    return;
  }

  const selectedDate = new Date(newDate);
  const today = new Date();
  const departureDate = new Date(selectedBooking.departureDate);
  const twoWeeksBeforeDeparture = new Date(departureDate);
  twoWeeksBeforeDeparture.setDate(departureDate.getDate() - 14);

  if (selectedDate < today) {
    setDateChangeError("Please select a future date");
    return;
  }

  if (selectedDate < twoWeeksBeforeDeparture) {
    setDateChangeError("You cannot request a change of date within two weeks of the departure date.");
    return;
  }

  try {
    const AdminNotificationMessage = `Booking #${selectedBooking.id} has Requested a Change date.`;
    await createAdminNotification(AdminNotificationMessage, selectedBooking.id, 'requestDateChange');

    const notificationMessage = `Your Request of Changing the date of booking #${selectedBooking.id} has been submitted successfully.`;
    await createNotification(currentUser.uid, notificationMessage, selectedBooking.id, "requestDateChange");

    


    await update(ref(db, `UserApprovedBookings/${currentUser.uid}/${selectedBooking.id}`), {
      status: "Request Change Date"
    });

    await update(ref(db, `approvedBookings/${selectedBooking.id}`), {
      status: "Request Change Date"
    });

    setIsChangeDateModalOpen(false);
    alert("Date change request submitted successfully!");

    await set(ref(db, `userRequestedDates/${currentUser.uid}/${selectedBooking.id}`), {
      requestedDate: newDate
    });

    await set(ref(db, `requestedDates/${selectedBooking.id}`), {
      requestedDate: newDate
    });

  } catch (error) {
    console.error("Error submitting date change request:", error);
    setDateChangeError("Failed to submit date change request. Please try again.");
  }
};





const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
const [refundErrorMessage, setRefundErrorMessage] = useState("");

const handleRefundClick = (booking) => {
  setSelectedBooking(booking);
  setIsRefundModalOpen(true);
};




const processRefundRequest = async () => {
  const booking = selectedBooking;
  const departureDate = new Date(booking.departureDate);
  const currentDate = new Date();
  const difference = departureDate.getTime() - currentDate.getTime();
  const daysUntilDeparture = Math.ceil(difference / (1000 * 3600 * 24));

  if (booking.downPayment === 0) {
    setRefundErrorMessage("You cannot request a refund because the down payment is 0.");
    return;
  }

  if (daysUntilDeparture < 5) {
    setRefundErrorMessage("No refunds are issued for requests made less than 5 days prior to the trip.");
    return;
  }

  try {
    const AdminNotificationMessage = `Booking #${booking.id} has Requested a Refund.`;
    await createAdminNotification(AdminNotificationMessage, booking.id, 'requestRefund');

    const notificationMessage = `Your Refund request for booking #${booking.id} has been submitted successfully.`;
    await createNotification(currentUser.uid, notificationMessage, booking.id, "requestRefund");

    await update(ref(db, `UserApprovedBookings/${currentUser.uid}/${booking.id}`), {
      status: "Request Refund"
    });

    await update(ref(db, `approvedBookings/${booking.id}`), {
      status: "Request Refund"
    });

    setIsRefundModalOpen(false);
    alert("Refund request submitted successfully!");
  } catch (error) {
    console.error("Error submitting refund request:", error);
    alert("Failed to submit refund request. Please try again.");
  }
};

const renderRefundModal = () => (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={() => setIsRefundModalOpen(false)} >&times;</span>
      <h2>Request Refund for Booking #{selectedBooking?.id}</h2>
      <p>Name: {selectedBooking?.userName}</p>
      <p>Email: {selectedBooking?.userEmail}</p>
      <p>Package: {selectedBooking?.package}</p>
      <p>Paid: ₱{selectedBooking?.downPayment}</p>
      <p>Departure Date: {selectedBooking.departureDate}</p>
      <p>Are you sure you want to request a refund for this booking?</p>


      <p style={{color: 'red'}}>Refunds are granted only if requested at least 14 
        days before the field trip, with a 20% surcharge 
        applied. For requests made 5-14 days before the 
        trip, a 50% surcharge applies, and no refunds are 
        issued for requests made less than 5 days prior. 

        </p>
        <p>- Refunds are given in cash at the Etourmo Travel and Tours office, 214 Abacan Brgy Calvario, Meycauayan, 
        Philippines, 3020.
        </p>

      {refundErrorMessage && <p className="text-red-500">{refundErrorMessage}</p>}
      <button
        className="mt-4 p-2 bg-blue-500 text-white rounded"
        onClick={processRefundRequest}
      >
        Yes, Request Refund
      </button>
      <button
        className="mt-4 p-2 bg-gray-500 text-white rounded"
        onClick={() => setIsRefundModalOpen(false)}
      >
        Cancel
      </button>
    </div>
  </div>
);


const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + 1);
const maxDateString = maxDate.toISOString().split('T')[0];

const [dateNotifications, setDateNotifications] = useState([]);

useEffect(() => {
  if (!currentUser) return;

  const dateNotificationsRef = ref(db, `dateNotifications`);
  onValue(dateNotificationsRef, (snapshot) => {
    if (snapshot.exists()) {
      const notificationsData = snapshot.val();
      const userNotifications = Object.entries(notificationsData).filter(
        ([, notification]) => notification.userId === currentUser.uid
      );
      setDateNotifications(userNotifications);
    }
  });
}, [currentUser]);

useEffect(() => {
  if (currentUser) {
    const requestedDatesRef = ref(db, `userRequestedDates/${currentUser.uid}`);
    onValue(requestedDatesRef, (snapshot) => {
      if (snapshot.exists()) {
        const requestedDatesData = snapshot.val();
        const requestedDatesArray = Object.keys(requestedDatesData).map((key) => ({
          id: key,
          ...requestedDatesData[key],
        }));
        setRequestedDates(requestedDatesArray);
      } else {
        console.log("No requested dates available");
      }
    });
  }
}, [currentUser]);

useEffect(() => {
  const checkBookingsForPayment = async () => {
    for (const booking of approvedBookings) {
      console.log(`Checking booking ${booking.id} for payment...`);
      if (booking.downPayment === booking.totalPrice) {
        console.log(`Down payment equals total price for booking ${booking.id}. Marking as paid.`);
        await handlePaidBooking(booking);
      } else {
        console.log(`Down payment does not equal total price for booking ${booking.id}.`);
      }
    }
  };

  if (approvedBookings.length > 0) {
    checkBookingsForPayment();
  }
}, [approvedBookings]);

useEffect(() => {
  if (selectedBooking) {
    if (selectedBooking.downPayment > 0) {
      setCheckoutAmount(selectedBooking.totalPrice - selectedBooking.downPayment);
      setPaymentType("Full Payment"); 
    } else {
      setPaymentType(""); 
    }
  }
}, [selectedBooking]);

return (
  <div>
     <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
      <div className="container d-flex fixed-top">
        <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
        <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
            <li><a className="nav-link scrollto" href="home">Home</a></li>
            <li><a className="nav-link scrollto" href="home#about">About</a></li>
            <li><a className="nav-link scrollto" href="home#team">Team</a></li>
            <li><a className="nav-link scrollto" href="home#faq">FAQ</a></li>
            <li><a className="nav-link scrollto" href="booking">Book</a></li>
            <li><a className="nav-link scrollto" href="packages">Packages</a></li>
              
            {userLoggedIn && (
              <li><a className="nav-link scrollto active" href="UserBookings">My Bookings</a></li>
            )}
            <div className="ml-2" style={{ marginLeft: '35px' }}>
                  <UserNotifications />
                </div>
            {userLoggedIn ? (
              <li className="ml-12 dropdown d-flex align-items-center">
                <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                  <img
                    src={profileImage || "assets/img/profile.png"}
                    alt="User Image"
                    className="user-avatar"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <a 
                    href="#" 
                    onClick={(e) => {
                      e.preventDefault();
                      setDropdownVisible(!dropdownVisible);
                    }} 
                    style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                  >
                    <i className="bi bi-chevron-down" />
                  </a>
                </div>
                
                {dropdownVisible && (
                  <ul className="dropdown-menu" style={{ 
                    position: 'absolute', 
                    left: '-150px', 
                    top: '100%',
                    backgroundColor: '#fff', 
                    border: '1px solid #ccc', 
                    padding: '10px', 
                    zIndex: '1000',
                    marginTop: '5px'
                  }}>
                    <li>
                      <a>
                        <span>{currentUser.email}</span>
                      </a>
                    </li>
                    <li><a href="profile">Profile</a></li>
                    {admin === "yes" && (
                      <li><a href="adminbookinglist">Admin Dashboard</a></li>
                    )}
                    <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                  </ul>
                )}
              </li>
            ) : (
              <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
            )}
          </ul>
          <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
        </nav>
      </div>
    </header>
{/* End Header */}


    <div className="container mt-30">
      <nav className="mt-30 nav nav-tabs">
        <a
          className={`mt-20 nav-item nav-link ${activeTab === "bookings" ? "active" : ""}`}
          onClick={() => setActiveTab("bookings")}
        >
          Pending
        </a>
        <a
          className={`mt-20 nav-item nav-link ${activeTab === "approved" ? "active" : ""}`}
          onClick={() => setActiveTab("approved")}
        >
          Approved Bookings
        </a>
        <a
          className={`mt-20 nav-item nav-link ${activeTab === "completed" ? "active" : ""}`}
          onClick={() => setActiveTab("completed")}
        >
          Completed Bookings
        </a>
        <a className={`mt-20 nav-item nav-link ${activeTab === "cancelled" ? "active" : ""}`} onClick={() => setActiveTab("cancelled")}>
          Cancelled Bookings
        </a>
      </nav>

      {activeTab === "bookings" && (
        <section id="booking-list" className="mt-10">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Pending Bookings</h2>

              <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>


        <p style={{ textAlign: 'left', color: 'red' }}>
          Please upload your ID for verification in the profile page within 7 days </p>

          <p style={{ textAlign: 'left', color: 'red' }}>
          If the required document is not uploaded within this timeframe, your booking will be cancelled. </p>
             
              <div className="d-flex">
              
                </div>
              {renderBookingsTable(sortedBookings, (booking) => (
                <>
                  <button className="mb-2 btn btn-danger action-button" onClick={() => handleCancelBooking(booking.id)}>
                    Cancel
                  </button>
                  
                </>
              ))}
              
            </div>
          </div>
        </section>
      )}

      {activeTab === "approved" && (
        <section id="approved-bookings" className="mt-10">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Approved Bookings</h2>

              <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>
              <p style={{ textAlign: 'left', color: 'red' }}>After payment, please input your invoice code to update your payment</p>
              <div className="d-flex">
              
                </div>
              {renderBookingsTable(sortedApprovedBookings, (booking) => (
                <> 
                <button className="mb-2 btn btn-success action-button btn-fixed-height text-center" onClick={() => handlePayClick(booking)}>
                  <span style={{ fontSize: '12px' }}>Pay</span>
                </button>
                <br/>

                <button className="mb-2 btn btn-info action-button btn-fixed-height text-top" onClick={() => handleApplyCodeClick(booking)}>
                  <span style={{ fontSize: '12px' }}>Apply Code</span>
                </button>
                <br/>

                <button className="mb-2 btn btn-info action-button btn-fixed-height text-top" onClick={() => handleChangeDateClick(booking)}>
                  <p style={{ fontSize: '12px' }}>Request Change Date</p>
                </button>
                <br/>

                <button className="btn btn-info action-button btn-fixed-height text-center" onClick={() => handleRefundClick(booking)}>
                  <p style={{ fontSize: '12px' }}>Request Refund</p>
                </button>
</>
              
              ))}
            </div>
          </div>
        </section>
      )}

{activeTab === "completed" && (
  <section id="completed-bookings" className="mt-10">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Completed Bookings</h2>

        <p style={{ marginBottom: '10px' }}>
          Click on the column headers to sort the bookings by that attribute. 
          The sorting order toggles between ascending and descending with each click.
        </p>
        {renderBookingsTable(sortedCompletedBookings, (booking) => (
          <button className="btn btn-success action-button" onClick={() => handleRateClick(booking)}>
            Rate
          </button>
        ), true)} 
      </div>
    </div>
  </section>
)}

{activeTab === "cancelled" && (
  <section id="cancelled-bookings" className="mt-10">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Cancelled Bookings</h2>
        {renderCancelledBookingsTable(cancelledBookings)} 
      </div>
    </div>
  </section>
)}
    </div>
    

    {isApplyCodeModalOpen  && (
  
  <div className="modal" >
    <div className="modal-content" >
      <span className="close" onClick={() => setIsApplyCodeModalOpen(false)} style={{color: 'black'}}>&times;</span>
      <h2>Apply Invoice Code for Booking #{selectedBooking?.id}</h2>
      <p>Name: {selectedBooking?.userName}</p>
      <p>Email: {selectedBooking?.userEmail}</p>
      <p>School: {selectedBooking?.schoolName}</p>
      <p>Package: {selectedBooking?.package}</p>
      <p>Pax: {selectedBooking?.pax}</p>
      
      <label>
        Invoice Code: (do not include "#")
        <input
          type="text"
          className="border-black-00 ml-5 rounded-lg p-2"
          value={RefID}
          onChange={handleInputChange}
        />
      </label>
  
      <button
        className="mt-4 p-2 bg-blue-500 text-white rounded"
        onClick={() => handleButtonClick()}
      >
        Apply Code
      </button>
      {alertMessage && <div className="alert-message">{alertMessage}</div>}
    </div>
  </div>
)}


  
{isModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={handleModalClose} style={{color: 'black'}}>&times;</span>
      <h2>Payment for Booking #{selectedBooking.id}</h2>
      <p>Name: {selectedBooking.userName}</p>
      <p>Email: {selectedBooking.userEmail}</p>
      <p>School: {selectedBooking.schoolName}</p>
      <p>Address: {selectedBooking.address}</p>
      <p>Departure Date: {selectedBooking.departureDate}</p>
      <p>Package: {selectedBooking.package}</p>
      <p>Pax:  {selectedBooking.pax}</p>
      
      <p>Total Price: <span style={{ color: 'black', fontWeight: 'bold' }}>{"₱" + selectedBooking.totalPrice + ".00"}</span></p>
      <p>Down Payment: <span style={{ color: 'black', fontWeight: 'bold' }}>{"₱" + selectedBooking.downPayment.toFixed(2)}</span></p>
      
      {selectedBooking.downPayment === 0 && (
        <p>Remaining Balance: <span style={{ color: 'black', fontWeight: 'bold' }}>{"₱" + (selectedBooking.totalPrice - selectedBooking.downPayment).toFixed(2)}</span></p>
      )}
      
      {/* Conditionally render payment type selection */}
      {selectedBooking.downPayment === 0 ? (
        <label>
          Payment Type:
          <select
            className="border-black-00 ml-5 rounded-lg p-2"
            value={paymentType}
            onChange={handlePaymentTypeChange}
          >
            <option value="">-Select payment option-</option>
            <option value="Full Payment">Full Payment</option>
            <option value="Down Payment">Down Payment</option>
          </select>
        </label>
        
      ) : null}

{showMinText && (
        <>
          <p>Minimum 50%: ₱ {fiftyPercentAmount}.00</p>
          <label>
            Down Payment Amount:
            <input
              type="text"
              className="border-black-00 ml-5 rounded-lg p-2"
              value={checkOutAmount}
              onChange={(e) => {setCheckoutAmount(e.target.value);
               
                 
              }} 
            />
          </label>
          <p>
            You can also increase the Down Payment. <span style={{ color: 'black',fontWeight: 'bold' }}></span>
          </p>
        </>
      )}

      <button className="underline mt-3" onClick={handleTermsOpen}>View Payment Terms</button>

      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}

      <button
        className="mt-4 p-2 bg-blue-500 text-white rounded"
        onClick={() => {
          const inputAmount = parseFloat(checkOutAmount);
          const remainingBalance = selectedBooking.totalPrice - selectedBooking.downPayment; 

          
          if (!agreedToTerms) {
            setErrorMessage("You must agree to the payment terms before proceeding."); 
            return; 
          }

          if (!paymentType) {
            setErrorMessage("Please select a payment type."); 
            return; 
          }

          if (inputAmount > remainingBalance) {
            alert(`Amount cannot exceed the remaining balance of ₱${remainingBalance}.00`);
            return; 
          }
       
          if (inputAmount < fiftyPercentAmount) {
            setErrorMessage(`Amount must be at least ₱${fiftyPercentAmount}.00 for this payment type.`);
            return; 
          }

          
          if (!Number.isInteger(inputAmount)) {
            setErrorMessage("Please enter a whole number for the amount."); 
            return; 
          }

          
          handlePayment(selectedBooking); 
        }}
      >
        Proceed to Payment
      </button>
    </div>
  </div>
)}

{isTermsModalOpen && termsContent}



{isRateModalOpen && (
  <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Rate Booking #{selectedBooking.id}</h5>
          <button type="button" className="close" onClick={handleRateModalClose}>
            <span aria-hidden="true">&times;</span>
          </button>
         
        </div>
        <div>
          <h5 className="mt-2 modal-title">{selectedBooking.package}</h5>
            </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label htmlFor="rating">Rate booking:</label>
              {renderStarRating()} {/* Render star rating */}
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={handleRateModalClose}>
            Close
          </button>
          <button type="button" className="btn btn-primary" onClick={handleRatingSubmit}>
            Submit Rating
          </button>
        </div>
      </div>
    </div>
  </div>
)}

{showWarning && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      textAlign: 'center',
      maxWidth: '400px',
      width: '90%'
    }}>
      <h4 style={{ marginBottom: '20px', color: '#333' }}>Session Timeout Warning</h4>
      <p style={{ marginBottom: '20px', color: '#666' }}>
        Your session will expire in 15 seconds. Would you like to continue?
      </p>
      <button 
        onClick={() => {
          setShowWarning(false);
          resetTimeout();
        }}
        style={{
          backgroundColor: '#4154f1',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px'
        }}
      >
        Continue Session
      </button>
    </div>
  </div>
)}

{isChangeDateModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={() => setIsChangeDateModalOpen(false)} style={{color: 'black'}}>&times;</span>
      <h2>Request Date Change for Booking #{selectedBooking.id}</h2>
      <p>Name: {selectedBooking.userName}</p>
      <p>Email: {selectedBooking.userEmail}</p>
      <p>School: {selectedBooking.schoolName}</p>
      <p>Package: {selectedBooking.package}</p>
      <p>Departure Date: {selectedBooking.departureDate}</p>

      <p style={{color: 'red'}}>You can request to change your booking date up to two weeks before the scheduled date. 
        The change will only be approved if the requirements are submitted.</p>
      
      <div className="form-group mt-3">
        <label htmlFor="newDate">Select New Date:</label>
        <input
          type="date"
          id="newDepartureDate"
          name="newDepartureDate"
          value={newDate}
          onChange={(e) => setNewDate(e.target.value)}
          required
          min={minDateForChange}
          className="w-full border-gray-300 rounded-lg p-2"
    
        />
      </div>

      {dateChangeError && (
        <p className="text-red-500 mt-2">{dateChangeError}</p>
      )}

      <button
        className="mt-4 p-2 bg-blue-500 text-white rounded"
        onClick={handleChangeDateSubmit}
      >
        Submit Request
      </button>
    </div>
  </div>
)}

{isRefundModalOpen && renderRefundModal()}





      <footer id="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>Etourmo</h3>
                <p>
                  214 Abacan St. Brgy Calvario <br />
                  Meycauayan Bulacan<br />
                  Philippines <br /><br />
                  <strong>Phone:</strong> 0908 300 0009<br />
                  <strong>Email:</strong>ETOURMO@GMAIL.COM<br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            © Copyright <strong><span>Arsha</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </div>
      </footer>

      
      
    </div>
  );
};

export default UserBookings;