import React, {useEffect, useState } from "react";
  import { Link, useNavigate, Navigate } from "react-router-dom";
  import { useAuth } from "../../../contexts/authContext";
  import { db } from "../../../firebase/firebase";
  import { set, ref } from "firebase/database";
  import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, deleteUser } from "firebase/auth"; 




  import "./styles.css";

  const Register = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [idImage, setIdImage] = useState(null);
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isTypingPassword, setIsTypingPassword] = useState(false); 
    const [showPassword, setShowPassword] = useState(false); 
    const [emailVerified, setEmailVerified] = useState(false); 
    const [showMessage, setShowMessage] = useState(false);
    const admin = "no";
    const adminType ="no";

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };


    const validateName = (name) => {
      const nameRegex = /^[A-Za-z\s'-]+$/;
      return nameRegex.test(name);
    };
    useEffect(() => {
      if (userLoggedIn) {
          console.log("Current User:", userLoggedIn);
          const user = getAuth().currentUser;
          if (user) {
              setEmailVerified(user.emailVerified);
              console.log("Initial Email Verified Status:", user.emailVerified);
          }
       }
      }, [userLoggedIn]);
    
    
const handleSignUp = async (e) => {
  e.preventDefault();

  if (!isRegistering) {
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setErrorMessage("Please fill in all required fields");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(password)) {
      setErrorMessage(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character, and be at least 8 characters long"
      );
      return;
    }

    if (!validateName(firstName) || !validateName(middleName) || !validateName(lastName)) {
      setErrorMessage(
        "Names can only contain letters, spaces, hyphens, and apostrophes."
      );
      return;
    }

    if (!termsAccepted) {
      setErrorMessage("Please accept the terms and conditions to continue");
      return;
    }

    setIsRegistering(true);

    try {
      const auth = getAuth(); 
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid; 

      await sendEmailVerification(userCredential.user);

      
      const verificationTimeout = setTimeout(async () => {
        const user = getAuth().currentUser;
        if (user && !user.emailVerified) {
          await deleteUser(user); 
          await set(ref(db, `/users/${userId}`), null); 
        }
      }, 120000); 

      await set(ref(db, `/users/${userId}`), {
        email,
        firstName,
        middleName,
        lastName,
        profileImage,
        idImage,
        admin,
        adminType,
      });

   

      setTimeout(() => {
        clearTimeout(verificationTimeout); 
        navigate("/registrationComplete");
      }, 100); 
    } catch (error) {
      setIsRegistering(false);
      setErrorMessage(error.message);
    }
  }
};


    const handlePasswordFocus = () => {
      setIsTypingPassword(true);
    };

    const handlePasswordBlur = () => {
      setIsTypingPassword(false);
    };

    const handleProfileImageChange = (e) => {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleIdImageChange = (e) => {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setIdImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const togglePopup = () => {
      setShowPopup(!showPopup);
    };

    const Popup = () => {
      return (
        <div className="popup1">
          <div className="popup-content1" style={{ textAlign: "justify" }}>
            <h2><strong>Terms and Conditions</strong></h2>
    
            <p>
              <h3><strong>Section 1. Etourmo Travel and Tours Rules and Regulations</strong></h3>
            </p>
            <p>Welcome to Etourmo Travel and Tours. These terms and conditions outline the rules and regulations for the use of the Website and any related services, features, and content provided by Etourmo Travel and Tours.</p>
    
            <p><strong>1.</strong> By accessing this Website, we assume you accept these terms and conditions in full. Do not continue to use Etourmo Travel and Tours if you do not accept all the terms and conditions stated on this page.</p>
            <p><strong>2.</strong> All content on Etourmo Travel and Tours is protected by intellectual property rights owned by Company and/or its licensors, unless otherwise indicated. Every right to intellectual property is reserved. Within the limitations outlined in these terms and conditions, you are permitted to browse and/or print pages from the Website for your own personal use.</p>
            
            <p><strong>3.</strong> In particular, you are prohibited from all of the following:</p>
            <ul>
              <li>3.1 Using any content from the website in other media;</li>
              <li>3.2 Commercializing any content on the website through sales, sublicensing, or other means;</li>
              <li>3.3 Performing in public or displaying any content from the website;</li>
              <li>3.4 Making any use of this website that could cause harm to it;</li>
              <li>3.5 Making any use of this website that could hinder users' ability to access it;</li>
              <li>3.6 Using this website in a way that violates the law or other applicable regulations, or that could endanger the website, any individual, or company;</li>
              <li>3.7 Using our Website as the basis for any kind of data mining, data harvesting, data extraction, or other comparable activity;</li>
              <li>3.8 Making any kind of marketing or advertising use of our website.</li>
              <li>3.9 In order to complete and confirm your booking or account registration, you are required to upload a valid identification document. Accepted forms of identification include, but is not limited to:
              - Employment identification card</li>

            </ul>
    
            <p><strong>4.</strong> This website is offered "as is," with all defects, and Company makes no warranties or representations whatsoever about this website or the content on it. Furthermore, nothing on this website should be construed as advice.</p>
            <p><strong>5.</strong> The Company and its officers, directors, and employees will never be held responsible for anything that results from or is related to your use of this website, even if that liability is based on a contract.</p>
            <p><strong>6.</strong> Any provision of these terms that is determined to be unlawful by any relevant legislation will be removed, with no effect on the remaining parts of these terms.</p>
            <p><strong>7.</strong> By using this website, you agree that the company may modify these terms at any time as it sees appropriate. It is your responsibility to regularly examine these terms to make sure you are aware of all the terms and conditions governing the use of this website.</p>
    
            <p>If you have any questions about these Terms and Conditions, please contact us via email at <strong>etourmo@gmail.com</strong>.</p>
    
            <h3><strong>Section 2. Data Privacy Act of the Philippines</strong></h3>
            <p>The Data Privacy Act of 2012 (Republic Act No. 10173) governs data protection and privacy. It ensures that personal data is secured, handled properly, and that individuals and organizations are held accountable for the privacy of the data they collect, store, and process.</p>
    
            <p><strong>Key Objectives:</strong></p>
            <ul>
              <li>Protection of Personal Data: Ensuring that personal information is secured and handled properly.</li>
              <li>Accountability: Holding individuals and organizations responsible for data privacy practices.</li>
              <li>Transparency: Promoting transparency in how personal data is collected and used.</li>
            </ul>
    
            <p><strong>Important Terms:</strong></p>
            <ul>
              <li><strong>Personal Information:</strong> Any information that can identify a person, such as name, address, contact details, etc.</li>
              <li><strong>Sensitive Personal Information:</strong> Specific data like race, religion, health, or criminal records.</li>
              <li><strong>Data Subject:</strong> The individual whose personal information is being processed.</li>
              <li><strong>Data Controller:</strong> The entity that decides how and why personal data is processed.</li>
              <li><strong>Data Processor:</strong> The entity that processes personal data on behalf of a data controller.</li>
            </ul>
    
            <p><strong>Rights of Data Subjects:</strong></p>
            <ul>
              <li>Right to be Informed</li>
              <li>Right to Access</li>
              <li>Right to Rectification</li>
              <li>Right to Erasure or Blocking</li>
              <li>Right to Data Portability</li>
            </ul>
    
            <p><strong>Obligations of Organizations:</strong></p>
            <ul>
              <li>Ensure Data Security</li>
              <li>Obtain Consent</li>
              <li>Report Data Breaches</li>
            </ul>
    
            <p><strong>Enforcement and Penalties:</strong></p>
            <ul>
              <li>The National Privacy Commission (NPC) is responsible for enforcing the Act and can investigate breaches, conduct checks, and impose penalties.</li>
              <li>Penalties for violating the Data Privacy Act range from fines to imprisonment.</li>
            </ul>

            <p><strong>Cookies</strong></p>
            <ul>
              <li>Internet cookies are small text files that store data, like usernames and passwords, to identify your computer on a network and make your browsing experience better. They are created by the server when you connect to a website.</li>
            </ul>

            <p><strong>Session Timeout</strong></p>
            <ul>
              <li>Session timeout on a website is to enhance security and manage resources by automatically ending a user's session after a period of inactivity. This helps protect sensitive information and prevents unauthorized access, especially if the user forgets to log out.</li>
            </ul>

    
            <h3><strong>Section 3. Payment, Cancellation, and Refund Policies</strong></h3>
    
            <p><strong>1. Down Payment Policy</strong></p>
            
        <ul>
          <li>50% downpayment 1 month before the trip.</li>
          <li>50% payment of the remaining balance on the day of the tour.</li>
     
        </ul>
        

      
    
            <p><strong>2. Cancellation Policy</strong></p>
            <ul>
              <li>10% surcharge if canceled one week before the trip; 25% five days before the trip; No refund after the fifth day</li>
              <li>Rescheduling may be valid in "force majeure" circumstances</li>
            </ul>
    
            <p><strong>3. Refund Policy</strong></p>
            <ul>
              <li>All cancellation requests must be submitted in writing via email or live chat.</li>
              <li>The refund timeline starts when the written request is received.</li>
              <li>Refunds will be processed within 14 business days through the original payment method.</li>
            </ul>
    
            <p><strong>4. Exceptional Circumstances</strong></p>
            <ul>
              <li>Medical Emergencies: Full refund may be issued with official medical documentation.</li>
              <li>Force Majeure: Full refund or credit may be offered for unforeseen events like natural disasters.</li>
            </ul>
    
            <p><strong>5. Trip Cancellation by Organizers</strong></p>
            <p>If the trip is canceled by the organizers, participants will receive a full refund of all fees paid.</p>
    
            <p><strong>6. Refund Processing Time</strong></p>
            <p>Refunds will be processed within 14 business days and issued through the original payment method.</p>
    
            <p><strong>7. No-Show Policy</strong></p>
            <p>No refunds for participants who do not show up on the day of the trip without prior notice or approval.</p>
    
            <p><strong>8. Participant Substitution</strong></p>
            <p>Participants may request to substitute another individual, subject to approval and any additional fees.</p>
    
            <p><strong>9. Claiming of Refund</strong></p>
            <p>Refunds will be issued through cash only and may be claimed at Etourmo Travel and Tours' office, 214 Abacan Brgy Calvario, Meycauayan, Philippines.</p>
    
            <div className="flex items-center">
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                onClick={() => {
                  togglePopup();
                  setShowMessage(false);
                }} 
                className="mr-2"
              />
              <label className="text-sm text-gray-600">
                I accept the terms and conditions
              </label>
            </div>
            <button onClick={togglePopup}>Close</button>
          </div>
        </div>
      );
    };
    
    

    return (
      <>
        <main
          className="w-full h-auto flex self-center place-content-center place-items-center"
          style={{
            backgroundImage: "url('assets/img/officeETM.png')",
            backgroundSize: "cover", 
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat", 
          }}
        >



{emailVerified && <Navigate to={"/home"} replace={true} />} 

        <header id="header" className="fixed-top navy-blue"style={{ height: '50px' }}>
    <div className="container d-flex fixed-top ">
      <img src="assets\img\ETOURMO LOGO.png"  className=" image" style={{ width: '100px', height: '50px' }} />
      <nav id="navbar" className="ml-15 navbar">
        <ul>
        
          <li><a className="nav-link scrollto active" href="home">Home</a></li>
          <li><a className="nav-link scrollto" href="home#about">About</a></li>
          <li><a className="nav-link scrollto" href="home#team">Team</a></li>
          <li><a className="nav-link scrollto" href="home#faq">FAQ</a></li>
          <li><a className="nav-link scrollto" href="booking">Booking</a></li>
          <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" />
      </nav>
    </div>
  </header>

          <div
            className={`mt-20 mb-10 w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl ${
              showPopup ? "slide-in-bottom" : ""
            }`}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
          >
            <h1 className="font-semibold text-center">Register</h1>
            
            <form onSubmit={handleSignUp}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm">Email:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div>
                  <label className="block text-sm">Password:</label>
                  <input
                    type={showPassword ? "text" : "password"} 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  <button type="button" onClick={() => setShowPassword(!showPassword)} className="text-sm text-blue-600">
                    {showPassword ? "Hide" : "Show"} Password
                  </button> 
                  {isTypingPassword && (
                    <div className="text-xs text-gray-500 mt-1">
                      Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character, and be at least 8 characters long.
                    </div>
                  )}
                </div>
                <div>
                  <label className="block text-sm">Confirm Password:</label>
                  <input
                    type={showPassword ? "text" : "password"} 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div>
                  <label className="block text-sm">First Name:</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) =>
                      setFirstName(capitalizeFirstLetter(e.target.value))
                    }
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div>
                  <label className="block text-sm">Middle Name:</label>
                  <input
                    type="text"
                    value={middleName}
                    onChange={(e) =>
                      setMiddleName(capitalizeFirstLetter(e.target.value))
                    }
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div>
                  <label className="block text-sm">Last Name:</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) =>
                      setLastName(capitalizeFirstLetter(e.target.value))
                    }
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div>
                  <label className="block text-sm">Profile Image:(optional)</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfileImageChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {profileImage && (
                    <img
                      src={profileImage}
                      alt="Profile Preview"
                      className="mt-2 h-20 w-20 object-cover"
                    />
                  )}
                </div>
                <div>
                  <label className="block text-sm">ID Image:(optional)</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleIdImageChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {idImage && (
                    <img
                      src={idImage}
                      alt="ID Preview"
                      className="mt-2 h-20 w-20 object-cover"
                    />
                  )}
                </div>
                <div className="flex items-center">
                <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onClick={() => {
                      setShowMessage(!termsAccepted);
                    }}
                    className="mr-2"
                    title="You must read the terms and conditions in order to check this box"
                  
                  />
                  
                 

                  <label htmlFor="terms" className="text-sm text-gray-600">
                    I accept the{" "}
                    <button
                      type="button"
                      className="text-blue-600 underline"
                      onClick={togglePopup}
                    >
                      terms and conditions
                    </button>
                  </label>
                </div>

                {showMessage && ( 
                    <div className="text-sm text-red-700">
                      You must read the terms and conditions in order to check this box
                    </div>
                  )}
                {errorMessage && (
              <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg">
                {errorMessage}
              </div>
            )}
            
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
                    href="login"
                  >
                    {isRegistering ? "Registering..." : "Register"}
                  </button>
                </div>
                <div className="text-center">
                  <p className="text-sm text-gray-600">
                    Already have an account?{" "}
                    <Link to="/login" className="text-blue-600 underline">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </main>
        {showPopup && <Popup />}
      </>
    );
  };

  export default Register;