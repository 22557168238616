import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { getAuth, deleteUser } from "firebase/auth";
import { ref, set } from "firebase/database";
import { db } from "../firebase/firebase";

const RegistrationComplete = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [countdown, setCountdown] = useState(120);
    const [emailVerified, setEmailVerified] = useState(false);
    const [showVerifiedMessage, setShowVerifiedMessage] = useState(false);
    const [redirectCountdown, setRedirectCountdown] = useState(10);

    useEffect(() => {
        const storedCountdown = localStorage.getItem('countdown');
        if (storedCountdown) {
            setCountdown(Number(storedCountdown));
            console.log(`Countdown retrieved from localStorage: ${storedCountdown}`);
        } else {
            console.log("No countdown found in localStorage, starting from 120 seconds.");
        }

        if (currentUser) {
            const verificationTimeout = setTimeout(async () => {
                const user = getAuth().currentUser;
                if (user && !user.emailVerified) {
                    const userId = user.uid;
                    await deleteUser(user);
                    await set(ref(db, `/users/${userId}`), null);
                    doSignOut();
                    window.location.href = '/login';
                }
            }, countdown * 1000);

            const interval = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        console.log("Countdown finished.");
                        return 0;
                    }
                    const newCountdown = prev - 1;
                    localStorage.setItem('countdown', newCountdown);
                    console.log(`Countdown updated: ${newCountdown} seconds remaining.`);
                    return newCountdown;
                });
            }, 1000);

  
            const verificationCheckInterval = setInterval(() => {
                const user = getAuth().currentUser;
                if (user) {
                    user.reload().then(() => {
                        setEmailVerified(user.emailVerified);
                        console.log(`Email verified status: ${user.emailVerified}`);
                        if (user.emailVerified) {
                            console.log("Email verified. Signing out the user.");
                            setShowVerifiedMessage(true);
                            
                            console.log("going to login.");
                            setRedirectCountdown(5);
                            doSignOut(); 
                            const countdownInterval = setInterval(() => {
                                setRedirectCountdown(prev => {
                                    if (prev <= 1) {
                                        clearInterval(countdownInterval);
                                        window.location.href = '/login';
                                        return 0;
                                    }
                                    return prev - 1;
                                });
                            }, 1000);
                        }
                    });
                }
            }, 5000); 

            return () => {
                clearTimeout(verificationTimeout);
                clearInterval(interval);
                clearInterval(verificationCheckInterval); 
                localStorage.removeItem('countdown');
                console.log("Countdown cleared from localStorage.");
            };
        }
    }, [currentUser, navigate]);

    const handleGoBackToLogin = () => {
        doSignOut();
        navigate('/login');
    };

    return (
        <div>

           
            <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
                <div className="container d-flex fixed-top ">
                    <img src="assets\img\ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} />
                    <nav id="navbar" className="ml-15 navbar">
                        <ul>
                            {/* ... other nav items ... */}
                        </ul>
                    </nav>
                </div>
            </header>


            
            {showVerifiedMessage && (
     
                <div style={{ textAlign: 'center', marginTop: '70px' }}>
           
                    <h1>Email Verified!</h1>
                    <h2>Redirecting to login in {redirectCountdown} seconds...</h2>
                </div>
        )}

            {!showVerifiedMessage && (
                <div style={{ textAlign: 'center', marginTop: '70px' }}>
                    <h2>Email Verification</h2>
                    <p>Please verify your email that has been sent to your inbox.</p>
                    <p>{countdown} seconds to verify your email or your account will be deleted.</p>
                    <Button onClick={handleGoBackToLogin} variant="primary">
                        Go back to Login
                    </Button>
                </div>
            )}
        </div>
    );
};

export default RegistrationComplete;
