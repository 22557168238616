import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import "../pages/Booking.css";


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


const LUZON_BOUNDARIES = {
    north: 20.5,
    south: 12.5,
    east: 126.0,
    west: 119.0,
};


const PopupContainer = ({ message, onClose }) => (
    <div className="popup-container">
        <div className="popup-content">
            <p>{message}</p>
            <button onClick={onClose}>Close</button>
        </div>
    </div>
);

const LocationPicker = ({ position, setPosition, onLocationSelect, showMessage }) => {
    useMapEvents({
        click: async (e) => {
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;

            if (
                lat <= LUZON_BOUNDARIES.north &&
                lat >= LUZON_BOUNDARIES.south &&
                lng <= LUZON_BOUNDARIES.east &&
                lng >= LUZON_BOUNDARIES.west
            ) {
                setPosition([lat, lng]);

                try {
                    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);
                    const { display_name, address } = response.data;
                    const zipCode = address?.postcode || '';
                    onLocationSelect(display_name, zipCode);
                } catch (error) {
                    console.error("Error fetching location name:", error);
                    onLocationSelect("Location not found", '');
                }
            } else {
                showMessage("Please select a location within Luzon, Philippines.");
            }
        },
    });

    return (
        <Marker position={position} draggable>
            <Popup>You can drag me to select a location!</Popup>
        </Marker>
    );
};

const MapComponent = ({ onLocationSelect }) => {
    const [position, setPosition] = useState([14.5995, 120.9842]);
    const [locationText, setLocationText] = useState('');
    const [popupMessage, setPopupMessage] = useState(''); 


    const showMessage = (message) => {
        setPopupMessage(message);
    };

   
    const closePopup = () => {
        setPopupMessage('');
    };

   
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    if (
                        latitude <= LUZON_BOUNDARIES.north &&
                        latitude >= LUZON_BOUNDARIES.south &&
                        longitude <= LUZON_BOUNDARIES.east &&
                        longitude >= LUZON_BOUNDARIES.west
                    ) {
                        setPosition([latitude, longitude]); 

                        try {
                            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
                            const { display_name } = response.data;
                            setLocationText(display_name); 
                            onLocationSelect(display_name, response.data.address?.postcode || ''); 
                        } catch (error) {
                            console.error("Error fetching location name:", error);
                            setLocationText("Location not found");
                            onLocationSelect("Location not found", '');
                        }
                    } else {
                        showMessage("Your location is outside of Luzon, Philippines. Defaulting to Metro Manila.");
                    }
                },
                (error) => {
                    console.error("Error getting location: ", error);
                    showMessage("Unable to retrieve your location. Defaulting to Metro Manila.");
                }
            );
        } else {
            showMessage("Geolocation is not supported by your browser. Defaulting to Metro Manila.");
        }
    };

    useEffect(() => {
        
        const map = document.querySelector('.leaflet-container');
        const zoomControl = document.querySelector('.leaflet-control-zoom');
        if (zoomControl) {
            zoomControl.style.display = 'none';
        }
    }, []);

    return (
        <div>
            <button onClick={getUserLocation} style={{ margin: '10px', marginRight: '2px', padding: '10px', marginLeft: '2px'}}>
                Get My Location
            </button>
            <MapContainer className="map-container" center={position} zoom={8}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationPicker position={position} setPosition={setPosition} onLocationSelect={onLocationSelect} showMessage={showMessage} />
            </MapContainer>
            {popupMessage && <PopupContainer message={popupMessage} onClose={closePopup} />}
        </div>
    );
};

export default MapComponent;