import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, get, update, remove,push, set } from "firebase/database";

import "./manageAccounts.css";
import "../pages/home.css";
import AdminNotification from './adminNotification';

// Add this style at the top of your component
const imageDescriptionStyle = {
  textAlign: 'center',
  padding: '10px',
  backgroundColor: '#f8f9fa',
  borderRadius: '0 0 8px 8px',
  fontSize: '14px',
  color: '#666'
};

const ManagePackages = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(300000); // 5 minutes
  let timeout;
  let warningTimeout;
  const [packages, setPackages] = useState([]);
  const [nextPackageId, setNextPackageId] = useState(37); // Starting after Package 36
  const [packageImage, setPackageImage] = useState(null);
  const [packageImageTemp, setPackageImageTemp] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  // Define carousel image style
  const carouselImageStyle = {
    width: '100%',
    height: '300px', // Adjust height as needed
    objectFit: 'cover'
  };

  // Add this new state for image files
  const [imageFiles, setImageFiles] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null
  });

  // Add this state for the next package number
  const [nextPackageNumber, setNextPackageNumber] = useState(1);

  // Handle package selection
  const handlePackageSelect = (packageName) => {
    // Store the selected package in localStorage or state management
    localStorage.setItem('selectedPackage', packageName);
    // Redirect to booking page
    window.location.href = "/booking";
  };

  const handleSignOut = () => {
    doSignOut();
    window.location.href = "/home";
  };

  const handleSignOut1 = () => {
    doSignOut();
    alert("Session expired due to inactivity.");
    window.location.href = "/home";
  };

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const resetTimeout = () => {
    if (userLoggedIn) { 
      if (timeout) clearTimeout(timeout);
      if (warningTimeout) clearTimeout(warningTimeout);
      
      warningTimeout = setTimeout(() => {
        setShowWarning(true);
      }, sessionTimeout - 15000);
      
      timeout = setTimeout(handleSignOut1, sessionTimeout);
    }
  };

  useEffect(() => {
    return () => {
      if (warningTimeout) clearTimeout(warningTimeout);
    };
  }, []);

  useEffect(() => {
    resetTimeout();

    const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
    events.forEach(event => window.addEventListener(event, resetTimeout));

    return () => {
      clearTimeout(timeout);
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin);
          }
        })
        .catch((error) => console.error("Error fetching user data:", error.message));
    }
  }, [currentUser]);

  // Load existing packages on component mount
  useEffect(() => {
    const packagesRef = ref(db, 'packages');
    get(packagesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const packagesData = snapshot.val();
          // Count the number of existing packages
          const packageCount = Object.keys(packagesData).length;
          setNextPackageNumber(packageCount + 1);
          
          // Convert packages data to array of components
          const packageComponents = Object.entries(packagesData).map(([id, packageData]) => (
            <li key={id}>
              <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
                <div id={`heroCarousel${id}`} className="carousel slide" data-bs-ride="false">
                  <div className="carousel-inner">
                    {packageData.images.map((image, index) => (
                      <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <div>
                          <img
                            src={image}
                            className="img-fluid"
                            style={carouselImageStyle}
                            alt={packageData.locations[index]}
                          />
                          <div style={imageDescriptionStyle}>
                            {packageData.locations[index]} - {packageData.descriptions[index]}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a
                    className="carousel-control-prev"
                    href={`#heroCarousel${id}`}
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href={`#heroCarousel${id}`}
                    role="button"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
              <div className="package-content">
                <div>
                  <h3 className="package-title">{packageData.title}</h3>
                  <div className="package-price">{packageData.price}</div>
                  <div className="package-details">
                    {packageData.locations.map((location, index) => (
                      <span key={index}>✓ {location}</span>
                    ))}
                  </div>
                </div>
                <div className="button-group" style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                  
                  {admin === "yes" && (
                    <button 
                      className="Delete-button"
                      onClick={() => handleDeletePackage(id)}
                      style={{
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        cursor: 'pointer'
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </li>
          ));
          
          setPackages(packageComponents);
        } else {
          // If no packages exist, start at 1
          setNextPackageNumber(1);
          setPackages([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, [admin]);

  // Add this state for the modal
  const [showAddModal, setShowAddModal] = useState(false);
  const [newPackage, setNewPackage] = useState({
    title: '',
    price: '',
    locations: ['', '', '', ''],
    images: ['', '', '', ''],
    descriptions: ['', '', '', '']
  });

  // Add this useEffect to calculate the next package number when component mounts
  useEffect(() => {
    const packagesRef = ref(db, 'packages');
    get(packagesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const packages = snapshot.val();
          // Count the number of existing packages
          const packageCount = Object.keys(packages).length;
          // Set next number to total count + 1
          setNextPackageNumber(packageCount + 1);
        } else {
          // If no packages exist, start at 1
          setNextPackageNumber(1);
        }
      })
      .catch((error) => {
        console.error("Error calculating next package number:", error);
      });
  }, []);

  // Add this function to handle form submission
  const handleAddPackage = (e) => {
    e.preventDefault();
    
    // Format the title to include the package number
    const formattedTitle = `Package ${nextPackageNumber}: ${newPackage.title}`;
    
    // Create a reference to the packages collection
    const packagesRef = ref(db, 'packages');
    
    // Get a new push key from Firebase
    const newPackageRef = push(packagesRef);
    const newPackageId = newPackageRef.key;
    
    // Set the data at the new reference
    set(newPackageRef, {
      title: formattedTitle,
      price: `Starts at ₱${newPackage.price} per head`,
      locations: newPackage.locations,
      images: newPackage.images,
      descriptions: newPackage.descriptions
    })
      .then(() => {
        // Add the new package to the list with delete button
        const newPackageElement = (
          <li key={newPackageId}>
            <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
              <div id={`heroCarousel${newPackageId}`} className="carousel slide" data-bs-ride="false">
                <div className="carousel-inner">
                  {newPackage.images.map((image, index) => (
                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                      <div>
                        <img
                          src={image}
                          className="img-fluid"
                          style={carouselImageStyle}
                          alt={newPackage.locations[index]}
                        />
                        <div style={imageDescriptionStyle}>
                          {newPackage.locations[index]} - {newPackage.descriptions[index]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <a
                  className="carousel-control-prev"
                  href={`#heroCarousel${newPackageId}`}
                  role="button"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href={`#heroCarousel${newPackageId}`}
                  role="button"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div className="package-content">
              <div>
                <h3 className="package-title">{formattedTitle}</h3>
                <div className="package-price">Starts at ₱{newPackage.price} per head</div>
                <div className="package-details">
                  {newPackage.locations.map((location, index) => (
                    <span key={index}>✓ {location}</span>
                  ))}
                </div>
              </div>
              <div className="button-group" style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                <button 
                  className="Book-button"
                  onClick={() => handlePackageSelect(`${formattedTitle} - Starts at ₱${newPackage.price} per head || ${newPackage.locations.join(', ')}`)}
                >
                  Book Now
                </button>
                {admin === "yes" && (
                  <button 
                    className="Delete-button"
                    onClick={() => handleDeletePackage(newPackageId)}
                    style={{
                      backgroundColor: '#dc3545',
                      color: 'white',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </li>
        );

        // Update states
        setPackages(prevPackages => [...prevPackages, newPackageElement]);
        setNextPackageNumber(prev => prev + 1);
        setShowAddModal(false);
        setNewPackage({
          title: '',
          price: '',
          locations: ['', '', '', ''],
          images: ['', '', '', ''],
          descriptions: ['', '', '', '']
        });
        alert("New package added successfully!");
      })
      .catch((error) => {
        console.error("Error adding package:", error);
        alert("Error adding package. Please try again.");
      });
  };

  const handleUploadPackage = async (packageId) => {
    setSelectedPackageId(packageId);
    setPopupMessage("Are you sure you want to update the package image?");
    setShowConfirmation(true);
  };

  const confirmPackageUpload = async () => {
    try {
      if (!selectedPackageId) {
        throw new Error("No package selected");
      }

      await update(ref(db, `/packages/${selectedPackageId}`), {
        packageImage: packageImageTemp,
      });

      setPackageImage(packageImageTemp);
      setPopupMessage("Package image uploaded successfully!");
      setShowPopup(true);
      setShowConfirmation(false);
    } catch (error) {
      console.error("Error uploading package image:", error);
      setPopupMessage("Error uploading package image: " + error.message);
      setShowPopup(true);
      setShowConfirmation(false);
    }
  };

  // Add this new function near your other state declarations
  const handleDeletePackage = (packageId) => {
    setSelectedPackageId(packageId);
    setPopupMessage("Are you sure you want to delete this package?");
    setShowConfirmation(true);
  };

  // Add this new function to handle the actual deletion
  const confirmDeletePackage = async () => {
    try {
      // First, get all packages
      const packagesRef = ref(db, 'packages');
      const snapshot = await get(packagesRef);
      
      if (snapshot.exists()) {
        const packages = snapshot.val();
        
        // Get the package to be deleted
        const deletedPackage = packages[selectedPackageId];
        const deletedPackageNumber = parseInt(deletedPackage.title.match(/Package (\d+):/)[1]);
        
        // Delete the selected package
        await set(ref(db, `packages/${selectedPackageId}`), null);
        
        // Update the numbers of remaining packages
        const updates = {};
        
        Object.entries(packages).forEach(([id, packageData]) => {
          if (id !== selectedPackageId) {  // Skip the deleted package
            const currentNumber = parseInt(packageData.title.match(/Package (\d+):/)[1]);
            if (currentNumber > deletedPackageNumber) {
              // Decrease the package number by 1 for all packages after the deleted one
              const newNumber = currentNumber - 1;
              const newTitle = packageData.title.replace(
                `Package ${currentNumber}:`,
                `Package ${newNumber}:`
              );
              updates[`${id}/title`] = newTitle;
            }
          }
        });
        
        // Apply all updates
        if (Object.keys(updates).length > 0) {
          await update(ref(db, 'packages'), updates);
        }
        
        // Refresh the packages list
        const newSnapshot = await get(packagesRef);
        if (newSnapshot.exists()) {
          const updatedPackages = newSnapshot.val();
          const packageComponents = Object.entries(updatedPackages).map(([id, packageData]) => (
            <li key={id}>
              <div className="col-lg-6 order-1 order-lg-2 hero-img w-100">
                <div id={`heroCarousel${id}`} className="carousel slide" data-bs-ride="false">
                  <div className="carousel-inner">
                    {packageData.images.map((image, index) => (
                      <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <div>
                          <img
                            src={image}
                            className="img-fluid"
                            style={carouselImageStyle}
                            alt={packageData.locations[index]}
                          />
                          <div style={imageDescriptionStyle}>
                            {packageData.locations[index]} - {packageData.descriptions[index]}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a
                    className="carousel-control-prev"
                    href={`#heroCarousel${id}`}
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href={`#heroCarousel${id}`}
                    role="button"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
              <div className="package-content">
                <div>
                  <h3 className="package-title">{packageData.title}</h3>
                  <div className="package-price">{packageData.price}</div>
                  <div className="package-details">
                    {packageData.locations.map((location, index) => (
                      <span key={index}>✓ {location}</span>
                    ))}
                  </div>
                </div>
                <div className="button-group" style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                  <button 
                    className="Book-button"
                    onClick={() => handlePackageSelect(`${packageData.title} - ${packageData.price} || ${packageData.locations.join(', ')}`)}
                  >
                    Book Now
                  </button>
                  {admin === "yes" && (
                    <button 
                      className="Delete-button"
                      onClick={() => handleDeletePackage(id)}
                      style={{
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        cursor: 'pointer'
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </li>
          ));
          setPackages(packageComponents);
          // Update next package number
          setNextPackageNumber(Object.keys(updatedPackages).length + 1);
        } else {
          // If no packages left, reset to 1
          setPackages([]);
          setNextPackageNumber(1);
        }
        
        setPopupMessage("Package deleted successfully!");
        setShowPopup(true);
        setShowConfirmation(false);
      }
    } catch (error) {
      console.error("Error deleting package:", error);
      setPopupMessage("Error deleting package: " + error.message);
      setShowPopup(true);
      setShowConfirmation(false);
    }
  };

  // Add this confirmation popup component if you haven't already
  const ConfirmationPopup = ({ message, onConfirm, onCancel }) => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center'
      }}>
        <p>{message}</p>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <button 
            onClick={onConfirm}
            style={{
              backgroundColor: '#dc3545',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Yes
          </button>
          <button 
            onClick={onCancel}
            style={{
              backgroundColor: '#6c757d',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {showWarning && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            textAlign: 'center',
            maxWidth: '400px',
            width: '90%'
          }}>
            <h4 style={{ marginBottom: '20px', color: '#333' }}>Session Timeout Warning</h4>
            <p style={{ marginBottom: '20px', color: '#666' }}>
              Your session will expire in 15 seconds. Would you like to continue?
            </p>
            <button 
              onClick={() => {
                setShowWarning(false);
                resetTimeout();
              }}
              style={{
                backgroundColor: '#4154f1',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              Continue Session
            </button>
          </div>
        </div>
      )}
      <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
        <div className="container d-flex fixed-top">
          <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
          <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
            <ul>

            <li><a className="nav-link scrollto" href="adminbookinglist">Bookings</a></li>
              <li><a className="nav-link scrollto" href="manageaccounts">Manage Accounts</a></li>
              <li><a className=" nav-link scrollto" href="analytics">Dashboard</a></li>
              <li><a className=" nav-link scrollto active" href="managepackages">Manage Packages</a></li>
              <li><a className=" nav-link scrollto" href="walkInBooking">Walk In</a></li>
              <li className="nav-link scrollto">
                <AdminNotification />
              </li>


                
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(!dropdownVisible);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                  
                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                      {admin === "yes" && (
                        <li><a href="adminbookinglist">Admin Dashboard</a></li>
                      )}
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
        </div>
      </header>

      <section id="packages" className="packages section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Packages</h2>
            <p>Choose from our selection of educational tour packages</p>
            {admin === "yes" && (
              <button 
                className="Book-button"
                style={{ marginTop: '20px' }}
                onClick={() => setShowAddModal(true)}
              >
                Add New Package
              </button>
            )}
          </div>

          <ul className="package-list">
            {packages}
          </ul>
        </div>
      </section>

      {showAddModal && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            width: '90%',
            maxWidth: '600px',
            maxHeight: '90vh',
            overflow: 'auto'
          }}>
            <h3>Add Package </h3>
            <form onSubmit={handleAddPackage}>
              <div className="mb-3">
                <label className="form-label">Package Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={newPackage.title}
                  onChange={(e) => setNewPackage({...newPackage, title: e.target.value})}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Price (per head)</label>
                <input
                  type="text"
                  className="form-control"
                  value={newPackage.price}
                  onChange={(e) => setNewPackage({...newPackage, price: e.target.value})}
                  required
                />
              </div>
              
              {[0, 1, 2, 3].map((index) => (
                <div key={index} className="mb-4">
                  <h5>Location {index + 1}</h5>
                  <div className="mb-3">
                    <label className="form-label">Location Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newPackage.locations[index]}
                      onChange={(e) => {
                        const newLocations = [...newPackage.locations];
                        newLocations[index] = e.target.value;
                        setNewPackage({...newPackage, locations: newLocations});
                      }}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Image</label>
                    <div>
                      <input
                        type="file"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            // Validate file type
                            const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                            if (!validTypes.includes(file.type)) {
                              alert("Only JPG and PNG files are allowed");
                              e.target.value = '';
                              return;
                            }

                            // Validate file size (5MB)
                            const maxSize = 5 * 1024 * 1024;
                            if (file.size > maxSize) {
                              alert("File size must be less than 5MB");
                              e.target.value = '';
                              return;
                            }

                            // Read file as base64
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              const newImages = [...newPackage.images];
                              newImages[index] = reader.result;
                              setNewPackage({...newPackage, images: newImages});
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                      <div className="small font-italic text-muted mt-1">Only JPG and PNG files (max 5MB)</div>
                      {newPackage.images[index] && (
                        <img 
                          src={newPackage.images[index]} 
                          alt={`Preview ${index + 1}`} 
                          style={{ 
                            maxWidth: '200px', 
                            marginTop: '10px', 
                            borderRadius: '4px' 
                          }} 
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newPackage.descriptions[index]}
                      onChange={(e) => {
                        const newDescriptions = [...newPackage.descriptions];
                        newDescriptions[index] = e.target.value;
                        setNewPackage({...newPackage, descriptions: newDescriptions});
                      }}
                      required
                    />
                  </div>
                </div>
              ))}
              
              <div className="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowAddModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Add Package
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showConfirmation && (
        <ConfirmationPopup 
          message={popupMessage} 
          onConfirm={confirmDeletePackage} 
          onCancel={() => setShowConfirmation(false)} 
        />
      )}
    </div>
  );
};

export default ManagePackages;
