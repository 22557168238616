import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import WalkIn from "../pages/WalkIn.js"; 

const WalkinCheck = () => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (currentUser) {
      
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setIsAdmin(userData.admin === "yes"); 
          } else {
            console.log("No user data available");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    }
  }, [currentUser]);


  return (
    <div>
      {/* Header */}
      {/* Header content */}
      {/* End Header */}

      {/* Render AdminDashboard only if user is admin */}
      {isAdmin && <WalkIn />}
    </div>
  );
};

export default WalkinCheck;
