import React, { useState, useEffect } from 'react';
import { BsBellFill } from 'react-icons/bs';
import { db } from "../firebase/firebase";
import { ref, get, update, onValue } from "firebase/database";
import { useNavigate } from 'react-router-dom';

const AdminNotification = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [pendingBookings, setPendingBookings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      const notificationsRef = ref(db, "adminNotifications/");
      
      const unsubscribeNotifications = onValue(notificationsRef, async (snapshot) => {
        let allNotifications = [];

        if (snapshot.exists()) {
          const notificationsData = snapshot.val();
          Object.entries(notificationsData).forEach(([id, notification]) => {
            let message = notification.message;
            let bgColor = notification.bgColor;

            allNotifications.push({
              id, 
              message,  
              bgColor,
              timestamp: notification.timestamp,
             
              time: new Date(notification.timestamp).toLocaleString(),
              viewed: notification.viewed || false,
              status: notification.status,
              statusViewed: notification.statusViewed || false,
              bookingId: notification.bookingId,
              userName: notification.userName
            });
          });
        }

        allNotifications.sort((a, b) => {
          const timeA = new Date(a.timestamp).getTime();
          const timeB = new Date(b.timestamp).getTime();
          
          if (timeA !== timeB) {
            return timeB - timeA;
          }
          
          return b.id.localeCompare(a.id);
        });

        setPendingBookings(allNotifications);
      });

      return () => {
        unsubscribeNotifications();
      };
    };

    fetchNotifications();

    return () => {
      // Cleanup logic if needed
    };
  }, []);




  const handleNotificationClick = async (notification) => {
    try {

      await update(ref(db, `adminNotifications/${notification.id}`), { statusViewed: true });

      const activeTab = notification.status === "pending" ? "bookings" :
                            notification.status === "approved" ? "approved" :
                            notification.status === "settledDownpayment" ? "approved" :
                            notification.status === "completed" ? "completed" :
                            notification.status === "cancelled" ? "cancelled" :
                            notification.status === "refunded" ? "refunded" : 
                            notification.status === "requestDateChange" ? "approved" : 
                            notification.status === "requestRefund" ? "approved" :
                            "booking"; 

      navigate('/adminbookinglist', { state: { activeTab } });
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  const markAllAsRead = async () => {
    try {
   
      const updates = {};
      pendingBookings.forEach(notification => {
        updates[`adminNotifications/${notification.id}/statusViewed`] = true; 
        
        
      });

      await update(ref(db), updates); 
      setPendingBookings(prevBookings => 
        prevBookings.map(notification => ({ ...notification, statusViewed: true })) 
      );
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowNotifications(!showNotifications)}
        className="relative p-1"
        style={{ background: 'none' }}
      >
        <BsBellFill className="w-4 h-4" style={{ color: 'white' }} />
        {pendingBookings.filter(booking => !booking.statusViewed).length > 0 && (
          <span className="absolute -top-1 -right-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-600 rounded-full">
            {pendingBookings.filter(booking => !booking.statusViewed).length}
          </span>
        )}
      </button>

      {showNotifications && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-50">
          <div className="px-4 py-2 text-sm font-medium text-gray-700 border-b flex justify-between items-center">
            <span>Booking Notifications</span>
            <button onClick={markAllAsRead} className="text-sm text-[#302b63] bg-white hover:text-black px-3 py-1 rounded-md transition-colors duration-200">
              Mark All as Read
            </button>
          </div>
          <div className="max-h-[300px] overflow-y-auto">
            {pendingBookings.length === 0 ? (
              <div className="px-4 py-2 text-sm text-gray-500">
                No notifications
              </div>
            ) : (
              pendingBookings.map((notification) => (
                <div
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                    !notification.statusViewed ? notification.bgColor : ''
                  }`}
                >
                  <div className="text-sm text-gray-700">{notification.message}</div>
                  <div className="text-xs text-gray-500 flex justify-between">
                    <span>{notification.time}</span>
                    <span className={`px-2 rounded-full text-xs ${
                      notification.status ?
                        notification.status === 'pending' ? 'bg-blue-100 text-blue-800' : ''
                        : ''
                      } ${notification.status === 'approved' ? 'bg-green-100 text-green-800' : ''}
                      ${notification.status === 'completed' ? 'bg-purple-100 text-purple-800' : ''}
                      ${notification.status === 'cancelled' ? 'bg-red-100 text-red-800' : ''}
                      ${notification.status === 'refunded' ? 'bg-yellow-100 text-yellow-800' : ''}
                      ${notification.status === 'requestDateChange' ? 'bg-yellow-100 text-yellow-800' : ''}
                      ${notification.status === 'requestRefund' ? 'bg-red-100 text-red-800' : ''}
                      ${notification.status === 'settledDownpayment' ? 'bg-green-100 text-green-800' : ''}
                    `}>
                      {notification.status ?
                        notification.status.charAt(0).toUpperCase() + notification.status.slice(1)
                        : 'Unknown Status'
                      }
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminNotification;
