import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, get, update, remove } from "firebase/database";
import { deleteUser } from "firebase/auth";
import "./manageAccounts.css";
import "../pages/home.css";
import AdminNotification from './adminNotification';

function ManageAccounts() {
  const [users, setUsers] = useState([]);
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [adminType, setAdminType] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [newAdmin, setNewAdmin] = useState("");
  const [newAdminType, setNewAdminType] = useState("");
  const [activeTab, setActiveTab] = useState("admin");
  const [searchQuery, setSearchQuery] = useState("");

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const [selectedUserBookings, setSelectedUserBookings] = useState([]);
  const [showBookingsModal, setShowBookingsModal] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setProfileImage(userData.profileImage);
            setAdmin(userData.admin); 
            setAdminType(userData.adminType);
          } else {
            console.log("No user data available");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    }


  
    const usersRef = ref(db, `/users/`);
    get(usersRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          const usersList = Object.keys(usersData).map(key => ({
            id: key,
            ...usersData[key],
            fullName: `${usersData[key].firstName || ""} ${usersData[key].middleName || ""} ${usersData[key].lastName || ""}`.trim(),
            profileImage: usersData[key].profileImage,
            idImage: usersData[key].idImage,
            admin: usersData[key].admin,
            adminType: usersData[key].adminType
          }));
          setUsers(usersList);
        } else {
          console.log("No users data available");
        }
      })
      .catch((error) => {
        console.error("Error fetching users data:", error.message);
      });
  }, [currentUser]);



  const handleSignOut = () => {
    doSignOut();
    window.location.href = "/home";
  };

  const handleEditClick = (user) => {

    if (adminType === "superAdmin") {
      setEditingUser(user.id);
      setNewAdmin(user.admin || "");
      setNewAdminType(user.adminType || "");
    } else {
      alert("You do not have permission to edit users.");
    }
  };

  const handleSaveClick = () => {
    if (window.confirm("Are you sure you want to update this user's information?")) {
      const userRef = ref(db, `/users/${editingUser}`);
      update(userRef, {
        admin: newAdmin,
        adminType: newAdminType
      })
      .then(() => {
        alert("User information updated successfully");
        setEditingUser(null); 
        setNewAdmin("");
        setNewAdminType("");
      })
      .catch((error) => {
        console.error("Error updating user data:", error.message);
      });
    }
  };

  const handleCancelClick = () => {
    setEditingUser(null);
    setNewAdmin("");
    setNewAdminType("");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = users.filter(user => 
    activeTab === "admin" ? user.admin === "yes" : user.admin === "no"
  ).filter(user => 
    user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) || 
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );



  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleDeleteClick = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const userRef = ref(db, `/users/${userId}`);


      if (admin === "yes" && adminType === "superAdmin") {
        remove(userRef)
          .then(() => {
            setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
            return deleteUser(userId);
          })
          .then(() => {
            alert("User deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting user:", error.message);
          });
      } else {
        alert("You do not have permission to delete users.");
      }
    }
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleShowBookings = async (userId) => {
    const paths = [
      `UserBookings/${userId}`,
      `UserApprovedBookings/${userId}`,
      `UserCompletedBookings/${userId}`,
      `UserCancelledBookings/${userId}`
    ];

    const allBookings = [];

    for (const path of paths) {
      const bookingsRef = ref(db, path);
      const snapshot = await get(bookingsRef);
      
      if (snapshot.exists()) {
        const bookingsData = snapshot.val();
        const bookingsArray = Object.keys(bookingsData).map((key) => ({
          id: key,
          ...bookingsData[key],
        }));
        allBookings.push(...bookingsArray);
      }
    }

    setSelectedUserBookings(allBookings);
    setShowBookingsModal(true);
  };

  const closeBookingsModal = () => {
    setShowBookingsModal(false);
    setSelectedUserBookings([]);
  };

  return (
    <div>
     <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
      <div className="container d-flex fixed-top">
        <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
        <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
          <li><a className="nav-link scrollto" href="adminbookinglist">Bookings</a></li>

              <li><a className="nav-link scrollto active" href="manageaccounts">Manage Accounts</a></li>
              <li><a className=" nav-link scrollto" href="analytics">Dashboard</a></li>
              <li><a className=" nav-link scrollto" href="managepackages">Manage Packages</a></li>
              <li><a className=" nav-link scrollto" href="walkInBooking">Walk In</a></li>
              <li className="nav-link scrollto">
                <AdminNotification />
              </li>
          
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(prev => !prev);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                  
                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                     
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
        </div>
      </header>

{/* End Header */}




      <div className="container-xl px-4 mt-4">
        <hr className="mt-0 mb-4" />
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search users by name or email"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "admin" ? "active" : ""}`}
              id="admin-tab"
              data-bs-toggle="tab"
              data-bs-target="#admin"
              type="button"
              role="tab"
              aria-controls="admin"
              aria-selected={activeTab === "admin"}
              onClick={() => setActiveTab("admin")}
            >
              Admin
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "users" ? "active" : ""}`}
              id="users-tab"
              data-bs-toggle="tab"
              data-bs-target="#users"
              type="button"
              role="tab"
              aria-controls="users"
              aria-selected={activeTab === "users"}
              onClick={() => setActiveTab("users")}
            >
              Users
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabContent">
          <div
            className={`tab-pane fade ${activeTab === "admin" ? "show active" : ""}`}
            id="admin"
            role="tabpanel"
            aria-labelledby="admin-tab"
          >
            <div className="row">
              {filteredUsers.map(user => (
                user.admin === "yes" && (
                  <div className="col-xl-4" key={user.id}>
                    <div className="card mt-4 mb-xl-0">
                      <div className="card-header">User: {user.fullName}</div>
                      <div className="card-body text-center" style={{ marginTop: '20px' }}>
                        <div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Name:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <input type="text" className="form-control" defaultValue={user.fullName} readOnly />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Email:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <input type="email" className="form-control" defaultValue={user.email} readOnly />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Profile Image:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <img src={user.profileImage} alt="Profile" style={{ width: '100px', height: '100px' }} />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">ID Image:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <img src={user.idImage} alt="ID" style={{ width: '100px', height: '100px' }} />
                            </div>
                          </div>
                          {editingUser === user.id ? (
                            <>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Admin:</label>
                                <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                                  <select
                                    className="form-control"
                                    value={newAdmin}
                                    onChange={(e) => setNewAdmin(e.target.value)}
                                  >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Admin Type:</label>
                                <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                                  <select
                                    className="form-control"
                                    value={newAdminType}
                                    onChange={(e) => setNewAdminType(e.target.value)}
                                  >
                                    <option value="superAdmin">Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="employee">Staff</option>
                                  </select>
                                </div>
                              </div>
                              <button className="btn btn-primary mr-2" onClick={handleSaveClick}>
                                Save
                              </button>
                              <button className="btn btn-secondary mr-2" onClick={handleCancelClick}>
                                Cancel
                              </button>
                              <button className="btn btn-danger mr-2" onClick={() => handleDeleteClick(user.id)}>
                                Delete User
                              </button>
                            </>
                          ) : (
                            <button className="btn btn-warning" onClick={() => handleEditClick(user)}>
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>

          <div
            className={`tab-pane fade ${activeTab === "users" ? "show active" : ""}`}
            id="users"
            role="tabpanel"
            aria-labelledby="users-tab"
          >
            <div className="row">
              {filteredUsers.map(user => (
                user.admin === "no" && (
                  <div className="col-xl-4 mb-4" key={user.id}>
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-header">User: {user.fullName}</div>
                      <div className="card-body text-center" style={{ marginTop: '20px' }}>
                        <div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Name:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <input type="text" className="form-control" defaultValue={user.fullName} readOnly />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Email:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <input type="email" className="form-control" defaultValue={user.email} readOnly />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Profile Image:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <img src={user.profileImage} alt="Profile" style={{ width: '100px', height: '100px' }} />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">ID Image:</label>
                            <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                              <img src={user.idImage} alt="ID" style={{ width: '100px', height: '100px' }} />
                            </div>
                          </div>
                          {editingUser === user.id ? (
                            <>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Admin:</label>
                                <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                                  <select
                                    className="form-control"
                                    value={newAdmin}
                                    onChange={(e) => setNewAdmin(e.target.value)}
                                  >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Admin Type:</label>
                                <div className="col-sm-8" style={{ marginBottom: '20px' }}>
                                  <select
                                    className="form-control"
                                    value={newAdminType}
                                    onChange={(e) => setNewAdminType(e.target.value)}
                                  >
                                    <option value="superAdmin">Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="employee">Staff</option>
                                  </select>
                                </div>
                              </div>
                              <button className="btn btn-primary mr-2" onClick={handleSaveClick}>
                                Save
                              </button>
                              <button className="btn btn-secondary mr-2" onClick={handleCancelClick}>
                                Cancel
                              </button>
                              <button className="btn btn-danger mr-2" onClick={() => handleDeleteClick(user.id)}>
                                Delete User
                              </button>
                            
                            </>
                          ) : (
                            <>
                            <button className="mr-2 btn btn-warning" onClick={() => handleEditClick(user)}>
                              Edit
                            </button>

                            <button className="btn btn-info" onClick={() => handleShowBookings(user.id)}>
                                Show Bookings
                              </button>

                            </>

                            
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for showing user bookings */}
      {showBookingsModal && (
        <div className="modal-user">
          <div className="modal-user-content">
            <span className="close" onClick={closeBookingsModal}>&times;</span>
            <h2>User Bookings</h2>
            <table>
              <thead>
                <tr>
                  <th>Booking ID</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Departure Date</th>
                  <th>Status</th>
                  <th>Package</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {selectedUserBookings.map((booking) => (
                  <tr key={booking.id}>
                    <td>{booking.id}</td>
                    <td>{booking.userName}</td>
                    <td>{booking.userEmail}</td>
                    <td>{booking.departureDate}</td>
                    <td>{booking.status}</td>
                    <td>{booking.package}</td>
                    <td>{booking.totalPrice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageAccounts;
