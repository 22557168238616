import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";


const Header = () => {
  const navigate = useNavigate();
  const { userLoggedIn, currentUser } = useAuth();
  return (
    <nav className="flex flex-row gap-x-2 w-full z-20 fixed top-0 left-0 h-12 border-b place-content-center items-center bg-gradient-to-b from-blue-400 to-white">
      {userLoggedIn ? (
        <></>
      ) : (
        <>
          
        </>
      )}
    </nav>
  );
};

export default Header;
