import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/authContext";
import { doSignOut } from "../firebase/auth";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import "../pages/home.css";
import UserNotifications from "./UserNotif";

import Cookies from 'js-cookie';

const carouselImageStyle = {
  width: '150%', 
  height: '450px',  
  objectFit: 'cover', 

};

const Home = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(null);
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (consent) {
      setCookieConsent(consent); 
    }
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', 'accepted'); 
    setCookieConsent('accepted');
  };

  const handleRejectCookies = () => {
    Cookies.set('cookieConsent', 'rejected'); 
    setCookieConsent('rejected');
  };

  const handleSignOut = () => {
    doSignOut();
    window.location.href = "/home";
  };

  const setUserSession = (sessionData) => {
    Cookies.set('userSession', sessionData);
  };

  useEffect(() => {
    if (currentUser) {
      setUserSession(currentUser.uid); 
      

      const userRef = ref(db, `/users/${currentUser.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setProfileImage(userData.profileImage);
          setAdmin(userData.admin);
        } else {
          console.log("No user data available");
        }
      }).catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
    }
  }, [currentUser]);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };


 
  useEffect(() => {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6742ace72480f5b4f5a31abc/1ide76q1v';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);




  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };


  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const openCookiePopup = () => {
    setShowCookiePopup(true);
  };

  const closeCookiePopup = () => {
    setShowCookiePopup(false);
  };

  useEffect(() => {
   
    resetTimeout();

    const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
    events.forEach(event => window.addEventListener(event, resetTimeout));


    return () => {
      clearTimeout(timeout);
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, []);


  const sessionTimeout = 300000; 
  let timeout;
  let warningTimeout;

  const resetTimeout = () => {
    if (userLoggedIn) { 
      if (timeout) clearTimeout(timeout);
      if (warningTimeout) clearTimeout(warningTimeout);
      

      warningTimeout = setTimeout(() => {
        setShowWarning(true);
      }, sessionTimeout - 15000);
      
      timeout = setTimeout(handleSignOut1, sessionTimeout);
    }
  };


  useEffect(() => {
    return () => {
      if (warningTimeout) clearTimeout(warningTimeout);
    };
  }, []);

  const handleSignOut1 = () => {
    doSignOut();
    alert("Session expired due to inactivity.");
  };

  const handleContinueSession = () => {
    setShowWarning(false);
    resetTimeout(); 
  };

  return (
    <div>

{cookieConsent === null && (
        <div className="cookie-consent-banner" style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          position: 'fixed', 
          top: '70%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          zIndex: 1000, 
          backgroundColor: '#f8f9fa', 
          padding: '30px',
          borderRadius: '15px',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          width: '400px',
          border: '2px solid #302b63',
          animation: 'fadeIn 0.3s ease'
        }}>
          <p style={{
            fontSize: '18px',
            color: '#333',
            marginBottom: '20px',
            textAlign: 'center',
            fontWeight: '500'
          }}>This website uses cookies to enhance the user experience. Do you accept cookies?</p>
          <div style={{ display: 'flex', gap: '20px' }}>
            <button 
              onClick={() => handleAcceptCookies()}
              style={{ 
                backgroundColor: '#302b63', 
                color: 'white',
                padding: '12px 24px',
                fontSize: '16px',
                width: '120px',
                height: '45px',
                border: 'none',
                borderRadius: '25px',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }}
            >
              Accept
            </button>
            <button 
              onClick={() => handleRejectCookies()}
              style={{ 
                backgroundColor: '#302b63', 
                color: 'white',
                padding: '12px 24px',
                fontSize: '16px', 
                width: '120px',
                height: '45px',
                border: 'none',
                borderRadius: '25px',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }}
            >
              Reject
            </button>
          </div>
          <span 
            onClick={openCookiePopup} 
            style={{ 
              marginTop: '20px',
              textDecoration: 'none',
              color: '#302b63',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '500',
              transition: 'color 0.2s ease'
            }}
          >
            About Cookies
          </span>
          <style>
            {`
              @keyframes fadeIn {
                from { opacity: 0; }
                to { opacity: 1; }
              }
            `}
          </style>
        </div>
      )}


      {showCookiePopup && (
        <div className="cookie-popup-modal" style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '5px',
            width: '300px',
            textAlign: 'center',
          }}>
            <h4>About Cookies</h4>
            <p>
              Cookies are small text files that are used to store small pieces of information. 
              They help us make the website function properly, make it more secure, 
              provide better user experience, and understand how the website performs 
              and to analyze what works and where it needs improvement.
            </p>
            <button 
              onClick={closeCookiePopup}
              style={{ 
                backgroundColor: '#302b63', 
                color: 'white',
                padding: '12px 24px',
                fontSize: '16px', 
                width: '120px',
                height: '45px',
                border: 'none',
                borderRadius: '25px',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                ':hover': {
                  backgroundColor: '#EE9B12',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      
      {showWarning && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            textAlign: 'center',
            maxWidth: '400px',
            width: '90%'
          }}>
            <h4 style={{ marginBottom: '20px', color: '#333' }}>Session Timeout Warning</h4>
            <p style={{ marginBottom: '20px', color: '#666' }}>
              Your session will expire in 15 seconds. Would you like to continue?
            </p>
            <button 
              onClick={handleContinueSession}
              style={{
                backgroundColor: '#4154f1',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px'
              }}
            >
              Okay
            </button>
          </div>
        </div>
      )}

      <header id="header" className="fixed-top navy-blue" style={{ height: '50px' }}>
        <div className="container d-flex fixed-top">
          <img src="assets/img/ETOURMO LOGO.png" className="image" style={{ width: '100px', height: '50px' }} alt="Logo" />
          <nav id="navbar" className={`ml-15 navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
            <ul>
              <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a className="nav-link scrollto" href="home#about">About</a></li>
              <li><a className="nav-link scrollto" href="home#team">Team</a></li>
              <li><a className="nav-link scrollto" href="home#faq">FAQ</a></li>
              <li><a className="nav-link scrollto" href="booking">Book</a></li>
              <li><a className="nav-link scrollto " href="packages">Packages</a></li>

                
              {userLoggedIn && (
                <li><a className="nav-link scrollto" href="UserBookings">My Bookings</a></li>
              )}

{userLoggedIn && (
              <div className="ml-2" style={{ marginLeft: '35px' }}>
                    <UserNotifications />
                  </div>

)}
              {userLoggedIn ? (
                <li className="ml-12 dropdown d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <img
                      src={profileImage || "assets/img/profile.png"}
                      alt="User Image"
                      className="user-avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        setDropdownVisible(!dropdownVisible);
                      }} 
                      style={{ marginRight: '15px', position: 'absolute', right: '-25px' }}
                    >
                      <i className="bi bi-chevron-down" />
                    </a>
                  </div>
                  
                  {dropdownVisible && (
                    <ul className="dropdown-menu" style={{ 
                      position: 'absolute', 
                      left: '-150px', 
                      top: '100%',
                      backgroundColor: '#fff', 
                      border: '1px solid #ccc', 
                      padding: '10px', 
                      zIndex: '1000',
                      marginTop: '5px'
                    }}>
                      <li>
                        <a>
                          <span>{currentUser.email}</span>
                        </a>
                      </li>
                      <li><a href="profile">Profile</a></li>
                      {admin === "yes" && (
                        <li><a href="adminbookinglist">Admin Dashboard</a></li>
                      )}
                      <li><a href="#" onClick={handleSignOut}>Sign Out</a></li>
                    </ul>
                  )}
                </li>
              ) : (
                <li><a className="Login-sign" href="Login">Log In / Sign Up</a></li>
              )}
            </ul>
            <i className =" bi bi-list mobile-nav-toggle" onClick={toggleMobileNav} />
          </nav>
        </div>
      </header>
{/* End Header */}




 
        {/* ======= Hero Section ======= */}

        <section id="hero" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
            <h1>Welcome to EtourMo Travel and Tours!</h1>
            <h2>
              Embark on unforgettable adventures with EtourMo, where every journey
              is crafted with passion and personalized care.
            </h2>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="booking" className="btn-get-started">Get Started</a>
              <a
              href="https://www.facebook.com/etourmo"
              className="glightbox btn-visit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ri-facebook-fill" />
              <span>Visit page</span>
            </a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/img/EtourMo.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="EtourMo"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/EtourMo2.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="EtourMo 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/EtourMo3.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="EtourMo 3"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/EtourMo4.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="EtourMo 3"
                  />
                  </div>
                <div className="carousel-item">
                  <img
                    src="/assets/img/EtourMo5.jpg"
                    className="img-fluid"
                    style={carouselImageStyle} 
                    alt="EtourMo 3"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroCarousel"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#heroCarousel"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </section>
{/* End Hero */}
        <main id="main">
          {/* ======= Clients Section ======= */}
      <section id="clients" className="clients section-bg">
  <div className="container">
    <div className="row justify-content-center" data-aos="zoom-in">
      <div className="col-lg-3 col-md-3 col-sm-4 col-6 d-flex align-items-center justify-content-center">
        <img src="assets/img/clients/DOT.png" className="img-fluid" alt="" />
      </div>
      <div className="col-lg-3 col-md-3 col-sm-4 col-6 d-flex align-items-center justify-content-center">
        <img src="assets/img/clients/PHILTOA.png" className="img-fluid" alt="" />
      </div>
      <div className="col-lg-3 col-md-3 col-sm-4 col-6 d-flex align-items-center justify-content-center">
        <img src="assets/img/clients/PTAA.png" className="img-fluid" alt="" />
      </div>
      <div className="col-lg-3 col-md-3 col-sm-4 col-6 d-flex align-items-center justify-content-center">
        <img src="assets/img/clients/TPB.png" className="img-fluid" alt="" />
      </div>
     
      <div className="col-lg-3 col-md-3 col-sm-4 col-6 d-flex align-items-center justify-content-center">
        <img src="assets/img/clients/ANOTHER_IMAGE.png" className="img-fluid" alt="" />
      </div>
    </div>
  </div>
</section>

{/* End Cliens Section */}
          {/* ======= about Us Section ======= */}
       <section id="about" className="about section-bg">
  <div className="container-fluid" data-aos="fade-up">
    <div className="row">
      <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
        <div className="content">
          <h3>About <strong>Us</strong></h3>
          <p>
            At EtourMo Travel, we're passionate about making your travel dreams a reality. With our dedication to exceptional service and unforgettable experiences, we strive to be your trusted partner in every journey you undertake.
          </p>
        </div>
        <div className="accordion-list">
          <ul>
            <li>
              <a><span>01</span> Personalized Service</a>
              <div id="accordion-list-1">
                <p>
                  Tailored travel experiences crafted to suit your preferences and needs.
                </p>
              </div>
            </li>
            <li>
              <a><span>02</span> Seamless Booking</a>
              <div id="accordion-list-2">
                <p>
                  Effortless online reservations for educational field trips of students from different schools.
                </p>
              </div>
            </li>
            <li>
              <a><span>03</span> Expert Guidance</a>
              <div id="accordion-list-3">
                <p>
                  Knowledgeable travel advisors ready to assist you every step of the way.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage: 'url("assets/img/chooose%20Etourmo.png")'}} data-aos="zoom-in" data-aos-delay={150}>&nbsp;</div>
    </div>
  </div>
</section>

{/* End About Us Section */}
         
      
          {/* ======= Team Section ======= */}
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Team</h2>
                <p>Meet the passionate minds driving our mission forward. Our team is a diverse blend of talent, united by a shared dedication to excellence. From creative innovators to strategic thinkers, each member brings unique expertise to the table, shaping our vision and propelling us towards success</p>
              </div>
              <div className="row">
                <div className="col-lg-6" data-aos="zoom-in" data-aos-delay={100}>
                  <div className="member d-flex align-items-start">
                    <div className="pic"><img src="assets/img/team/Sir Eric.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Eric Legaspi</h4>
                      <span>Chief Executive Officer</span>
                      <p></p>
                      <div className="social">
                        <a href><i className="ri-twitter-fill" /></a>
                        <a href><i className="ri-facebook-fill" /></a>
                        <a href><i className="ri-instagram-fill" /></a>
                        <a href> <i className="ri-linkedin-box-fill" /> </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={200}>
                  <div className="member d-flex align-items-start">
                    <div className="pic"><img src="assets/img/team/Elton.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Elton Lagrimas</h4>
                      <span>Secretary</span>
                      <p></p>
                      <div className="social">
                        <a href><i className="ri-twitter-fill" /></a>
                        <a href><i className="ri-facebook-fill" /></a>
                        <a href><i className="ri-instagram-fill" /></a>
                        <a href> <i className="ri-linkedin-box-fill" /> </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay={300}>
                  <div className="member d-flex align-items-start">
                    <div className="pic"><img src="assets/img/team/Rex.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Rex Soria</h4>
                      <span>Manager</span>
                      <p></p>
                      <div className="social">
                        <a href><i className="ri-twitter-fill" /></a>
                        <a href><i className="ri-facebook-fill" /></a>
                        <a href><i className="ri-instagram-fill" /></a>
                        <a href> <i className="ri-linkedin-box-fill" /> </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay={400}>
                  <div className="member d-flex align-items-start">
                    <div className="pic"><img src="assets/img/team/danica.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Danica Vilas</h4>
                      <span>Assistant Secretary</span>
                      <p></p>
                      <div className="social">
                        <a href><i className="ri-twitter-fill" /></a>
                        <a href><i className="ri-facebook-fill" /></a>
                        <a href><i className="ri-instagram-fill" /></a>
                        <a href> <i className="ri-linkedin-box-fill" /> </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{/* End Team Section */}
     
          {/* ======= Frequently Asked Questions Section ======= */}
          <section id="faq" className="faq section-bg">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>Frequently Asked Questions</h2>
      <p>This is our FAQ section, our aim is to provide quick and concise answers to the most common questions you might have about our service. We want to make sure you can find solutions efficiently, reduce the need for reaching out to customer support, and enhance your overall experience with us.</p>
    </div>
    <div className="faq-list">


    <div className="text-center mb-4">
      <button 
        onClick={toggleVideo}
        style={{ 
          backgroundColor: '#302b63', 
          color: 'white',
          padding: '12px 24px',
          fontSize: '16px',
          border: 'none',
          borderRadius: '25px',
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        Tutorial video of how to book
      </button>
    </div>



      


      
      <ul>
        <li data-aos="fade-up" data-aos-delay={100}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Where are you located?
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-1">
            <p>
          214 Abacan St. Brgy Calvario, Meycauayan Bulacan
            </p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={300}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
          Can you assist with special requests or accommodations?  
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-3">
            <p>
              Yes, we can offer your preferred requests and accommodations.
            </p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={400}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Office Hours
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-4">
            <p>
              - Everyday: 8:00 AM - 6:00 PM
              </p>
               
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={500}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Tours
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-5">
            <p>
              - Educational Tour 
              </p>
              <p>
              - Field trip inquiry 
            </p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={500}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Payment Method 
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-5">
            <p>
              - Walk In  
              </p>
              <p>
              - GCASH / Paymaya  
            </p>
            <p>
              - Bank Transfer 
            </p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={100}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Contact Details 
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-1">
            <p>
          - Etourmo@gmail.com 
            </p>
            <p>
              - 0908 300 0009
            </p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={100}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Travel Insurance 
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-1">
            <p>
          - Fieldtrip / Educational Tour: Fortune Insurance
            </p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay={100}>
          <i className="bx bx-help-circle icon-help" /> 
          <a>
            Etourmo Travel and Tours is member of the following: 
            <i className="bx bx-chevron-down icon-show" />
          </a>
          <div id="faq-list-1">
            <p>
              Philippine Travel Agencies Association
            </p>
            <p>
              Department of Tourism
            </p>
            <p>
              Philippine Tour Operators Association 
            </p>
            <p>
              Tourism Promotions Board
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>


{showVideo && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  }}>
    <div style={{
      position: 'relative',
      width: '80%',
      maxWidth: '800px',
      aspectRatio: '16/9',
    }}>
      <button 
        onClick={toggleVideo}
        style={{
          position: 'absolute',
          top: '-40px',
          right: 0,
          backgroundColor: 'transparent',
          border: 'none',
          color: 'white',
          fontSize: '24px',
          cursor: 'pointer',
        }}
      >
        ×
      </button>
      <video 
        width="100%" 
        height="100%" 
        controls
        style={{ backgroundColor: 'black' }}
      >
        <source src="/assets/video/Booking.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
)}
          {/* End Contact Section */}

        </main>{/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>Etourmo</h3>
                  <p>
                  214 Abacan St. Brgy Calvario <br />
                  Meycauayan Bulacan<br />
                    Philippines <br /><br />
                    <strong>Phone:</strong> 0908 300 0009<br />
                    <strong>Email:</strong>ETOURMO@GMAIL.COM<br />
                  </p>
                </div>
                
                
               
              </div>
            </div>
          </div>
          <div className="container footer-bottom clearfix">
            <div className="copyright">
              © Copyright <strong><span>Arsha</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
           
              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
          </div>
        </footer>{/* End Footer */}
        
        
        
      </div>

      
      
      
    );
  }

export default Home;
      

